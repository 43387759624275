import { InputHTMLAttributes, PropsWithChildren, useRef } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import classNames from 'classnames';
import { ValidatableInputProps } from "helpers/types";

export type RadioboxProps = ValidatableInputProps<boolean> & PropsWithChildren & Pick<InputHTMLAttributes<HTMLInputElement>, 'id' | 'disabled' | 'className' | 'style'> & {
    label?: string
}

const Radiobox = (props: RadioboxProps) =>  {
    const { isValid, className,  value, onChange, label, name, id, children, ...otherProps } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    return (<>
        <FormGroup check className={classNames({
            "is-invalid": isValid === false,
            "is-valid": (isValid && value)
        })}>
            <Input
                innerRef={inputRef}
                valid={isValid && value} 
                invalid={isValid === false} 
                className={classNames(className)}
                type="radio"
                onChange={e => onChange?.(e.target.checked)}
                checked={value || false}
                name={name}
                id={id || name}
                {...otherProps} />
                {(label || children) ? <Label htmlFor={id || name} check>{children || label}</Label> : null}
        </FormGroup>
    </>)
}

export default Radiobox;