import moment from "moment";
import { useId, useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";

type DateDisplayProps = {
    date: Date | undefined,
    dateOnly?: boolean,
    humanize?: boolean
}

const DateDisplay = ({ date, dateOnly, humanize }: DateDisplayProps) => {
    const momentDate = useMemo(() => moment(date), [date]);
    const id = useId();

    if (!momentDate.isValid()) {
        return "-";
    }

    if (humanize) {
        return <>
            <span id={`date-display-${id}`}>{momentDate.calendar()}</span>
            <UncontrolledTooltip target={`date-display-${id}`}>
                {momentDate.format(dateOnly ? "L" : "L LT")}
            </UncontrolledTooltip>
        </>;
    }
    else {
        return <span>{momentDate.format(dateOnly ? "L" : "L LT")}</span>;
    }
};

export default DateDisplay;