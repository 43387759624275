import { DocumentForm, DocumentKind, DocumentType } from "api/types/contracts/common";
import { ForwardedRef, RefAttributes, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { loadDocumentTypes } from "slices/common/thunk";
import FileUpload from "./FileUpload";
import { Col, Row } from "reactstrap";
import SelectInput from "./SelectInput";
import { useDispatch } from "react-redux";

export type DocumentUploadProps = {
    kind: DocumentKind,
    onUpload?: (document: DocumentForm) => void
}

export type DocumentUploadRef = {
    reset: () => void
}

const DocumentUpload = (props: DocumentUploadProps, ref: ForwardedRef<DocumentUploadRef>) => {
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState<string>();
    const [uploadedFiles, setUploadedFiles] = useState<string[]>()
    const documentTypeOptions = useMemo(() => documentTypes.map(dt => ({ label: dt.name, value: dt.documentTypeId })), [documentTypes]);
    const dispatch = useDispatch();

    const { kind, onUpload } = props;

    useEffect(() => {
        loadDocumentTypes({ kind })(dispatch).then(setDocumentTypes);

    }, [dispatch, kind]);

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setSelectedDocumentType(undefined);
                setUploadedFiles([]);
            }
        };
    }, []);

    useEffect(() => {
        if (onUpload && uploadedFiles && uploadedFiles.length > 0 && selectedDocumentType) {
            //const found = documentTypes.find(dt => dt.documentTypeId === selectedDocumentType);

            onUpload({
                documentTypeId: selectedDocumentType,
                file: uploadedFiles[0]
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFiles, selectedDocumentType]);
    
    return <>
        <Row>
            <Col md={4} sm={6} className="g-4">
                <SelectInput placeholder="Select Document Type" name="documentTypeId" options={documentTypeOptions} value={selectedDocumentType} onChange={setSelectedDocumentType} />
            </Col>
            <Col md={8} sm={6}>
                <FileUpload onUploaded={setUploadedFiles} />
            </Col>
        </Row>
    </>;
}

export default forwardRef(DocumentUpload) as (
    props: DocumentUploadProps & RefAttributes<DocumentUploadRef>
) => ReturnType<typeof DocumentUpload>;