import BusyOverlay from "Components/Common/BusyOverlay";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import Grid from "./_Grid";

const BaysView = () => {
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => ({
            loading: location.loading,
            selectedAisle: location.areas?.find(a => a.areaId === location.selectedAreaId)?.aisles?.find(a => a.aisleId === location.selectedAisleId)
        })
    );

    const { selectedAisle, loading } = useSelector(selectProperties);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedAisle && <Grid aisle={selectedAisle} />}
        </BusyOverlay>
    </>;
}

export default BaysView;