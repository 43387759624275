import { AnyAction, Dispatch } from "redux";
import { setList, loading, apiError } from "./reducer";
import { ListProductBrandsQuery } from "api/types/queries";
import { CreateProductBrandCommand, DeleteProductBrandCommand, UpdateProductBrandCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteProductBrand, getProductBrandList, postProductBrand, putProductBrand } from "api/productBrand";

export const loadList = (params: ListProductBrandsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getProductBrandList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createProductBrand = (params: CreateProductBrandCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        const brand = await postProductBrand(params);
        toast.success(i18n.t("Product brand created"));

        return brand;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return undefined;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateProductBrand = (params: UpdateProductBrandCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putProductBrand(params);
        toast.success(i18n.t("Product brand updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeProductBrand = (params: DeleteProductBrandCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteProductBrand(params);

        toast.success(i18n.t("Product brand deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiProductBrands = (params: DeleteProductBrandCommand[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteProductBrand(item);
        }

        toast.success(i18n.t("Product brands deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}