import { getAuthToken } from "api/auth";

import {
    loginSuccess,
    logoutUserSuccess,
    apiError,
    reset_login_flag,
    loading
} from "./reducer";

import { AnyAction, Dispatch } from "redux";
import { LoginCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { setLoggedInUser } from "helpers/api_helper";

export const loginUser = (login: LoginCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading());

        var result = await getAuthToken(login);
        
        dispatch(loginSuccess(result));
        setLoggedInUser(result);

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
};

export const logoutUser = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        setLoggedInUser(undefined);
        dispatch(logoutUserSuccess());
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const resetLoginFlag = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        const response = dispatch(reset_login_flag());
        return response;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};