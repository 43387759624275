import { useRouteError } from 'react-router-dom';
import NotFound from './_NotFound';
import Unauthorized from './_Unauthorized';

const ErrorPage = () => {
    const error: any = useRouteError();
    
    if (error.status === 404) {
        return <NotFound />
    }
    else if (error.status === 403) {
        return <Unauthorized />
    }   
    else {
        return <div>{error.toString()}</div>
    }
};

export default ErrorPage;