import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { PropsWithChildren, useRef } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { finalizeReceivingEntry, loadReceivingEntry } from "slices/receivings/thunk";

type CompleteButtonProps = PropsWithChildren & {
    disabled?: boolean
};

const CompleteButton = (props: CompleteButtonProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dialogRef = useRef<DialogRef>(null);

    const selectProperties = createSelector(
        (state: RootState) => state.Receiving,
        (receiving) => ({
            processedEntry: receiving.processedEntry!,
            selectedBox: receiving.selectedBox,
            loading: receiving.loading
        }));
        
    const { processedEntry, selectedBox, loading } = useSelector(selectProperties);
    
    const finalize = async () => {
        await finalizeReceivingEntry({ receivingEntryId: processedEntry.receivingEntryId })(dispatch);
        await loadReceivingEntry({ receivingEntryId: processedEntry.receivingEntryId })(dispatch);
    };

    return <>
        <Button color="success" disabled={props.disabled} onClick={() => dialogRef.current?.show()}>{props.children}</Button>
        <Dialog ref={dialogRef} color="success" buttons={["yes", "no"]} busy={loading.save || loading.load} iconClass="ri-checkbox-circle-line"  
            message={t('The receiving process will be completed')} title={`Do you want to continue?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    finalize().then(() => hide())
                }
                else {
                    hide();
                }
            }} />
    </>
}

export default CompleteButton;