import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import CarrierImportTaxPricingRuleSelect from "Components/EntitySelects/CarrierImportTaxPricingRuleSelect";
import CarrierInsuranceRuleSelect from "Components/EntitySelects/CarrierInsuranceRuleSelect";
import CarrierOversizeRuleSelect from "Components/EntitySelects/CarrierOversizeRuleSelect";
import CarrierOverweightRuleSelect from "Components/EntitySelects/CarrierOverweightRuleSelect";
import CarrierRateSourceSelect from "Components/EnumSelects/CarrierRateSourceSelect";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import Radiobox from "Components/Form/Radiobox";
import SelectInput from "Components/Form/SelectInput";
import TextInput from "Components/Form/TextInput";
import ValidatedFileUpload from "Components/Form/Validated/ValidatedFileUpload";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { CarrierAccountContract, CarrierAccountServiceContract, CarrierAccountServiceForm, CarrierContract, CarrierRateSource } from "api/types/contracts/carrier";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { updateCarrierAccountService } from "slices/carrier/thunk";
import * as Yup from "yup";

type UpdateCarrierAccountServiceFormProps = {
    carrier: CarrierContract,
    carrierAccount: CarrierAccountContract,
    carrierAccountService: CarrierAccountServiceContract,
    onCancel?: () => void,
    onSuccess?: () => void
}

const createCarrierAccountServiceForm = (carrierAccountService: CarrierAccountServiceContract) : Partial<CarrierAccountServiceForm> => {
    return {
        apiPercentageIncrease: carrierAccountService.apiPercentageIncrease,
        lookupTableFile: undefined,
        carrierRateSource: carrierAccountService.carrierRateSource,
        deliveryWindow: carrierAccountService.deliveryWindow,
        enabled: carrierAccountService.enabled,
        mergeLookupRates: false,
        carrierImportTaxPricingRuleId: carrierAccountService.carrierImportTaxPricingRule?.carrierImportTaxPricingRuleId,
        carrierInsuranceRuleId: carrierAccountService.carrierInsuranceRule?.carrierInsuranceRuleId,
        carrierOversizeRuleId: carrierAccountService.carrierOversizeRule?.carrierOversizeRuleId,
        carrierOverweightRuleId: carrierAccountService.carrierOverweightRule?.carrierOverweightRuleId
    };
};

const UpdateCarrierAccountServiceForm = (props: UpdateCarrierAccountServiceFormProps) => {
    const { t } = useTranslation();
    const carrierAccountService = createCarrierAccountServiceForm(props.carrierAccountService);
    const [usePercentage, setUsePercentage] = useState(false);
    const [lookupTable, setLookupTable] = useState(props.carrierAccountService.lookupTable);
    const dispatch = useDispatch();

    const selectProperties = createSelector(
        (state: RootState) => state.Carrier,
        (customer) => ({
            error: customer.error,
            loading: customer.loading
        })
    );
    const { loading, error } = useSelector(selectProperties); 

    const validation = useFormik({
        initialValues: carrierAccountService,
        validationSchema: Yup.object<CarrierAccountServiceForm, TypedShape<CarrierAccountServiceForm>>({
            apiPercentageIncrease: Yup.number().when("carrierRateSource", ([carrierRateSource], schema) => {
                return carrierRateSource === "api" && usePercentage ? schema.required("You need to specify a value") : schema.notRequired();
            }),
            carrierRateSource: Yup.string<CarrierRateSource>().required(),
            deliveryWindow: Yup.string().required(t("Delivery Window is required")),
            enabled: Yup.boolean().required(),
            lookupTableFile: Yup.string().when("carrierRateSource", ([carrierRateSource], schema) => {
                return carrierRateSource === "lookupTable" ? schema.required() : schema.notRequired();
            }).test("lookupTableRequired", t("Lookup Table is required"), (value, context) => {
                return context.parent.carrierRateSource === "api" || !_.isEmpty(lookupTable) || !_.isEmpty(value);
            }),
            mergeLookupRates: Yup.boolean().required(),
            carrierImportTaxPricingRuleId: Yup.string().notRequired(),
            carrierInsuranceRuleId: Yup.string().notRequired(),
            carrierOversizeRuleId: Yup.string().notRequired(),
            carrierOverweightRuleId: Yup.string().notRequired()
        }),
        onSubmit: async (values) => {
            await updateCarrierAccountService({
                carrierAccountServiceId: props.carrierAccountService.carrierAccountServiceId,
                carrierAccountService: values
            })(dispatch);

            if (props.onSuccess) {
                props.onSuccess();
            }
        }
    });

    return <>
        <div className="d-flex align-items-center gap-2 mb-2">
            <img src={props.carrierAccountService.carrierService.logoImage ?? props.carrier.logoImage} alt={props.carrierAccountService.carrierService.name} style={{ maxWidth: "100px" }} />
            <div>
                <h5 className="mb-0">{props.carrier.name} - {props.carrierAccount.accountName}</h5>
                <span className="text-muted small">{props.carrierAccountService.carrierService.name} - {props.carrierAccountService.country.name}</span>
            </div>
        </div>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Row>
                <Col className="mb-2">
                    <h5 className="mb-1">{t("Configure your {{service}}", { service: props.carrierAccountService.carrierService.name })}</h5>
                    <p className="mb-0 fs-10">{t("paragraphs:UpdateCarrierAccountServiceDescription")}</p>
                </Col>
            </Row>
            <Row className="g-2 mb-2">
                <Col xs={12}>
                    <Label>{t("Delivery Window")}</Label>
                    <ValidationWrapper validation={validation} field="deliveryWindow">
                        <TextInput />
                    </ValidationWrapper>
                </Col>
                <Col xs={12} className="vstack">
                    <RequiredLabel>{t("Rate Source Type")}</RequiredLabel>
                    <span className="text-muted fs-10">{t("paragraphs:CarrierRateSourceTypeHelpText")}</span>
                    <ValidationWrapper validation={validation} field="carrierRateSource">
                        <CarrierRateSourceSelect />
                    </ValidationWrapper>
                </Col>
            </Row>
            {validation.values.carrierRateSource === "api" && <div>
                <div className="label-separator my-2 label-seperator-2">
                    <span className="label"><i className="ri-settings-4-line me-1"></i> {t("API Rates")}</span>
                </div>
                <Radiobox id="dontUsePercentage" value={!usePercentage} onChange={() => {
                    setUsePercentage(false);
                    validation.setFieldValue("apiPercentageIncrease", undefined);
                }}>
                    <span className="small">{t("Use Existing API Rates")} - <span className="fw-light fst-italic">{t("paragraphs:CarrierApiRatesHelpText")}</span></span>
                </Radiobox>
                <Radiobox id="usePercentage" value={usePercentage} onChange={() => setUsePercentage(true)}>
                    <span className="small">{t("Add Percentage to API Rates")} - <span className="fw-light fst-italic">{t("paragraphs:CarrierCustomPercentageHelpText")}</span></span>
                </Radiobox>
                {usePercentage && <InputGroup size="sm" className="w-25 ms-3">
                    <InputGroupText>%</InputGroupText>
                    <ValidationWrapper validation={validation} field="apiPercentageIncrease">
                        <NumberInput />
                    </ValidationWrapper>
                </InputGroup>}
                <hr />
            </div>}
            {validation.values.carrierRateSource === "lookupTable" &&  <div>
                <div className="label-separator my-2 label-seperator-2">
                    <span className="label"><i className="ri-file-text-line me-1"></i> {t("Lookup Rates")}</span>
                </div>
                {!_.isEmpty(lookupTable) && <div className="d-flex justify-content-between">
                    <div>
                        {t("{{count}} rates uploaded on {{uploadDate}}", { 
                            count: _.size(lookupTable), 
                            uploadDate: moment(props.carrierAccountService.lookupTableUpdateDate).format("L")
                        })}
                    </div>
                    <div className="d-flex gap-2">
                        <Button color="danger" onClick={() => setLookupTable({})}>{t("Delete")}</Button>
                        <Button color="info">{t("Download")}</Button>
                    </div>
                </div>}
                <div>
                    <h5>{t("Update Shipping Rates")}</h5>
                    <InfoAlert kind="top-border">
                        <span className="fs-11">{t("paragraphs:UploadLookupTableDescription")}</span>
                    </InfoAlert>
                    <div className="mb-2">
                        <ValidatedFileUpload validation={validation} field="lookupTableFile" />
                    </div>
                    <div>
                        <ValidationWrapper validation={validation} field="mergeLookupRates">
                            <Checkbox>{t("Delete old prices for this service")}</Checkbox>
                        </ValidationWrapper>
                    </div>
                </div>
                <hr />
            </div>}
            <Row className="g-2 mb-3">
                <Col xs={12}>
                    <Label>{t("Import Tax Pricing Rule")} ({t("for international shipments only")})</Label>
                    <ValidationWrapper validation={validation} field="carrierImportTaxPricingRuleId">
                        <CarrierImportTaxPricingRuleSelect />
                    </ValidationWrapper>
                </Col>
                <Col xs={12}>
                    <Label>{t("Insurance Rule")}</Label>
                    <ValidationWrapper validation={validation} field="carrierInsuranceRuleId">
                        <CarrierInsuranceRuleSelect />
                    </ValidationWrapper>
                </Col>
                <Col xs={12}>
                    <Label>{t("Oversize Rule")}</Label>
                    <ValidationWrapper validation={validation} field="carrierOversizeRuleId">
                        <CarrierOversizeRuleSelect />
                    </ValidationWrapper>
                </Col>
                <Col xs={12}>
                    <Label>{t("Overweight Rule")}</Label>
                    <ValidationWrapper validation={validation} field="carrierOverweightRuleId">
                        <CarrierOverweightRuleSelect />
                    </ValidationWrapper>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-between align-items-center">
                    <div>
                        <ValidationWrapper validation={validation} field="enabled">
                            <Checkbox>{t("Activate this service")}</Checkbox>
                        </ValidationWrapper>
                    </div>
                    <div className="hstack gap-2 justify-content-end">
                        {props.onCancel && <Button type="button" color="light" onClick={props.onCancel}>
                            {t("Cancel")}
                        </Button>}
                        <BusyOverlay size="sm" busy={loading.save}>
                            <ValidatorButton type="submit" color="primary" validation={validation} >
                                {t("Update")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </Col>
            </Row>
        </Form>
    </>;
};

export default UpdateCarrierAccountServiceForm;