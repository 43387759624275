import { LoginInfo } from "api/types/contracts/users";
import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestTransformer, AxiosResponseTransformer } from "axios";

import config from "config";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { ApiError } from "./types";
import { logError } from "./logger";

const dateToStringTransformer = (data: any): any => {
    if (data instanceof Date) {
        // do your specific formatting here
        return moment(data).toISOString(true);
    }

    if (Array.isArray(data)) {
        return data.map(dateToStringTransformer)
    }

    if (typeof data === 'object' && data !== null) {
        var entries = Object.entries(data).map(([key, value]) => [key, dateToStringTransformer(value)]);
        return Object.fromEntries(entries)
    }

    return data;
};

/**
 * An instance of Axios used for making HTTP requests.
 */
const axiosInstance = axios.create({
    transformRequest: [dateToStringTransformer, ...axios.defaults.transformRequest as AxiosRequestTransformer[]],
    //transformResponse: [dateStringToDateTransformer, ...axios.defaults.transformResponse as AxiosResponseTransformer[]],
    baseURL: config.api.API_URL,
    headers: {
        get: {

        },
        post: {
            "Content-Type": "application/json"
        },
        put: {
            "Content-Type": "application/json"
        },
        patch: {
            "Content-Type": "application/json"
        },
        delete: {

        }
    }
})

/**
 * Retrieves the logged-in user information from the local storage.
 * @returns The logged-in user information if available, otherwise undefined.
 */
const getLoggedInUser = (): LoginInfo | undefined => {
    const user = localStorage.getItem("authUser");
    if (!user) {
        return undefined;
    } else {
        return JSON.parse(user);
    }
};

/**
 * Sets the logged-in user in the local storage.
 * @param user The user information to be stored.
 */
const setLoggedInUser = (user: LoginInfo | undefined) => { 
    if (user) {
        localStorage.setItem("authUser", JSON.stringify(user));
    }
    else {
        localStorage.removeItem("authUser");
    }
}

/**
 * Converts an object into a query string.
 * @param obj - The object to convert.
 * @param prefix - The prefix to use for nested properties.
 * @returns The generated query string.
 */
const toQueryString = (obj: any, prefix: string = ""): string => {
    const query = [];
    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        }

        const k = prefix ? `${prefix}[${p}]` : p;
        const v = obj[p];

        if (!v) {
            continue;
        }
        
        let str: string;
        
        if(v instanceof Date) {
            str = encodeURIComponent(k) + "=" + encodeURIComponent(moment(v).toISOString(true));
        }
        else if (typeof v === "object") {
            str = toQueryString(v, k);
        }
        else {
            str = encodeURIComponent(k) + "=" + encodeURIComponent(v);
        }

        if (str) {
            query.push(str);
        }
    }

    return query.join("&");
}

axiosInstance.interceptors.request.use((request) => {
    const user = getLoggedInUser();
    const token = user?.token;
    if (token) 
        request.headers.Authorization = "Bearer " + token;

    return request;
});

// intercepting to capture errors
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response == null) {
            const errorMessage = `${error.name}: [${error.code}] ${error.message}`;

            logError("API request failed", {
                error
            });

            return Promise.reject({ status: 0, message: "Connection failure" } as ApiError);
        }
        else if (error.response.status === 422) {
            const data = error.response.data as FaultResponse;
            const errorMessage = data.detail;
            toast.error(`Failed: [${data.title}] ${data.detail || ""}`);

            logError(`API returns status 422: [${data.title}] ${data.detail || ""}`, {
                error: data
            });
            
            return Promise.reject({ status: error.response.status, message: errorMessage } as ApiError);
        }
        else if (error.response.status === 401) {
            const errorMessage = error.response.data.message;
            toast.error(errorMessage);

            logError(`API returns unauthorized: ${errorMessage}`, {});

            return Promise.reject({ status: error.response.status, message: "Authorization failed" } as ApiError);
        }
        else if (error.response.status === 404) {
            return Promise.reject({ status: error.response.status, message: "Not Found" } as ApiError);
        }
        else if (error.response.status >= 400 && error.response.status < 500) {
            const data = error.response.data as FaultResponse;
            const errorMessage = _.sample(data.errors)?.[0];
            toast.error(`[${data.title}] ${errorMessage || ""}`);

            logError(`API returns status ${error.response.status}: ${errorMessage}`, {
                error: data
            });

            return Promise.reject({ status: error.response.status, message: errorMessage } as ApiError);
        } 
        else if (error.response.status >= 500) {
            const data = error.response.data as FaultResponse;
            const errorMessage = data.detail;
            toast.error(`[${data.title}] ${data.detail || ""}`);

            logError(`API server error: ${errorMessage}`, {
                error: data
            });

            return Promise.reject({ status: error.response.status, message: errorMessage } as ApiError);
        }
    }
);

/**
 * Represents an API client that provides methods for making HTTP requests.
 */
class APIClient {
    /**
     * Sends a GET request to the specified URL with optional query parameters.
     * @param url - The URL to send the GET request to.
     * @param params - Optional query parameters to include in the request.
     * @returns A Promise that resolves to the response data.
     */
    get = async (url: string, params?: any): Promise<any> => {
        if (!params) {
            const response = await axiosInstance.get(url);
            return response?.data;
        }

        const queryString = toQueryString(params);
        const response = await axiosInstance.get(`${url}?${queryString}`);
        return response?.data;
    };

    /**
     * Sends a POST request to the specified URL with the provided data.
     * @param url - The URL to send the request to.
     * @param data - The data to send with the request.
     * @returns A Promise that resolves to the AxiosResponse object.
     */
    post = (url: string, data: any): Promise<AxiosResponse> => {
        return axiosInstance.post(url, data);
    };

    /**
     * Sends a PATCH request to the specified URL with the provided data.
     * @param url - The URL to send the PATCH request to.
     * @param data - The data to send in the PATCH request.
     * @returns A Promise that resolves to the AxiosResponse object.
     */
    patch = (url: string, data: any): Promise<AxiosResponse> => {
        return axiosInstance.patch(url, data);
    };

    /**
     * Sends a PUT request to the specified URL with the provided data.
     * @param url - The URL to send the request to.
     * @param data - The data to send in the request body.
     * @returns A Promise that resolves to the AxiosResponse object.
     */
    put = (url: string, data: any): Promise<AxiosResponse> => {
        return axiosInstance.put(url, data);
    };

    /**
     * Sends a DELETE request to the specified URL.
     * 
     * @param url - The URL to send the request to.
     * @param config - Optional configuration for the request.
     * @returns A Promise that resolves to the AxiosResponse.
     */
    delete = (url: string, config?: AxiosRequestConfig ): Promise<AxiosResponse> => {
        return axiosInstance.delete(url, { ...config });
    };
}

/**
 * Represents a fault response from an API.
 */
export type FaultResponse = {
    type: string;
    title: string;
    status: number;
    detail?: string;
    errors?: {
        [key: string]: string[];
    };
};

/**
 * Represents a paged list of items.
 * @template T The type of items in the list.
 */
export type PagedList<T> = {
    items: T[],
    totalCount: number,
    currentPage: number
}

export type PagerQuery = {
    page: number,
    pageSize: number
}

export type SorterQuery = {
    sortBy?: string,
    sortingOrder?: SortingDirection
}

/**
 * Represents a date range with optional start and end dates.
 */
export type DateRange = {
    start?: Date,
    end?: Date
}

/**
 * Represents a numeric range with optional minimum and maximum values.
 */
export type NumericRange = {
    min?: number,
    max?: number
}

export type SortingDirection = "ascending" | "descending";

export { APIClient, getLoggedInUser, setLoggedInUser, toQueryString };