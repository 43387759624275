import { AreaContract } from "api/types/contracts/locations";
import { Col, Row } from "reactstrap";
import AreaItem from "./_AreaItem";

type GridProps = {
    areas: AreaContract[]
}

const Grid = (props: GridProps) => {
    return (<>
        <Row>
            {props.areas.map(area => <Col lg={3} xl={4} md={6} key={area.areaId}>
                <AreaItem area={area} />
            </Col>)}
        </Row>
    </>);
};

export default Grid;