import { GetProductByAsinQuery, GetProductQuery, ListProductsQuery, SearchProductByAsinQuery } from "api/types/queries";
import { CreateProductCommand, DeleteProductCommand, QuickAddProductsCommand, UpdateProductCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductSearchResultContract, ProductContract } from "./types/contracts/products";

const api = new APIClient();

export const getProduct = async (data: GetProductQuery): Promise<ProductContract | undefined> => {
    const result = await api.get(`/api/product/${data.productId}`);
    return result;
}

export const getProductByAsin = async (data: GetProductByAsinQuery): Promise<ProductContract | undefined> => {
    const result = await api.get(`/api/product/asin/${data.asin}`);
    return result;
}

export const searchProductByAsin = async (data: SearchProductByAsinQuery): Promise<ProductSearchResultContract | undefined> => {
    const result = await api.get(`/api/product/asin/${data.asin}/search`);
    return result;
}

export const getProductList = async (data: ListProductsQuery): Promise<PagedList<ProductContract>> => {
    const result = await api.get(`/api/product`, data);
    return result;
}

export const postProduct = async (data: CreateProductCommand): Promise<void> => {
    await api.post(`/api/product`, data);
}

export const putProduct = async (data: UpdateProductCommand): Promise<void> => {
    await api.put(`/api/product/${data.productId}`, { ...data.product });
}

export const deleteProduct = async (data: DeleteProductCommand): Promise<void> => {
    await api.delete(`/api/product/${data.productId}`);
}

export const postQuickAddProduct = async (data: QuickAddProductsCommand): Promise<ProductContract[]> => {
    const result = await api.post(`/api/product/quick-add`, data);

    return result.data;
}