import { FormikValidation } from "helpers/types";
import { FormFeedback } from "reactstrap";
import classnames from 'classnames';
import Checkbox, { CheckboxProps } from "../Checkbox";

export type ValidatedCheckboxProps<T> = CheckboxProps & {
    validation: FormikValidation<T>,
    field: keyof T
}

const ValidatedCheckbox = <T,>(props: ValidatedCheckboxProps<T>) =>  {
    const fieldName = props.field.toString(); 
    const { error, touched, value } = props.validation.getFieldMeta(fieldName);
    const setValue = (val: boolean) => props.validation.setFieldValue(fieldName, val);

    return (<>
        <Checkbox
            className={classnames(props.className)}
            isValid={touched ? !error : undefined}
            switch={props.switch}
            name={fieldName}
            label={props.label}
            disabled={props.disabled}
            onChange={checked => setValue(!!checked)}
            value={value || false} />
        {touched && error ? (
            <FormFeedback type="invalid" tooltip>
                <div>{error}</div>
            </FormFeedback>
        ) : null}
    </>)
}

export default ValidatedCheckbox;