import SelectInput from "Components/Form/SelectInput"
import { CarrierInsuranceRuleContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadInsuranceRules } from "slices/carrierRules/thunk";

type CarrierInsuranceRuleSelectProps = EntitySelectInputProps<CarrierInsuranceRuleContract, "carrierInsuranceRuleId"> & {

};

const CarrierInsuranceRuleSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CarrierInsuranceRuleSelectProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectProperties = createSelector(
        (state: RootState) => state.CarrierRules,
        (carrier) => ({
            insuranceRules: carrier.insurance?.items ?? [],
            loading: carrier.loading.importTaxPricing.list
        })
    );

    const { insuranceRules, loading } = useSelector(selectProperties);
    const rulesList = useMemo(() => insuranceRules.map(c => ({ value: c.carrierInsuranceRuleId, label: c.name})), [insuranceRules]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = insuranceRules.filter(c => val.includes(c.carrierInsuranceRuleId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = insuranceRules.find(c => c.carrierInsuranceRuleId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierInsuranceRuleId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadInsuranceRules({
            page: 1,
            pageSize: 9999
        })(dispatch);
    }, []);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (insuranceRules.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceRules]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Carrier Import Tax Pricing Rule"),
        busy: loading,
        options: rulesList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierInsuranceRuleSelect;
