import { APIClient } from "../helpers/api_helper";
import { CreateCarrierAccountCommand, CreateShipmentCommand, DeleteCarrierAccountCommand, DeleteCarrierAccountServiceCommand, UpdateCarrierAccountCommand, UpdateCarrierAccountServiceCommand } from "./types/commands";
import { CarrierAccountContract, CarrierAccountServiceContract, CarrierContract, ShipmentInfoContract, ShipmentRateContract } from "./types/contracts/carrier";
import { CarrierPackageTypeContract } from "./types/contracts/shipping";
import { GetCarrierAccountQuery, GetCarrierAccountServiceQuery, GetCarrierQuery, GetShipmentRateQuery, ListCarrierAccountServicesQuery, ListCarrierAccountsQuery, ListCarrierPackageTypesQuery, ListCarriersQuery } from "./types/queries";

const api = new APIClient();

export const getCarrierList = async (data: ListCarriersQuery): Promise<CarrierContract[]> => {
    const result = await api.get(`/api/carrier`);
    return result;
}

export const getCarrier = async (data: GetCarrierQuery): Promise<CarrierContract> => {
    const result = await api.get(`/api/carrier/${data.carrierId}`);
    return result;
}

export const getCarrierAccountList = async (data: ListCarrierAccountsQuery): Promise<CarrierAccountContract[]> => {
    const result = await api.get(`/api/carrier/account`, { ...data });
    return result;
}

export const getCarrierAccountServiceList = async (data: ListCarrierAccountServicesQuery): Promise<CarrierAccountServiceContract[]> => {
    const result = await api.get(`/api/carrier/service`, { ...data });
    return result;
}

export const getCarrierAccount = async (data: GetCarrierAccountQuery): Promise<CarrierAccountContract> => {
    const result = await api.get(`/api/carrier/account/${data.carrierAccountId}`);
    return result;
}

export const getCarrierAccountService = async (data: GetCarrierAccountServiceQuery): Promise<CarrierAccountServiceContract> => {
    const result = await api.get(`/api/carrier/service/${data.carrierAccountServiceId}`);
    return result;
}

export const postCarrierAccount = async (data: CreateCarrierAccountCommand) => {
    const result = await api.post(`/api/carrier/account`, { ...data });
    return result;
}

export const putCarrierAccount = async ({ carrierAccountId, carrierAccount }: UpdateCarrierAccountCommand) => {
    const result = await api.put(`/api/carrier/account/${carrierAccountId}`, { ...carrierAccount });
    return result;
}

export const deleteCarrierAccount = async (data: DeleteCarrierAccountCommand) => {
    const result = await api.delete(`/api/carrier/account/${data.carrierAccountId}`);
    return result;
}

export const putCarrierAccountService = async ({ carrierAccountServiceId, carrierAccountService }: UpdateCarrierAccountServiceCommand) => {
    const result = await api.put(`/api/carrier/service/${carrierAccountServiceId}`, { ...carrierAccountService });
    return result;
}

export const deleteCarrierAccountService = async (data: DeleteCarrierAccountServiceCommand) => {
    const result = await api.delete(`/api/carrier/service/${data.carrierAccountServiceId}`);
    return result;
}

export const postShipmentRateQuery = async (data: GetShipmentRateQuery): Promise<ShipmentRateContract> => {
    const result = await api.post(`/api/carrier/service/rate`, { ...data });

    return result.data;
}

export const getCarrierPackageTypeList = async (data: ListCarrierPackageTypesQuery): Promise<CarrierPackageTypeContract[]> => {
    const result = await api.get(`/api/carrier/packagetype`, { ...data });
    return result;
}

export const postCreateShipment = async (data: CreateShipmentCommand): Promise<ShipmentInfoContract> => {
    const result = await api.post(`/api/carrier/shipment`, { ...data });
    return result.data;
}