import { LoaderFunction, Navigate, RouteObject, defer } from "react-router-dom";

// API
import { getCompany } from "api/company";
import { getWarehouse } from "api/warehouse";
import { getProduct } from "api/product";
import { getCustomer } from "api/customer";
import { getReceivingEntry } from "api/receiving";
import { getPrepService } from "api/prepService";
import { getInbound, getInboundReceivingsList } from "api/inbound";

//Dashboard
import DashboardPage from "pages/Dashboard";

// login
import Login from "pages/Authentication/Login";
import ForgetPasswordPage from "pages/Authentication/ForgetPassword";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";

// Tenants
import CompainesPage from "pages/Companies";
import WarehousesPage from "pages/Warehouses";
import Index from "pages/Home";
import LocationsPage from "pages/Locations";
import BinsPage from "pages/Bins";
import CustomersPage from "pages/Customers";
import VerifyEmail from "pages/Authentication/VerifyEmail";
import ProductsPage from "pages/Products";
import InboundEditPage from "pages/Inbound/Edit";
import InboundListPage from "pages/Inbound/List";
import GateEntryPage from "pages/GateEntry";
import ReceivingsPage from "pages/Receivings";
import InboundDetailPage from "pages/Inbound/Detail";
import PrepServicesPage from "pages/PrepServices";
import GetAQuotePage from "pages/Shipment/GetAQuote";
import ListFbaShipmentsPage from "pages/Shipment/List";
import ProcessFbaShipmentPage from "pages/Shipment/Process";
import { getFbaShipment } from "api/fbaShipping";
import FbaShipmentInvoicePage from "pages/Shipment/Invoice";
import { getInvoice } from "api/invoice";
import PutawayPage from "pages/Inventory/Putaway";
import { getBin } from "api/bin";
import CarriersPage from "pages/Carriers";
import OversizeCarrierRulePage from "pages/CarrierRules/Oversize";
import { getCarrierImportTaxPricingRule, getCarrierInsuranceRule, getCarrierOversizeRule, getCarrierOverweightRule } from "api/carrierRules";
import OverweightCarrierRulePage from "pages/CarrierRules/Overweight";
import InsuranceCarrierRulePage from "pages/CarrierRules/Insurance";
import ImportTaxPricingCarrierRulePage from "pages/CarrierRules/ImportTaxPricing";

type RouteItem = {
    path: string,
    pageFn: () => JSX.Element,
    loader?: LoaderFunction
}

const authProtectedRoutes: RouteItem[] = [
    { path: "/dashboard", pageFn: () => <DashboardPage /> },
    { path: "/index", pageFn: () => <DashboardPage /> },

    //User Profile
    //{ path: "/profile", pageFn: () => <UserProfile /> },

    // Companies
    { path: "/companies/new", pageFn: () => <CompainesPage edit /> },
    { path: "/companies/edit/:companyId", pageFn: () => <CompainesPage edit />, loader: ({ params }) => {
        if (params.companyId) {
            return defer({ 
                company: getCompany({ companyId: params.companyId })
            });
        }
        
        return null;
    }},
    { path: "/companies", pageFn: () => <CompainesPage /> },
    // Warehouses
    { path: "/warehouses/new", pageFn: () => <WarehousesPage edit /> },
    { path: "/warehouses/edit/:warehouseId", pageFn: () => <WarehousesPage edit />, loader: ({ params }) => {
        if (params.warehouseId) {
            return defer({ 
                warehouse: getWarehouse({ warehouseId: params.warehouseId })
            });
        }
        
        return null;
    }},
    { path: "/warehouses", pageFn: () => <WarehousesPage /> },
    { path: "/locations", pageFn: () => <LocationsPage /> },
    { path: "/locations/:warehouseId", pageFn: () => <LocationsPage /> },
    { path: "/bins", pageFn: () => <BinsPage /> },
    { path: "/bins/new", pageFn: () => <BinsPage edit /> },
    { path: "/bins/edit/:binId", pageFn: () => <BinsPage edit />, loader: ({ params }) => {
        if (params.binId) {
            return defer({ 
                bin: getBin({ id: params.binId })
            });
        }
        
        return null;
    }},
    { path: "/customers", pageFn: () => <CustomersPage /> },
    { path: "/customers/edit/:customerId", pageFn: () => <CustomersPage edit />, loader: ({ params }) => {
        if (params.customerId) {
            return defer({ 
                customer: getCustomer({ customerId: params.customerId })
            });
        }
        
        return null;
    }},
    { path: "/products", pageFn: () => <ProductsPage /> },
    { path: "/products/new", pageFn: () => <ProductsPage edit /> },
    { path: "/products/edit/:productId", pageFn: () => <ProductsPage edit />, loader: ({ params }) => {
        if (params.productId) {
            return defer({ 
                product: getProduct({ productId: params.productId })
            });
        }
        
        return null;
    }},
    { path: "/inbounds", pageFn: () => <InboundListPage /> },
    { path: "/inbounds/new", pageFn: () => <InboundEditPage /> },
    { path: "/inbounds/:inboundId", pageFn: () => <InboundDetailPage />, loader: ({ params }) => {
        if (params.inboundId) {
            return defer({ 
                inbound: getInbound({ inboundId: params.inboundId }),
                receivings: getInboundReceivingsList({ inboundId: params.inboundId })
            });
        }
        
        return null;
    }},
    { path: "/inbounds/edit/:inboundId", pageFn: () => <InboundEditPage />, loader: ({ params }) => {
        if (params.inboundId) {
            return defer({ 
                inbound: getInbound({ inboundId: params.inboundId })
            });
        }
        
        return null;
    }},
    { path: "/gate-entry", pageFn: () => <GateEntryPage /> },
    { path: "/gate-entry/:receivingId", pageFn: () => <GateEntryPage />, loader: ({ params }) => {
        if (params.receivingId) {
            return defer({ 
                receivingEntry: getReceivingEntry({ receivingEntryId: params.receivingId })
            });
        }
        
        return null;
    }},
    { path: "/gate-entry/inbound/:inboundId", pageFn: () => <GateEntryPage />, loader: ({ params }) => {
        if (params.inboundId) {
            return defer({ 
                inbound: getInbound({ inboundId: params.inboundId })
            });
        }
        
        return null;
    }},
    { path: "receivings", pageFn: () => <ReceivingsPage /> },
    { path: "receivings/:receivingId", pageFn: () => <ReceivingsPage process />, loader: ({ params }) => {
        if (params.receivingId) {
            return defer({ 
                receivingEntry: getReceivingEntry({ receivingEntryId: params.receivingId })
            });
        }
        
        return null;
    }},
    { path: "prep-services", pageFn: () => <PrepServicesPage /> },
    { path: "prep-services/new", pageFn: () => <PrepServicesPage edit /> },
    { path: "prep-services/edit/:prepServiceId", pageFn: () => <PrepServicesPage edit />, loader: ({ params }) => {
        if (params.prepServiceId) {
            return defer({ 
                prepService: getPrepService({ prepServiceId: params.prepServiceId })
            });
        }
        
        return null;
    }},
    { path: "get-a-quote", pageFn: () => <GetAQuotePage /> },
    { path: "fba-shipments", pageFn: () => <ListFbaShipmentsPage /> },
    { path: "fba-shipment/:fbaShipmentId", pageFn: () => <ProcessFbaShipmentPage />, loader: ({ params }) => {
        if (params.fbaShipmentId) {
            const promise = getFbaShipment({ fbaShipmentId: params.fbaShipmentId });

            return defer({ 
                fbaShipment: promise
            });
        }
        
        return null;
    }},
    { path: "create-invoice/:fbaShipmentId", pageFn: () => <FbaShipmentInvoicePage create /> , loader: ({ params }) => {
        if (params.fbaShipmentId) {
            return defer({ 
                fbaShipment: getFbaShipment({ fbaShipmentId: params.fbaShipmentId })
            });
        }
        
        return null;
    }},
    { path: "invoice/:invoiceId", pageFn: () => <FbaShipmentInvoicePage /> , loader: ({ params }) => {
        if (params.invoiceId) {
            return defer({ 
                invoice: getInvoice({ fbaShipmentInvoiceId: params.invoiceId })
            });
        }
        
        return null;
    }},
    { path: "bin-putaway", pageFn: () => <PutawayPage forBin /> },
    { path: "item-putaway", pageFn: () => <PutawayPage forItem /> },
    { path: "carriers", pageFn: () => <CarriersPage /> },
    { path: "carrier-rules/oversize-rules", pageFn: () => <OversizeCarrierRulePage />},
    { path: "carrier-rules/oversize-rules/new", pageFn: () => <OversizeCarrierRulePage edit />},
    { path: "carrier-rules/oversize-rules/edit/:oversizeRuleId", pageFn: () => <OversizeCarrierRulePage edit />, loader: ({ params }) => {
        if (params.oversizeRuleId) {
            return defer({ 
                oversizeRule: getCarrierOversizeRule({ carrierOversizeRuleId: params.oversizeRuleId })
            });
        }
        
        return null;
    }},
    { path: "carrier-rules/overweight-rules", pageFn: () => <OverweightCarrierRulePage />},
    { path: "carrier-rules/overweight-rules/new", pageFn: () => <OverweightCarrierRulePage edit />},
    { path: "carrier-rules/overweight-rules/edit/:overweightRuleId", pageFn: () => <OverweightCarrierRulePage edit />, loader: ({ params }) => {
        if (params.overweightRuleId) {
            return defer({ 
                overweightRule: getCarrierOverweightRule({ carrierOverweightRuleId: params.overweightRuleId })
            });
        }
        
        return null;
    }},
    { path: "carrier-rules/insurance-rules", pageFn: () => <InsuranceCarrierRulePage />},
    { path: "carrier-rules/insurance-rules/new", pageFn: () => <InsuranceCarrierRulePage edit />},
    { path: "carrier-rules/insurance-rules/edit/:insuranceRuleId", pageFn: () => <InsuranceCarrierRulePage edit />, loader: ({ params }) => {
        if (params.insuranceRuleId) {
            return defer({ 
                insuranceRule: getCarrierInsuranceRule({ carrierInsuranceRuleId: params.insuranceRuleId })
            });
        }
        
        return null;
    }},
    { path: "carrier-rules/import-tax-rules", pageFn: () => <ImportTaxPricingCarrierRulePage />},
    { path: "carrier-rules/import-tax-rules/new", pageFn: () => <ImportTaxPricingCarrierRulePage edit />},
    { path: "carrier-rules/import-tax-rules/edit/:importTaxRuleId", pageFn: () => <ImportTaxPricingCarrierRulePage edit />, loader: ({ params }) => {
        if (params.importTaxRuleId) {
            return defer({ 
                importTaxRule: getCarrierImportTaxPricingRule({ carrierImportTaxPricingRuleId: params.importTaxRuleId })
            });
        }
        
        return null;
    }},
    // this route should be at the end of all other routes
    {
        path: "/",
        pageFn: () => <Navigate to="/dashboard" />
    }
];

const publicRoutes: RouteItem[] = [
    // Authentication Page
    { path: "/logout", pageFn: () => <Logout /> },
    { path: "/login", pageFn: () => <Login /> },
    { path: "/forgot-password", pageFn: () => <ForgetPasswordPage /> },
    { path: "/register", pageFn: () => <Register /> },
    { path: "/verify-email", pageFn: () => <VerifyEmail /> },
    { path: "/home", pageFn: () => <Index /> }
];

export { authProtectedRoutes, publicRoutes };