import { CreateFbaShipmentTransportsCommand, CreateShipmentCommand } from "api/types/commands";
import { CarrierContract, PackageInfoContract, ProductInfoContract, ShipmentInfoContract } from "api/types/contracts/carrier";
import { UnitOfCurrency, UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { FbaShipmentPackageContract, FbaShipmentTransportForm, ShipmentChargesContract, ShipmentDutyType } from "api/types/contracts/shipping";
import classNames from "classnames";
import { WarningAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import ClipboardCopy from "Components/Common/ClipboardCopy";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import PrivateDownload from "Components/Common/PrivateDownload";
import TruncatableText from "Components/Common/TruncatableText";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import CarrierAccountServiceSelect from "Components/EntitySelects/CarrierAccountServiceSelect";
import CarrierPackageTypeSelect from "Components/EntitySelects/CarrierPackageTypeSelect";
import CarrierSelect from "Components/EntitySelects/CarrierSelect";
import ShipmentTargetSelect from "Components/EntitySelects/ShipmentTargetSelect";
import ShipmentDutyTypeSelect from "Components/EnumSelects/ShipmentDutyTypeSelect";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import WeightInput from "Components/Form/UnitInputs/WeightInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { withCdn } from "helpers/urlHelper";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, PopoverBody, Row, Table, UncontrolledButtonDropdown, UncontrolledPopover } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { createShipment, getCarrierAccountServiceCost } from "slices/carrier/thunk";
import { createFbaShipmentTransports, loadFbaShipment, removeFbaShipmentTransport } from "slices/shipping/fbaShipping/thunk";
import * as Yup from 'yup';

type PackageInfoFormType = PackageInfoContract & {
    selected: boolean
};

const convertFbaPackageToPackageInfo = (pkg: FbaShipmentPackageContract): PackageInfoFormType => {
    return {
        selected: false,
        width: pkg.box.width,
        height: pkg.box.height,
        length: pkg.box.length,
        weight: pkg.box.weight,
        currency: "usd",
        identifier: pkg.fbaShipmentPackageId,
        lengthUnit: pkg.box.lengthUnit,
        weightUnit: pkg.box.weightUnit,
        shipmentTargetId: pkg.shipmentTargetId,
        value: pkg.value ?? 0,
        items: pkg.box.products.map<ProductInfoContract>(pr => ({
            productId: pr.productId,
            quantity: pr.count
        }))
    };
}

type CreateShipmentFormProps = {
    onSuccess: () => void
}

const CreateShipmentForm = (props: CreateShipmentFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const deleteShipmentConfirmDialog = useRef<DialogRef>(null);
    const [selectedCarrier, setSelectedCarrier] = useState<CarrierContract>();
    const [resultMode, setResultMode] = useState<"rates" | "shipment">();
    const [shipmentInfo, setShipmentInfo] = useState<ShipmentInfoContract>();
    const [processingPackage, setProcessingPackage] = useState<FbaShipmentPackageContract>();
    const [selectedPackages, setSelectedPackages] = useState<FbaShipmentPackageContract[]>([]);
    
    const selectProperties = createSelector(
        (state: RootState) => state,
        (state) => ({
            fbaShipment: state.FbaShipping.processingItem!,
            carrierServiceRates: state.Carrier.carrierAccountServices,
            loading: {
                createShipment: state.Carrier.loading.createShipment,
                save: state.FbaShipping.loading.save,
                delete: state.FbaShipping.loading.delete
            }
        })
    );
    
    // Inside your component
    const { fbaShipment, carrierServiceRates, loading } = useSelector(selectProperties);

    const validation = useFormik({
        initialValues: {
            packages: fbaShipment.packages.map(p => convertFbaPackageToPackageInfo(p)),
            carrierAccountServiceId: "",
            originWarehouseId: fbaShipment.warehouse.warehouseId,
            dutyPaymentType: "sender",
            packageTypeCode: "",
            shipmentCode: fbaShipment.shipmentCode
        } satisfies CreateShipmentCommand,
        validationSchema: Yup.object<CreateShipmentCommand, TypedShape<CreateShipmentCommand>>({
            packages: Yup.array<PackageInfoContract>(Yup.object<PackageInfoContract>({
                selected: Yup.boolean().required(),
                width: Yup.number().required(t("Width is required")).when("selected", ([selected], schema) => selected ? schema : schema.notRequired()),
                height: Yup.number().when("selected", ([selected], schema) => selected ? schema.required(t("Height is required")) : schema.notRequired()),
                length: Yup.number().when("selected", ([selected], schema) => selected ? schema.required(t("Length is required")) : schema.notRequired()),
                weight: Yup.number().when("selected", ([selected], schema) => selected ? schema.required(t("Weight is required")) : schema.notRequired()),
                currency: Yup.string<UnitOfCurrency>().when("selected", ([selected], schema) => selected ? schema.required() : schema.notRequired()),
                identifier: Yup.string().required(),
                lengthUnit: Yup.string<UnitOfLength>().required(),
                weightUnit: Yup.string<UnitOfWeight>().required(),
                shipmentTargetId: Yup.string().when("selected", ([selected], schema) => selected ? schema.required("You must select destination") : schema.notRequired()),
                value: Yup.number().when("selected", ([selected], schema) => selected ? schema.required("You must specify a value") : schema.notRequired()),
                items: Yup.array<ProductInfoContract>(Yup.object<ProductInfoContract, TypedShape<ProductInfoContract>>({
                    productId: Yup.string().required(),
                    quantity: Yup.number().required()
                })).required()
            })).required().test("at-least-one", t("You must select at least one package"), (value: PackageInfoFormType[]) => value.some(p => p.selected)),
            shipmentCode: Yup.string().required(),
            originWarehouseId: Yup.string().required(),
            carrierAccountServiceId: Yup.string().required("You must select carrier service"),
            dutyPaymentType: Yup.string<ShipmentDutyType>().required("You must select duty payer"),
            packageTypeCode: Yup.string().notRequired()
        }),
        onSubmit: async values => {
            setResultMode("shipment");
            setShipmentInfo(undefined);
            setSelectedPackages(_.chain(validation.values.packages)
                .filter(p => p.selected)
                .map(p => fbaShipment.packages.find(pkg => pkg.fbaShipmentPackageId === p.identifier))
                .compact()
                .value());
            
            const packages = values.packages.filter(p => p.selected);
            const shipment = await createShipment({
                ...values,
                packages,
            })(dispatch);

            setShipmentInfo(shipment);
        }
    });

    const selectedRates = carrierServiceRates.find(r => r.carrierAccountService.carrierAccountServiceId === validation.values.carrierAccountServiceId);

    const getRates = async () => {
        setResultMode("rates");
        setSelectedPackages(_.chain(validation.values.packages)
            .filter(p => p.selected)
            .map(p => fbaShipment.packages.find(pkg => pkg.fbaShipmentPackageId === p.identifier))
            .compact()
            .value());
        
        if (validation.values.carrierAccountServiceId && validation.values.packages) {
            await getCarrierAccountServiceCost({
                carrierAccountServiceId: validation.values.carrierAccountServiceId,
                shipmentTargetId: fbaShipment.shipmentTarget.shipmentTargetId,
                originWarehouseId: validation.values.originWarehouseId,
                packages: validation.values.packages.filter(p => p.selected),
                packageTypeCode: validation.values.packageTypeCode,
                shipmentCode: fbaShipment.shipmentCode
            })(dispatch);
        }
    }

    const deleteShipment = (item: FbaShipmentPackageContract) => {
        setProcessingPackage(item);
        deleteShipmentConfirmDialog.current?.show();
    } 

    useEffect(() => {
        if (fbaShipment) {
            setSelectedCarrier(fbaShipment.carrierAccountService.carrierService.carrier);
            validation.setFieldValue("carrierAccountServiceId", fbaShipment.carrierAccountService.carrierAccountServiceId);

            for (let i = 0; i < validation.values.packages.length; i++) {
                validation.setFieldValue(`packages.${i}.currency`, fbaShipment.warehouse.settings.unitOfCurrency);
                
                if (!validation.values.packages[i].shipmentTargetId) {
                    validation.setFieldValue(`packages.${i}.shipmentTargetId`, fbaShipment.shipmentTarget.shipmentTargetId);
                }
                
                if (!validation.values.packages[i].value) {
                    const productsInPackage = fbaShipment.packages[i].box.products.map(pr => ({
                        product: fbaShipment.items.find(it => it.product.productId === pr.productId)!,
                        count: pr.count
                    }));

                    validation.setFieldValue(`packages.${i}.value`, fbaShipment.packages[i].value ?? _.round(_.sumBy(productsInPackage, p => p.product.buyPrice * p.count), 2));
                }
            }
        }
    }, [fbaShipment]);

    useEffect(() => {
        if (shipmentInfo?.totalCost && selectedPackages.length > 0) {
            const packages = validation.values.packages.filter(p => p.selected);

            transportsValidation.setFieldValue("fbaShipmentTransports", shipmentInfo.packages.map<FbaShipmentTransportForm>((pkg, i) => {
                const pkgInfo = selectedPackages[i];
                const valuesPackage = packages[i];

                return {
                    fbaShipmentPackageId: pkgInfo.fbaShipmentPackageId,
                    carrierAccountServiceId: validation.values.carrierAccountServiceId,
                    packageTypeCode: validation.values.packageTypeCode,
                    dutyType: validation.values.dutyPaymentType,
                    totalCharge: pkg.rates.totalCost ?? 0,
                    packageValue: valuesPackage.value,
                    trackingNumber: pkg.trackingNumber,
                    shipmentTargetId: valuesPackage.shipmentTargetId!,
                    shipmentCharges: {
                        totalCost: pkg.rates.totalCost ?? 0,
                        importTaxCost: pkg.rates.importTaxCost ?? 0,
                        shippingCost: pkg.rates.shippingCost ?? 0,
                        insuranceCost: pkg.rates.insuranceCost ?? 0,
                        oversizeCost: pkg.rates.oversizeCost ?? 0,
                        overweightCost: pkg.rates.overweightCost ?? 0,
                        details: pkg.rates.details
                    },
                    commercialInvoicePath: pkg.commercialInvoicePath,
                    labelPath: pkg.labelPath
                };
            }));
        }
    }, [shipmentInfo, selectedPackages]);

    // useEffect(() => {
    //     validation.setFieldValue("carrierAccountServiceId", "");
    //     validation.setFieldValue("packageTypeCode", "");
    // }, [selectedCarrier]);

    const transportsValidation = useFormik({
        initialValues: {
            fbaShipmentId: fbaShipment.fbaShipmentId,
            fbaShipmentTransports: []
        } satisfies CreateFbaShipmentTransportsCommand,
        validationSchema: Yup.object<CreateFbaShipmentTransportsCommand, TypedShape<CreateFbaShipmentTransportsCommand>>({
            fbaShipmentId: Yup.string().required(),
            fbaShipmentTransports: Yup.array<FbaShipmentTransportForm>(Yup.object<FbaShipmentTransportForm, TypedShape<FbaShipmentTransportForm>>({
                fbaShipmentPackageId: Yup.string().required(),
                carrierAccountServiceId: Yup.string().required(),
                packageTypeCode: Yup.string().notRequired(),
                dutyType: Yup.string<ShipmentDutyType>().required(),
                totalCharge: Yup.number().required(t("You need to specify shipment charge")),
                packageValue: Yup.number().required(),
                trackingNumber: Yup.string().required(),
                shipmentTargetId: Yup.string().required(),
                shipmentCharges: Yup.object<ShipmentChargesContract, TypedShape<ShipmentChargesContract>>({
                    totalCost: Yup.number().required(),
                    importTaxCost: Yup.number().required(),
                    insuranceCost: Yup.number().required(),
                    oversizeCost: Yup.number().required(),
                    overweightCost: Yup.number().required(),
                    shippingCost: Yup.number().required(),
                    details: Yup.object().required()
                }).required(),
                commercialInvoicePath: Yup.string().notRequired(),
                labelPath: Yup.string().required()
            })).length(1, t("You need to create shipment first")).required()
        }),
        onSubmit: async values => {
            await createFbaShipmentTransports(values)(dispatch);
            await loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId })(dispatch);
            props.onSuccess();
        }
    });

    return <>
        <p className="small">{t("paragraphs:CreateShipmentDescription")}</p>
        <h5>{t("Packages")}</h5>
        <p className="small">{t("Select the boxes for your fba shipment and assign them to Amazon fullfillment centers")}</p>
        <Card body className="">
            <div className="table-card">
                <Table borderless className="mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "12%" }}>
                                <Checkbox 
                                    value={selectedPackages.length === fbaShipment.packages.length} 
                                    indeterminate={selectedPackages.length !== 0 && selectedPackages.length < fbaShipment.packages.length}
                                    onChange={val => {
                                        for (let i = 0; i < validation.values.packages.length; i++) {
                                            const pkg = fbaShipment.packages[i];

                                            if (!pkg.transport) {
                                                validation.setFieldValue(`packages.${i}.selected`, val);
                                            }
                                        }
                                    }}>
                                        {t("Select All")}
                                </Checkbox>
                            </th>
                            <th style={{ width: "30%" }}>{t("Dimensions")}</th>
                            <th style={{ width: "10%" }}>{t("Weight")}</th>
                            <th style={{ width: "10%" }}>{t("Total Value")}</th>
                            <th style={{ width: "33%" }}>{t("Destination")}</th>
                            <th style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fbaShipment.packages.map((pkg, i) => <tr key={i} className={classNames({
                            "align-middle": !pkg.transport
                        })}>
                            <td>
                                <Checkbox 
                                    disabled={!!pkg.transport}
                                    value={validation.values.packages[i].selected}
                                    onChange={val => {
                                        validation.setFieldValue(`packages.${i}.selected`, val);
                                    }}>
                                        <small>{t("Package {{number}}", { number: pkg.box.boxNumber })}</small>
                                </Checkbox>
                            </td>
                            <td>
                                <div className="hstack gap-2">
                                    <ValidationWrapper validation={validation} field={`packages.${i}.width`} >
                                        <NumberInput size="sm" className="text-center" decimalPlaces={2} disabled={!!pkg.transport} />
                                    </ValidationWrapper>
                                    <span>x</span>
                                    <ValidationWrapper validation={validation} field={`packages.${i}.height`}>
                                        <NumberInput size="sm" className="text-center" decimalPlaces={2} disabled={!!pkg.transport} />
                                    </ValidationWrapper>
                                    <span>x</span>
                                    <ValidationWrapper validation={validation} field={`packages.${i}.length`}>
                                        <NumberInput size="sm" className="text-center" decimalPlaces={2} disabled={!!pkg.transport} />
                                    </ValidationWrapper>
                                    <small>{pkg.box.lengthUnit}</small>
                                </div>
                                {pkg.transport && <div className="vstack gap-1 fs-10 mt-1">
                                    <div className="hstack gap-1">
                                        <i className="bx bx-package text-info"></i>
                                        <TruncatableText maxLines={1}>
                                            <span>{pkg.transport.carrierAccountService.carrierService.name}</span>
                                        </TruncatableText>
                                    </div>
                                    <div>
                                        <i className="bx bx-dollar-circle text-success me-1"></i>
                                        <span>{t("Rate")}: <Currency value={pkg.transport.totalCharge} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></span>
                                    </div>
                                </div>}
                            </td>
                            <td>
                                <div>
                                    <ValidationWrapper validation={validation} field={`packages.${i}.weight`} hideError>
                                        <WeightInput size="sm" className="text-center" style={{ width: "3rem" }} unit={pkg.box.weightUnit} disabled={!!pkg.transport} />
                                    </ValidationWrapper>
                                </div>
                                {pkg.transport && <div className="vstack gap-1 fs-10 mt-1">
                                    <div className="hstack">
                                        <i className="bx bxs-copy text-secondary me-1"></i>
                                        <a href="javascript:void();" onClick={() => navigator.clipboard.writeText(pkg.transport!.trackingNumber)}>{pkg.transport.trackingNumber}</a>
                                    </div>
                                    <div>
                                        <i className="bx bx-printer text-secondary me-1"></i>
                                        <a href="javascript:void();">
                                            {t("Print Label")}
                                        </a>
                                    </div>
                                </div>}
                            </td>
                            <td>
                                <ValidationWrapper validation={validation} field={`packages.${i}.value`}>
                                    <CurrencyInput size="sm" className="text-center" style={{ width: "3rem" }} currency={fbaShipment.warehouse.settings.unitOfCurrency} disabled={!!pkg.transport} />
                                </ValidationWrapper>
                            </td>
                            <td>
                                <ValidationWrapper validation={validation} field={`packages.${i}.shipmentTargetId`}>
                                    <ShipmentTargetSelect countryCode={fbaShipment.shipmentTarget.countryCode} size="sm" disabled={!!pkg.transport} />
                                </ValidationWrapper>
                            </td>
                            <td>
                                {pkg.transport && <UncontrolledButtonDropdown>
                                    <DropdownToggle tag="button" className="btn btn-sm btn-ghost-dark btn-icon">
                                        <i className="ri-more-2-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>{t("Print Label")}</DropdownItem>
                                        <DropdownItem href={withCdn(pkg.transport.labelPath)} target="_blank" rel="noreferrer">{t("View Label")}</DropdownItem>
                                        <DropdownItem href={withCdn(pkg.transport.commercialInvoicePath)} target="_blank" rel="noreferrer">{t("View Commercial Invoice")}</DropdownItem>
                                        <DropdownItem onClick={() => navigator.clipboard.writeText(pkg.transport!.trackingNumber)}>{t("Copy Tracking Number")}</DropdownItem>
                                        <DropdownItem onClick={() => deleteShipment(pkg)}>{t("Delete Shipment")}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>}
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
        </Card>
        <Row>
            <Col xs={4}>
                <Card body className="">
                    <div className="vstack">
                        <FormGroup>
                            <Label>{t("Duty Payer")}</Label>
                            <ValidationWrapper validation={validation} field="dutyPaymentType">
                                <ShipmentDutyTypeSelect />
                            </ValidationWrapper>
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("Carrier")}</Label>
                            <CarrierSelect 
                                userDefinedOnly 
                                shipmentTargetId={fbaShipment.shipmentTarget.shipmentTargetId} 
                                value={selectedCarrier?.carrierId}
                                onSelect={setSelectedCarrier} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("Carrier Service")}</Label>
                            <ValidationWrapper validation={validation} field="carrierAccountServiceId">
                                <CarrierAccountServiceSelect 
                                    shipmentTargetId={fbaShipment.shipmentTarget.shipmentTargetId} 
                                    carrierId={selectedCarrier?.carrierId} />
                            </ValidationWrapper>
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("Package Type")}</Label>
                            <ValidationWrapper validation={validation} field="packageTypeCode">
                                <CarrierPackageTypeSelect carrierId={selectedCarrier?.carrierId} />
                            </ValidationWrapper>
                        </FormGroup>
                    </div>
                </Card>
            </Col>
            <Col xs={8}>
                <Card body>
                    <div className="table-card">
                        {!resultMode && <p className="text-muted text-center mb-0">
                            {t("Get Rates or Create Shipment to inspect packages")}
                        </p>}
                        {resultMode === "rates" && selectedRates && <>
                            <Table className="mb-0">
                                <thead className="table-light align-middle text-center">
                                    <tr>
                                        <th>{t("Packages")}</th>
                                        <th>{t("Dim. & Weight")}</th>
                                        <th>{t("Retail Rate")}</th>
                                        <th>{t("API Rate")}</th>
                                        <th>{t("API Rate Details")}</th>
                                    </tr>
                                </thead>
                                <tbody className="align-middle">
                                    {selectedRates.rateLoading ? <>
                                        <tr>
                                            <td colSpan={7}>
                                                <Loader height="70px" />
                                            </td>
                                        </tr>
                                    </> : !selectedRates.success ? <>
                                        <tr>
                                            <td colSpan={7}>
                                                <WarningAlert kind="top-border" className="mb-0">
                                                    {selectedRates.message}
                                                </WarningAlert>
                                            </td>
                                        </tr>
                                    </> : <>
                                        {selectedRates.packageRates.map((rate, i) => {
                                            const pkg = selectedPackages[i];
                                            const formPackage = validation.values.packages.find(p => p.identifier === pkg.fbaShipmentPackageId)!;

                                            return <tr key={i}>
                                                <td>
                                                    <div className="hstack gap-1 align-items-center">
                                                        <i className="bx bx-package text-info"></i>
                                                        <small>{t("Package {{number}}", { number: pkg.box.boxNumber })}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="vstack">
                                                        <strong><Dimensions value={formPackage} unit={pkg.box.lengthUnit} /></strong>
                                                        <small><Weight value={formPackage.weight} unit={pkg.box.weightUnit} /></small>
                                                    </div>
                                                </td>
                                                <td>
                                                    {rate.totalCost ? 
                                                        <Badge color="success"><Currency value={rate.totalCost} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></Badge> 
                                                    : 
                                                        <Badge color="warning"><i className="ri-alert-fill" /></Badge>
                                                    }
                                                </td>
                                                <td>
                                                    {rate.totalCost ? 
                                                        <Badge color="success"><Currency value={rate.totalCost / selectedRates.ratio} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></Badge> 
                                                    : 
                                                        <Badge color="warning"><i className="ri-alert-fill" /></Badge>
                                                    }
                                                </td>
                                                <td>
                                                    <Button color="soft-danger" size="sm" id={`rate-details-${i}`}>
                                                        <span>{t("Details")}</span>
                                                    </Button>
                                                    <UncontrolledPopover target={`rate-details-${i}`} trigger="legacy">
                                                        <PopoverBody>
                                                            <div>
                                                                {_.map(rate.details, (d, k) => <dl key={k} className="row mb-0">
                                                                    <dd className="col-auto mb-0">{k}</dd>
                                                                    <dt className="col-auto ms-auto">{d}</dt>
                                                                </dl>)}
                                                            </div>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </td>
                                            </tr>;
                                        })}
                                    </>}
                                </tbody>
                            </Table>
                        </>}
                        {resultMode === "shipment" && <>
                            <Table className="mb-0">
                                <thead className="table-light align-middle text-center">
                                    <tr>
                                        <th>{t("Packages")}</th>
                                        <th>{t("Dim. & Weight")}</th>
                                        <th>{t("Retail Rate")}</th>
                                        <th>{t("API Rate")}</th>
                                        <th>{t("API Rate Details")}</th>
                                        <th>{t("Tracking Number")}</th>
                                        <th>{t("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody className="align-middle">
                                    {loading.createShipment ? <>
                                        <tr>
                                            <td colSpan={7}>
                                                <Loader height="70px" />
                                            </td>
                                        </tr>
                                    </> : !shipmentInfo?.totalCost ? <>
                                        <tr>
                                            <td colSpan={7}>
                                                <WarningAlert kind="top-border" className="mb-0">
                                                    {shipmentInfo?.message}
                                                </WarningAlert>
                                            </td>
                                        </tr>
                                    </> : <>
                                        {shipmentInfo.packages.map((pkgInfo, i) => {
                                            const pkg = selectedPackages[i];
                                            const formPackage = validation.values.packages.find(p => p.identifier === pkg.fbaShipmentPackageId)!;

                                            return <tr key={i}>
                                                <td>
                                                    <div className="hstack gap-1 align-items-center text-nowrap">
                                                        <i className="bx bx-package text-info"></i>
                                                        <small>{t("Package {{number}}", { number: pkg.box.boxNumber })}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="vstack">
                                                        <strong><Dimensions value={formPackage} unit={pkg.box.lengthUnit} /></strong>
                                                        <small><Weight value={formPackage.weight} unit={pkg.box.weightUnit} /></small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ValidationWrapper validation={transportsValidation} field={`fbaShipmentTransports.${i}.totalCharge`}>
                                                        <CurrencyInput size="sm" currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                                    </ValidationWrapper>
                                                </td>
                                                <td>
                                                    {pkgInfo.rates.totalCost ? 
                                                        <Badge color="success"><Currency value={pkgInfo.rates.totalCost / shipmentInfo.ratio} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></Badge> 
                                                    : 
                                                        <Badge color="warning"><i className="ri-alert-fill" /></Badge>
                                                    }
                                                </td>
                                                <td>
                                                    <Button color="soft-danger" size="sm" id={`shipment-details-${i}`}>
                                                        <span>{t("Details")}</span>
                                                    </Button>
                                                    <UncontrolledPopover target={`shipment-details-${i}`} trigger="legacy">
                                                        <PopoverBody>
                                                        <div>
                                                                {_.map(pkgInfo.rates.details, (d, k) => <dl key={k} className="row mb-0">
                                                                    <dd className="col-auto mb-0">{k}</dd>
                                                                    <dt className="col-auto ms-auto text-end"><pre className="mb-0 fs-12">{d}</pre></dt>
                                                                </dl>)}
                                                            </div>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </td>
                                                <td>
                                                    <ClipboardCopy text={pkgInfo.trackingNumber}>
                                                        <small>{pkgInfo.trackingNumber}</small>
                                                    </ClipboardCopy>
                                                </td>
                                                <td>
                                                    <div className="hstack gap-1">
                                                        <Button color="soft-primary" className="btn-icon" size="sm">
                                                            <i className="ri-printer-fill"></i>
                                                        </Button>
                                                        <PrivateDownload href={pkgInfo.commercialInvoicePath}>
                                                            {url => <>
                                                                {url ? <a href={url} target="_blank" rel="noreferrer" className="btn btn-sm btn-icon btn-soft-danger">
                                                                    <i className="ri-file-text-fill"></i>
                                                                </a> : <a href="#" className="btn btn-sm btn-icon btn-soft-danger disabled">
                                                                    <i className="ri-file-text-fill"></i>
                                                                </a>}
                                                            </>}
                                                        </PrivateDownload>
                                                        <PrivateDownload href={pkgInfo.labelPath}>
                                                            {url => <>
                                                                <a href={url} target="_blank" rel="noreferrer" className="btn btn-sm btn-icon btn-soft-success">
                                                                    <i className="bx bx-package"></i>
                                                                </a>    
                                                            </>}
                                                        </PrivateDownload>
                                                    </div>
                                                </td>
                                            </tr>;
                                        })}
                                    </>}
                                </tbody>
                            </Table>
                        </>}
                    </div>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col className="col-auto">
                <div className="hstack gap-2">
                    <BusyOverlay size="sm" busy={loading.createShipment} backgroundColor="white" spinnerColor="tenant-primary">
                        <ValidatorButton validation={validation} type="button" color="outline-dark" className="btn-label" onClick={validation.submitForm}>
                            <i className="bx bx-package label-icon"></i>
                            <span>{t("Create Shipment")}</span>
                        </ValidatorButton>
                    </BusyOverlay>
                    <BusyOverlay size="sm" busy={selectedRates?.rateLoading ?? false} backgroundColor="white" spinnerColor="tenant-primary">
                        <ValidatorButton validation={validation} type="button" color="outline-info" className="btn-label" onClick={getRates}>
                            <i className="bx bx-dollar-circle label-icon"></i>
                            <span>{t("Get Rates")}</span>
                        </ValidatorButton>
                    </BusyOverlay>
                    {/* <Button type="button" color="danger" className="btn-icon">
                        <i className="ri-arrow-go-back-line"></i>
                    </Button> */}
                </div>
            </Col>
            <Col className="col-auto ms-auto">
                <div className="hstack gap-2">
                    <BusyOverlay busy={loading.save} size="sm">
                        <ValidatorButton validation={transportsValidation} disabled={!shipmentInfo?.totalCost} type="button" color="primary" className="btn-label" onClick={transportsValidation.submitForm}>
                            <i className="ri-save-line label-icon"></i>
                            <span>{t("Save Changes")}</span>
                        </ValidatorButton>
                    </BusyOverlay>
                </div>
            </Col>
        </Row>
        <Dialog ref={deleteShipmentConfirmDialog} color="danger" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={t('The shipment will be deleted')} title={`Are you sure?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    removeFbaShipmentTransport({
                        fbaShipmentTransportId: processingPackage?.transport?.fbaShipmentTransportId!
                    })(dispatch).then(() => {
                        hide();
                        setProcessingPackage(undefined);
                        loadFbaShipment({
                            fbaShipmentId: fbaShipment!.fbaShipmentId
                        })(dispatch);
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default CreateShipmentForm;