import classNames from "classnames";
import { CloseButton, CloseButtonProps } from "reactstrap";

const ModalCloseButton = (props: CloseButtonProps) => {
    const { className, style, ...rest } = props;
    return <>
        <CloseButton style={{
            ...style,
            "transform": "translate(120%, 0)"
        }} className={classNames(className, "bg-white end-0 p-2 position-absolute top-0 opacity-100")} {...rest} />
    </>;
};

export default ModalCloseButton;