import { useProfile } from "Components/Hooks/ProfileHooks";
import { UserContract } from "api/types/contracts/users";
import { Permission } from "helpers/permissions";
import { PropsWithChildren, useMemo } from "react";
import { json } from "react-router-dom";

type OwnedResource = {
    owner?: UserContract
};

type RestrictedProps = PropsWithChildren & {
    require: Permission | Permission[],
    requireAll?: boolean,
    create?: boolean,
    read?: boolean,
    write?: boolean,
    delete?: boolean,
    fallback?: () => JSX.Element,
    throw?: boolean,
    ownership?: OwnedResource
}

const Restricted = (props: RestrictedProps) => {
    const { canCreate, canWrite, canRead, canDelete, hasPermission, userProfile } = useProfile();

    const checkCreate = (r: Permission) => !props.create || canCreate(r);
    const checkRead = (r: Permission) => !props.read || canRead(r);
    const checkWrite = (r: Permission) => !props.write || canWrite(r);
    const checkDelete = (r: Permission) => !props.delete || canDelete(r);
    const check = (r: Permission) => hasPermission(r) && (checkCreate(r) && checkWrite(r) && checkRead(r) && checkDelete(r));
    
    const required = useMemo(() => (Array.isArray(props.require) ? props.require : [props.require]), [props.require]);
    const allowed = (props.requireAll ? required.every(check) : required.some(check)) 
        || (props.ownership !== undefined && props.ownership.owner?.userId === userProfile?.user.userId);
    
    if (!allowed && props.throw) {
        throw json(
            { permission: props.require, create: props.create, read: props.read, write: props.write, delete: props.delete },
            { status: 403 }
        );
    }

    return allowed ? props.children : (typeof props.fallback === "function" ? props.fallback() : props.fallback);
}

export default Restricted;