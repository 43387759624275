import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type WarehouseLoadingStates = LoadingStates<"list" | "save" | "delete" | "selectList" | "status">;

export type WarehouseState = {
    list: Partial<PagedList<WarehouseContract>>,
    selectList: WarehouseContract[],
    loading: WarehouseLoadingStates,
    error?: ApiError
}

export const initialState: WarehouseState = {
    list: {},
    selectList: [],
    loading: {
        list: false,
        save: false,
        delete: false,
        status: false,
        selectList: false
    }
};

const WarehouseSlice = createSlice({
    name: "WarehouseSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<WarehouseContract>>) {
            state.list = action.payload;
        },
        setSelectList(state, action: PayloadAction<WarehouseContract[]>) {
            state.selectList = action.payload;
        },
        loading(state, action: PayloadAction<[keyof WarehouseLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    setSelectList,
    loading,
    apiError
} = WarehouseSlice.actions;

export default WarehouseSlice.reducer;
