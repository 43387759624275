import { FormikValidation, ValidatableComponentProps } from "helpers/types";
import React, { FunctionComponent } from "react";
import { FormFeedback } from "reactstrap";

type ValidationWrapperProps<TChildren, TObject, TField> = {
    validation: FormikValidation<TObject>,
    field: TField | string,
    children: TChildren,
    hideError?: boolean
}

const ValidationWrapper = <T, F extends keyof T, C extends React.ReactElement<CP, FunctionComponent<CP>>, CP extends Required<ValidatableComponentProps<T[F]>>>(props: ValidationWrapperProps<C, T, F>) => {
    //const ValidatedInput = props.children;
    
    const fieldName = props.field as string; 
    const { error, touched, value } = props.validation.getFieldMeta(fieldName);
    const isValid = touched ? !error : undefined;

    const onChanged = (val?: T[F]) => {
        props.children.props.onChange?.(val as any);
        props.validation.setFieldValue(fieldName, val).then(r => {
            props.validation.setFieldTouched(fieldName, true, true);
        });
    }

    const inputProps: CP = {
        ...props.children.props, 
        isValid: isValid,
        name: fieldName,
        value: value,
        onChange: onChanged
    };

    return <>
        {React.cloneElement(props.children, inputProps)}
        {!props.hideError && isValid === false && typeof error === "string" && <FormFeedback type="invalid" tooltip>{error}</FormFeedback>}
    </>;
};

export default ValidationWrapper;