import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isTenant, setIsTenant] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e : any) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul : any = document.getElementById("two-column-menu");
            const iconItems : any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        
        if (iscurrentState !== 'dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'tenant') {
            setIsTenant(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isTenant,
    ]);

    const menuItems : any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboards",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e : any) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('dashboard');
                updateIconSidebar(e);
            },
        }, {
            id: "tenants",
            label: "Tenants",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: setIsTenant,
            click: function (e : any) {
                e.preventDefault();
                setIsTenant(!setIsTenant);
                setIscurrentState('tenants');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "createTenant",
                    label: "Create New",
                    link: "/tenants/create",
                    parentId: "tenants",
                    // badgeColor: "success",
                    // badgeName: "New",
                },
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;