import { APIClient } from "helpers/api_helper";
import { GetFbaShippingQuery, ListFbaShipmentsQuery, ListShipmentTargetsQuery } from "./types/queries";
import { AddFbaShipmentDocumentsCommand, AddFbaShipmentItemsCommand, ClearFbaShipmentDocumentsCommand, CreateFbaShipmentCommand, CreateFbaShipmentInvoiceCommand, CreateFbaShipmentTransportsCommand, DeleteFbaShipmentCommand, DeleteFbaShipmentItemCommand, DeleteFbaShipmentTransportCommand, ProcessFbaShipmentItemCommand, UpdateFbaShipmentCommand, UpdateFbaShipmentItemPrepServiceCommand, UpdateFbaShipmentItemsCommand, UpdateFbaShipmentPackagesCommand } from "./types/commands";
import { FbaShipmentContract, FbaShipmentInvoiceContract, ShipmentTargetContract } from "./types/contracts/shipping";

const api = new APIClient();

export const getFbaShipment = async (data: GetFbaShippingQuery) => {
    const result = await api.get(`/api/fbaShipping/${data.fbaShipmentId}`);
    return result;
}

export const getFbaShipmentsList = async (data: ListFbaShipmentsQuery) => {
    const result = await api.get(`/api/fbaShipping`, data);
    return result;
}

export const postFbaShipment = async (data: CreateFbaShipmentCommand): Promise<FbaShipmentContract | undefined> => {
    const response = await api.post(`/api/fbaShipping`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const patchFbaShipmentItems = async (data: UpdateFbaShipmentItemsCommand) => {
    const result = await api.patch(`/api/fbaShipping/${data.fbaShipmentId}/items`, [...data.items]);
    return result;
}

export const postFbaShipmentItems = async (data: AddFbaShipmentItemsCommand) => {
    const result = await api.post(`/api/fbaShipping/${data.fbaShipmentId}/items`, [...data.items]);
    return result;
}

export const deleteFbaShipmentItem = async (data: DeleteFbaShipmentItemCommand) => {
    const result = await api.delete(`/api/fbaShipping/${data.fbaShipmentId}/items/${data.fbaShipmentItemId}`);
    return result;
}

export const patchFbaShipment = async (data: UpdateFbaShipmentCommand) => {
    const { fbaShipmentId, ...rest} = data;
    const result = await api.patch(`/api/fbaShipping/${fbaShipmentId}`, { ...rest });
    return result;
}

export const putFbaShipmentPackages = async (data: UpdateFbaShipmentPackagesCommand) => {
    const result = await api.put(`/api/fbaShipping/${data.fbaShipmentId}/packages`, [...data.packages]);
    return result;
}

export const processFbaShipmentItem = async (data: ProcessFbaShipmentItemCommand) => {
    const { fbaShipmentId, fbaShipmentItemId, ...processData } = data;

    const result = await api.patch(`/api/fbaShipping/${fbaShipmentId}/items/${fbaShipmentItemId}/process`, processData);
    return result;
}

export const deleteFbaShipping = async (data: DeleteFbaShipmentCommand) => {
    const result = await api.delete(`/api/fbaShipping/${data.fbaShipmentId}`);
    return result;
}

export const postFbaShipmentInvoice = async (data: CreateFbaShipmentInvoiceCommand): Promise<FbaShipmentInvoiceContract | undefined> => {
    const response = await api.post(`/api/fbaShipping/${data.fbaShipmentId}/invoice`, { ...data.invoice });
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putFbaShipmentDocuments = async (data: AddFbaShipmentDocumentsCommand) => {
    const result = await api.put(`/api/fbaShipping/${data.fbaShipmentId}/documents`, [...data.documents]);
    return result;
}

export const deleteFbaShipmentDocuments = async (data: ClearFbaShipmentDocumentsCommand) => {
    const result = await api.delete(`/api/fbaShipping/${data.fbaShipmentId}/documents`);
    return result;
}

export const patchFbaShipmentItemPrepService = async (data: UpdateFbaShipmentItemPrepServiceCommand) => {
    const { fbaShipmentId, fbaShipmentItemId, prepServiceId, ...rest } = data;
    const result = await api.patch(`/api/fbaShipping/${fbaShipmentId}/items/${fbaShipmentItemId}/prepServices/${prepServiceId}`, rest);
    return result;
}

export const getShipmentTargetList = async (data: ListShipmentTargetsQuery): Promise<ShipmentTargetContract[]> => {
    const result = await api.get(`/api/fbaShipping/targets`, data);
    return result;
}

export const postFbaShipmentTransports = async (data: CreateFbaShipmentTransportsCommand) => {
    const result = await api.post(`/api/fbaShipping/${data.fbaShipmentId}/transport`, [...data.fbaShipmentTransports]);
    return result;
}

export const deleteFbaShipmentTransport = async (data: DeleteFbaShipmentTransportCommand) => {
    const result = await api.delete(`/api/fbaShipping/transport/${data.fbaShipmentTransportId}`);
    return result;
}