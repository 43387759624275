import TruncatableText from "Components/Common/TruncatableText";
import ProductIdentifiersDisplay from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Radiobox from "Components/Form/Radiobox";
import { ItemAvatar } from "Components/Shared/ShipmentPackageView";
import { InboundItemContract } from "api/types/contracts/inbound";
import { useId } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";

type ProductCardProps = {
    item: InboundItemContract,
    selectable?: boolean,
    onSelect?: (product: InboundItemContract) => void
};

const ProductCard = ({ item, selectable, onSelect }: ProductCardProps) => {
    const { t } = useTranslation();
    const id = useId();

    return <>
        <Card body className="border p-4">
            {selectable && <label htmlFor={id} className="position-absolute top-0 start-0 p-2 w-100 h-50 cursor-pointer">
                <Radiobox name={id} onChange={() => onSelect?.(item)} />
            </label>}
            <div className="d-flex align-items-start">
                <div className="flex-shrink-0 me-3">
                    <ProductImageDisplay product={item.product} size="lg" radius="rounded" className="mb-2" />
                    <ProductIdentifiersDisplay product={item.product} />
                </div>
                <div className="flex-grow-1">
                    <h6>
                        <TruncatableText maxLines={4}>
                            {item.product.name}
                        </TruncatableText>
                    </h6>
                    <dl className="mb-1 row">
                        {item.product.color && <>
                            <dt className="col-8">{t("Color")}</dt>
                            <dd className="col-4 mb-0 d-flex align-items-center gap-1">
                                <ItemAvatar size={1} color={item.product.color.hexCode} />
                                <span className="text-muted">{item.product.color.name}</span>
                            </dd>
                        </>}
                        {item.product.size && <>
                            <dt className="col-8">{t("Size")}</dt>
                            <dd className="col-4 mb-0">{item.product.size.name}</dd>
                        </>}
                        <hr className="my-1" />
                        <dt className="col-8">{t("Total Expected")}</dt>
                        <dd className="col-4 mb-0">{item.quantity}</dd>
                        <dt className="col-8">{t("Total Received")}</dt>
                        <dd className="col-4 mb-0">{item.received}</dd>
                        <dt className="col-8">{t("Remaining")}</dt>
                        <dd className="col-4 mb-0">{item.quantity - item.received}</dd>
                    </dl>
                </div>
            </div>
        </Card>
    </>;
}

export default ProductCard;