import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Alert } from "reactstrap";

type AlertProps = PropsWithChildren & {
    icon?: string,
    className?: string,
    kind?: "default" | "solid" | "top-border" | "left-border"
}

const GenericAlert = ({children, icon, className, kind, color}: AlertProps & { color: string }) => (
    <Alert color={color} className={classNames(className, kind === "solid" ? `bg-${color}` : "",  { 
        "rounded-label": !kind || kind === "default",
        "alert-label-icon": kind === "solid",
        "alert-top-border": kind === "top-border",
        "alert-border-left": kind === "left-border",
        "alert-solid": kind === "solid",
        "text-white": kind === "solid" && color !== "dark" && color !== "light"
    })}>
        <div className="d-flex align-items-center gap-3">
            <i className={classNames(icon, kind === "top-border" ? `text-${color}` : "", {
                "label-icon": kind === "solid"
            })}></i>
            <div className={classNames({
                
            })}>
                {children}
            </div>
        </div>
    </Alert>)

export const DangerAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="danger" icon={icon ?? "ri-error-warning-line"} {...rest} />
export const WarningAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="warning" icon={icon ?? "ri-alert-line"} {...rest} />
export const InfoAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="info" icon={icon ?? "ri-airplay-line"} {...rest} />
export const SuccessAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="success" icon={icon ?? "ri-checkbox-circle-line"} {...rest} />
export const PrimaryAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="primary" icon={icon ?? "ri-user-smile-line"} {...rest} />
export const SecondaryAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="secondary" icon={icon ?? "ri-spam-line"} {...rest} />
export const LightAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="light" icon={icon ?? "ri-mail-line"} {...rest} />
export const DarkAlert = ({ icon, ...rest }: AlertProps) => <GenericAlert color="dark" icon={icon ?? "ri-refresh-line"} {...rest} />