import { UnitOfCurrency } from "api/types/contracts/common";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";

const useCommon = () => {
    const dispatch = useDispatch();

    const selectProperties = createSelector(
        (state: RootState) => state.Common,
        (common) => ({
            exchangeRates: common.usdExchangeRates
        })
    );
    // Inside your component
    const { exchangeRates } = useSelector(selectProperties);

    const calculateExchange = (amount: number, from: UnitOfCurrency, to: UnitOfCurrency) => {
        if (to === from) {
            return amount;
        }

        return amount / exchangeRates[from] * exchangeRates[to];
    }

    return { calculateExchange };
};

export { useCommon };
