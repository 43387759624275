import { useLocation, useNavigate, useParams } from "react-router-dom";

const useRouter = () => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return { location, navigate, params };
};

export { useRouter };
