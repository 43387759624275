import { APIClient, PagedList } from "helpers/api_helper";
import { GetInboundQuery, ListInboundReceivingsQuery, ListInboundsQuery, SearchInboundsQuery } from "./types/queries";
import { CreateInboundCommand, DeleteInboundCommand, SetInboundCompletedCommand, UpdateInboundCommand } from "./types/commands";
import { InboundContract } from "./types/contracts/inbound";
import { ReceivingEntryContract } from "./types/contracts/receivings";

const api = new APIClient();

export const generateInboundCode = async (): Promise<string> => {
    const result = await api.get(`/api/inbound/code`);
    return result;

}

export const getInbound = async (data: GetInboundQuery): Promise<InboundContract | undefined> => {
    const result = await api.get(`/api/inbound/${data.inboundId}`);
    return result;
}

export const getInboundList = async (data: ListInboundsQuery): Promise<PagedList<InboundContract>> => {
    const result = await api.get(`/api/inbound`, data);
    return result;
}

export const getInboundReceivingsList = async (data: ListInboundReceivingsQuery): Promise<ReceivingEntryContract[]> => {
    const result = await api.get(`/api/inbound/${data.inboundId}/receivings`);
    return result;
}

export const searchInbounds = async (data: SearchInboundsQuery): Promise<InboundContract[]> => {
    const result = await api.get(`/api/inbound/search`, data);
    return result;
}

export const postInbound = async (data: CreateInboundCommand): Promise<void> => {
    await api.post(`/api/inbound`, data);
}

export const putInbound = async (data: UpdateInboundCommand): Promise<void> => {
    await api.put(`/api/inbound/${data.inboundId}`, { ...data.inbound });
}

export const deleteInbound = async (data: DeleteInboundCommand): Promise<void> => {
    await api.delete(`/api/inbound/${data.inboundId}`);
}

export const patchCompleteInbound = async (data: SetInboundCompletedCommand): Promise<void> => {
    await api.patch(`/api/inbound/${data.inboundId}/complete`, {});
}