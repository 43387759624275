import { FbaShipmentItemStatus } from "api/types/contracts/shipping";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const FbaShipmentItemStatusMap: Record<FbaShipmentItemStatus, { className: string }> = {
    "waiting": { className: "bg-warning" },
    "ready": { className: "bg-info" },
    "prepared": { className: "bg-success" }
};

const FbaShipmentItemStatusBadge = (props: StatusBadgeProps<FbaShipmentItemStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`FbaShipmentItemStatus.${props.value}`);
    const { className } = FbaShipmentItemStatusMap[props.value];

    return <Badge className={className}>{statusName}</Badge>
}

export default FbaShipmentItemStatusBadge;