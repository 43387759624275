import { FbaShipmentStatus } from "api/types/contracts/shipping";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const FbaShipmentStatusMap: Record<FbaShipmentStatus, { className: string }> = {
    "productPending": { className: "bg-warning" },
    "fnskuPending": { className: "bg-warning-subtle text-warning" },
    "ready": { className: "bg-success" },
    "processing": { className: "bg-success-subtle text-success" },
    "boxLabelPending": { className: "bg-info" },
    "paymentWaiting": { className: "bg-info-subtle text-info" },
    "paymentCompleted": { className: "bg-danger" },
    "shipped": { className: "bg-primary" },
};

const FbaShipmentStatusBadge = (props: StatusBadgeProps<FbaShipmentStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`FbaShipmentStatus.${props.value}`);
    const { className } = FbaShipmentStatusMap[props.value];

    return <Badge className={className}>{statusName}</Badge>
}

export default FbaShipmentStatusBadge;