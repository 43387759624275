import { useEffect, useState } from "react";
import Step1 from "./_Step1";
import Step2 from "./_Step2";
import { InboundContract } from "api/types/contracts/inbound";
import { ReceivingEntryContract } from "api/types/contracts/receivings";
import { Container } from "reactstrap";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useLoaderData, useLocation } from "react-router-dom";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import { clearGateEntrySelection, selectInboundForGateEntry, selectReceivingForGateEntry } from "slices/receivings/thunk";

type GateEntryPageProps = {

};

const GateEntryPage = (props: GateEntryPageProps) => {
    const loaderData = useLoaderData() as any;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const [step, setStep] = useState(1);
    const location = useLocation();

    setPageTitle(`${t("Gate Entry")} - ${t("Receiving")}`);

    const selectProperties = createSelector(
        (state: RootState) => state.Receiving,
        (receiving) => ({
            loading: receiving.loading,
            inbound: receiving.gateEntry.inbound,
            customer: receiving.gateEntry.customer,
            receiving: receiving.gateEntry.receiving,
            error: receiving.error
        })
    );

    // Inside your component
    const { inbound, receiving } = useSelector(selectProperties);

    useEffect(() => {
        if (!loaderData?.receivingEntry && !loaderData?.inbound) {
            clearGateEntrySelection()(dispatch).then(() => setStep(1));
        }
    }, [location]);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Gate Entry")} parents={[t("Receiving")]} />
                {loaderData?.receivingEntry ? <>
                    <ResolveWrapper promise={loaderData.receivingEntry}>
                        {(resolvedReceivingEntry: ReceivingEntryContract) => {
                            if (receiving?.receivingEntryId !== resolvedReceivingEntry.receivingEntryId) {
                                selectReceivingForGateEntry(resolvedReceivingEntry)(dispatch);
                            }
                            return <Step2 />;
                        }}
                    </ResolveWrapper>
                </> : loaderData?.inbound ? <>
                    <ResolveWrapper promise={loaderData.inbound}>
                        {(resolvedInbound: InboundContract) => {
                            if (inbound?.inboundId !== resolvedInbound.inboundId) {
                                selectInboundForGateEntry(resolvedInbound)(dispatch);
                            }
                            return <Step2 />;
                        }}
                    </ResolveWrapper>
                </> : <>
                    {step === 1 && <Step1 onComplete={() => setStep(2)} />}
                    {step === 2 && <Step2 />}
                </>}
            </Container>
        </div>
    </>;
};

export default GateEntryPage;