const Activities = () => {
    return <>
        <div>
            <div className="mb-3 border-bottom pb-2">
                <h5 className="card-title">User Logs</h5>
            </div>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-smartphone-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>iPhone 12 Pro</h6>
                    <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                        2:47PM</p>
                </div>
            </div>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-tablet-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>Apple iPad Pro</h6>
                    <p className="text-muted mb-0">Washington, United States - November 06
                        at 10:43AM</p>
                </div>
            </div>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-smartphone-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>Galaxy S21 Ultra 5G</h6>
                    <p className="text-muted mb-0">Conneticut, United States - June 12 at
                        3:24PM</p>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-macbook-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>Dell Inspiron 14</h6>
                    <p className="text-muted mb-0">Phoenix, United States - July 26 at
                        8:10AM</p>
                </div>
            </div>
        </div>
    </>;
}

export default Activities;