import { FbaShipmentInvoiceStatus } from "api/types/contracts/shipping";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const FbaShipmentInvoiceStatusMap: Record<FbaShipmentInvoiceStatus, { className: string }> = {
    "pending": { className: "bg-danger" },
    "refund": { className: "bg-info" },
    "paid": { className: "bg-success" },
    "cancelled": { className: "bg-warning" }
};

const FbaShipmentInvoiceStatusBadge = (props: StatusBadgeProps<FbaShipmentInvoiceStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`FbaShipmentInvoiceStatus.${props.value}`);
    const { className } = FbaShipmentInvoiceStatusMap[props.value];

    return <Badge className={className}>{statusName}</Badge>
}

export default FbaShipmentInvoiceStatusBadge;