import { AnyAction, Dispatch } from "redux";
import { setList, loading, apiError, setSelectList } from "./reducer";
import { deleteCustomer, getCustomer, getCustomerList, getCustomerListForSelect, putCustomer } from "api/customer";
import { GetCustomerQuery, ListCustomersQuery, ListCustomersQueryForSelect } from "api/types/queries";
import { DeleteCustomerCommand, UpdateCustomerCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";

export const loadCustomer = (params: GetCustomerQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await getCustomer(params);
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const loadList = (params: ListCustomersQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getCustomerList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const loadSelectList = (params: ListCustomersQueryForSelect) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["selectList", true]));

        var result = await getCustomerListForSelect(params);
        
        dispatch(setSelectList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["selectList", false]));
    }
};

export const resetList = () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setList({
        items: [],
        currentPage: 1,
        totalCount: 0
    }));
}

export const updateCustomer = (params: UpdateCustomerCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putCustomer(params);
        toast.success(i18n.t("Customer updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeCustomer = (params: DeleteCustomerCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteCustomer(params);
        toast.success(i18n.t("Customer deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultipleCustomers = (params: DeleteCustomerCommand[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteCustomer(item);
        }

        toast.success(i18n.t("Customer deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}