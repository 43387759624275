import { ReceivingEntryStatus } from "api/types/contracts/receivings";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const FbaShipmentInvoiceStatusMap: Record<ReceivingEntryStatus, { color: string, className: string }> = {
    "draft": { color: "light", className: "text-body" },
    "completed": { color: "success", className: "" },
    "grn": { color: "info", className: "" }
};

const ReceivingEntryStatusBadge = (props: StatusBadgeProps<ReceivingEntryStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`ReceivingEntryStatus.${props.value}`);
    const { color, className } = FbaShipmentInvoiceStatusMap[props.value];

    return <Badge color={color} className={className}>{statusName}</Badge>
}

export default ReceivingEntryStatusBadge;