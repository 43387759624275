import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyContract, TenantInfo } from "api/types/contracts/companies";
import { CustomerContract } from "api/types/contracts/customers";

export type StorageAuthorizationMode = "none" | "cookie" | "queryString" | "header";

type StorageAuthorization = {
    mode: StorageAuthorizationMode,
    signatureValues: Record<string, string>,
    expiresAt: Date | undefined,
    ready: boolean,
    loading: boolean
}

export type TenantState = {
    tenant: TenantInfo | undefined,
    company: CompanyContract | undefined,
    customer: CustomerContract | undefined,
    storageAuthorization: StorageAuthorization,
    loadingState: "notloaded" | "loading" | "loaded" | "failed",
    customerLoading: boolean,
    error: string | undefined
};

export const initialState: TenantState = {
    loadingState: "notloaded",
    tenant: undefined,
    company: undefined,
    customer: undefined,
    error: undefined,
    customerLoading: false,
    storageAuthorization: {
        mode: "queryString",
        signatureValues: {},
        expiresAt: undefined,
        ready: false,
        loading: false
    }
};

const TenantSlice = createSlice({
    name: "TenantSlice",
    initialState,
    reducers: {
        initialize(state) {
            state.loadingState = "notloaded";
        },
        setSignatureValues(state, action: PayloadAction<[StorageAuthorizationMode, Record<string, string>] | undefined>) {
            state.storageAuthorization.loading = false;
            if (action.payload) {
                state.storageAuthorization.ready = true;
                state.storageAuthorization.mode = action.payload[0];
                state.storageAuthorization.signatureValues = action.payload[1];
            }
            else {
                state.storageAuthorization.ready = false;
                state.storageAuthorization.signatureValues = {};
            }
        },
        setStorageAuthorizationLoading(state) {
            state.storageAuthorization.loading = true;
            state.storageAuthorization.ready = false;
        },
        setTenant(state, action: PayloadAction<TenantInfo>) {
            state.loadingState = "loaded";
            state.tenant = action.payload;
            state.error = undefined;
        },
        setCompany(state, action: PayloadAction<CompanyContract | undefined>) {
            state.company = action.payload;
        },
        setCustomer(state, action: PayloadAction<CustomerContract | undefined>) {
            state.customer = action.payload;
            state.customerLoading = false;
        },
        setCustomerLoading(state) {
            state.customerLoading = true;
        },
        setLoading(state, action: PayloadAction<"loading">) {
            state.loadingState = action.payload;
        },
        setError(state, action: PayloadAction<string | undefined>) {
            state.error = action.payload;
            state.loadingState = "failed";
        }
    },
});

export const { 
    initialize,
    setTenant, 
    setLoading, 
    setCompany, 
    setError, 
    setCustomer, 
    setCustomerLoading, 
    setStorageAuthorizationLoading, 
    setSignatureValues 
} = TenantSlice.actions;

export default TenantSlice.reducer;
