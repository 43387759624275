import { useReactTable, getCoreRowModel, getSortedRowModel, getFilteredRowModel, getExpandedRowModel, getPaginationRowModel, flexRender, ColumnDef, PaginationState, RowSelectionState } from "@tanstack/react-table";
import { Table as ReactTable } from "@tanstack/table-core";
import { Table, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, Label, UncontrolledTooltip, Spinner } from "reactstrap";
import React, { ForwardedRef, RefAttributes, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Checkbox from "Components/Form/Checkbox";
import classNames from "classnames";
import _ from "lodash";

export const selectRowColumn = <T,>(): ColumnDef<T, any> => ({
    id: '#',
    enableHiding: false,
    header: (cell) => <Checkbox onChange={cell.table.toggleAllRowsSelected} indeterminate={cell.table.getIsSomeRowsSelected()} value={cell.table.getIsAllRowsSelected()} />,
    cell: (cell) => {
        return <Checkbox value={cell.row.getIsSelected()} onChange={cell.row.toggleSelected} />;
    }
});
export type TableContainerProps<D> = {
    className?: string;
    columns: ColumnDef<D, any>[];
    data: D[];
    totalDataLength?: number,
    tableClass?: string,
    theadClass?: string,
    trClass?: string,
    thClass?: string,
    divClass?: string,
    busy?: boolean,
    nowrap?: boolean,
    pagination?: PaginationState,
    hiddenColumns?: string[],
    onPaginationChanged?: (paginationState: PaginationState) => void,
    onSelectionChanged?: (selectedRows: D[]) => void
}

export type TableContainerRef = {
    resetSelection: VoidFunction
}

const TablePagination = <D,>(props: {
    table: ReactTable<D>,
    totalDataLength: number,
    pagination: PaginationState,
    rowsCount: number,
    showColumnSelect?: boolean
}) => {
    const pagingOptions = [5, 10, 25, 50, 100].map(i => ({
        value: i,
        label: `Show ${i}`
    }));
    const { getCanNextPage, getCanPreviousPage, getPageOptions, setPageIndex, nextPage, previousPage, setPageSize, getAllLeafColumns } = props.table;
    const { t } = useTranslation();

    const selectedPagingOption = pagingOptions.find(p => p.value === props.pagination.pageSize);

    return (<>
        <div className="col-sm">
            <div className="text-muted">Showing<span className="fw-semibold ms-1">{props.rowsCount}</span> of <span className="fw-semibold">{props.totalDataLength}</span> Results
            </div>
        </div>
        <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                <li className={!getCanPreviousPage() ? "page-item disabled" : "page-item"}>
                    <a href="#" className="page-link" onClick={previousPage}>{t("Previous")}</a>
                </li>
                {getPageOptions().map(item => <li className="page-item" key={item}>
                    <a href="#" className={props.pagination.pageIndex === item ? "page-link active" : "page-link"} onClick={() => setPageIndex(item)}>{item + 1}</a>
                </li>)}
                <li className={!getCanNextPage() ? "page-item disabled" : "page-item"}>
                    <a href="#" className="page-link" onClick={nextPage}>{t("Next")}</a>
                </li>
            </ul>
        </div>
        <div className="col-sm-auto">
            <Select value={selectedPagingOption} onChange={opt => setPageSize(opt?.value || 10)} options={pagingOptions}></Select>
        </div>
        {props.showColumnSelect ?
            <div className="col-sm-auto ps-0">
                <UncontrolledTooltip placement="top" target="columnSelectToggleButton">{t("Select Columns")}</UncontrolledTooltip>
                <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="btn btn-link p-0" id="columnSelectToggleButton">
                        <i className="mdi mdi-table-headers-eye fs-2"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md p-2">
                        <div>
                            {getAllLeafColumns().map(column => (
                                <div className="d-flex justify-content-start" key={column.id}>
                                    <div className="p-1">
                                        <Checkbox disabled={!column.getCanHide()} id={column.id} value={column.getIsVisible()}
                                            onChange={column.toggleVisibility} />
                                    </div>
                                    <div className="p-1">
                                        <Label htmlFor={column.id}>{
                                            (typeof column.columnDef.header) === "string" ?
                                                column.columnDef.header?.toString() :
                                                column.columnDef.id}</Label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            : null}
    </>);
}
const TableContainer = <D,>(props: TableContainerProps<D>, ref: ForwardedRef<TableContainerRef>) => {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: props.pagination?.pageIndex || 0,
        pageSize: props.pagination?.pageSize || 10,
    });
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    
    const table = useReactTable({
        columns: props.columns,
        data: props.data,
        initialState: {
            columnVisibility: _.fromPairs(props.hiddenColumns?.map(c => [c, false]) || []),
            pagination
        },
        state: {
            pagination,
            rowSelection
        },
        pageCount: Math.max(1, Math.ceil((props.totalDataLength || props.data.length) / pagination.pageSize)),
        onPaginationChange: (updater) => {
            if (typeof updater === "function") {
                var val = updater(pagination);
                setPagination(val);
                props.onPaginationChanged?.(val);
            }
            else {
                setPagination(updater);
                props.onPaginationChanged?.(updater);
            }
        },
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: (updater) => {
            if (typeof updater === "function") {
                var val = updater(rowSelection);
                setRowSelection(val);
                props.onSelectionChanged?.(props.data.filter((item, i) => val[i]));
            }
            else {
                setRowSelection(updater);
                props.onSelectionChanged?.(props.data.filter((item, i) => updater[i]));
            }
        },
        manualPagination: !!props.pagination,
        enableRowSelection: true
    });

    const { t } = useTranslation();

    useImperativeHandle(ref, () => {
        return {
            resetSelection: () => {
                table.setRowSelection(prev => ({}));
            }
        };
    }, [table]);

    return <>
        <Col>
            <Row className="align-items-center mt-2 mb-4 text-center text-sm-start">
                <TablePagination table={table} totalDataLength={props.totalDataLength || props.data.length}
                    rowsCount={props.data.length} pagination={pagination} showColumnSelect></TablePagination>
            </Row>
            <Row>
                <Col>
                    <div className={classNames("table-responsive table-card", props.divClass)}>
                        <Table hover className={classNames("", props.tableClass, { "table-nowrap": props.nowrap })}>
                            <thead className={classNames("table-tenant-primary table-nowrap", props.theadClass)}>
                                {table.getHeaderGroups().map(({ id, headers }) => (
                                    <tr className={classNames(props.trClass, "align-middle")} key={id}>
                                        {headers.map((header) => (
                                            <th key={header.id} className={classNames(props.thClass)} onClick={header.column.getToggleSortingHandler()} role={header.column.getCanSort() ? "button" : ""}>
                                                <div className="d-flex justify-content-between">
                                                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                                                    <div className="ml-1 text-dark fw-bold">{{
                                                        asc: <i className="bx bx-sort-up"></i>,
                                                        desc: <i className="bx bx-sort-down"></i>,
                                                    }[header.column.getIsSorted() as string]}
                                                    </div>
                                                </div>
                                                {/* <Filter column={column} /> */}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {props.busy ?
                                    <tr>
                                        <td colSpan={table.getVisibleLeafColumns().length} className="text-center">
                                            <Spinner className='text-tenant-primary' />
                                        </td>
                                    </tr> 
                                : 
                                props.data.length === 0 ?
                                    <tr>
                                        <td colSpan={table.getVisibleLeafColumns().length} className="text-center text-warning-emphasis">
                                            {t("No records available")}
                                        </td>
                                    </tr>
                                :
                                    table.getRowModel().rows.map(row => (
                                        <React.Fragment key={row.id}>
                                            <tr>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <td key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </React.Fragment>)
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center text-center text-sm-start">
                <TablePagination table={table} totalDataLength={props.totalDataLength || props.data.length}
                    rowsCount={props.data.length} pagination={pagination}></TablePagination>
            </Row>
        </Col>
    </>;
};

// little type assertion for typed component
export default forwardRef(TableContainer) as <D>(
    props: TableContainerProps<D> & RefAttributes<TableContainerRef>
) => ReturnType<typeof TableContainer>;