import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";

const useMetadata = () => {
    const selectProperties = createSelector(
        (state: RootState) => state,
        (state) => ({
            tenant: state.Tenant.tenant
        })
    );
    // Inside your component
    const { tenant } = useSelector(selectProperties);
    

    const setPageTitle = (title: string) => {
        document.title = tenant?.name ? `${title} - ${tenant.name}` : title;
    };

    return { setPageTitle };
};

export { useMetadata };
