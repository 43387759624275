import { APIClient } from "helpers/api_helper";
import { CreateBinLocationCommand, UpdateBinLocationCommand, DeleteBinLocationCommand } from "./types/commands";
import { GetBinLocationByLabelQuery } from "./types/queries";
import { BinLocationContract } from "./types/contracts/locations";

const api = new APIClient();

export const postBinLocation = async (data: CreateBinLocationCommand): Promise<void> => {
    await api.post(`/api/binlocation`, data);
}

export const putBinLocation = async (data: UpdateBinLocationCommand): Promise<void> => {
    await api.put(`/api/binlocation/${data.binLocationId}`, { ...data.binLocation });
}

export const deleteBinLocation = async (data: DeleteBinLocationCommand): Promise<void> => {
    await api.delete(`/api/binlocation/${data.binLocationId}`);
}

export const getBinLocationByLabel = async (data: GetBinLocationByLabelQuery): Promise<BinLocationContract | undefined> => {
    return await api.get(`/api/binlocation/label`, { ...data });
}