import SelectInput from "Components/Form/SelectInput";
import { ShippingPurpose } from "api/types/contracts/inbound";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ShippingPurposeSelect = (props: EnumSelectInput<ShippingPurpose>) => {
    const { t } = useTranslation();

    const purposeOptions = useMemo(() => ["storage", "fba", "dropship"].map<SelectOption<ShippingPurpose>>(p => ({
        value: p as ShippingPurpose,
        label: t(`enums:ShippingPurpose.${p}`)
    })), [t]);
    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<ShippingPurpose> isMulti value={value} onChange={onChange} {...rest} options={purposeOptions} placeholder={t("Shipping Purpose")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={purposeOptions} placeholder={t("Shipping Purpose")} />}
    </>;
};

export default ShippingPurposeSelect;