import { WarningAlert } from "Components/Alerts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import Grid from "./_Grid";
import BusyOverlay from "Components/Common/BusyOverlay";
import List from "../BinLocations/_List";

type AislesViewProps = {
    
}

const AislesView = (props: AislesViewProps) => {
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => ({
            loading: location.loading,
            selectedArea: location.areas?.find(a => a.areaId === location.selectedAreaId)
        })
    );

    const { selectedArea, loading } = useSelector(selectProperties);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedArea && (selectedArea.aisles?.length !== 0 || selectedArea.binLocations?.length !== 0) ? <>
                {selectedArea.aisles && <Grid aisles={selectedArea.aisles} />}
                {selectedArea.binLocations && <>
                    <h5 className="my-3">{t("Bin Locations")}</h5>
                    <List binLocations={selectedArea.binLocations} />
                </>}
            </>

            :
                <WarningAlert>{t("There are no defined aisles/bin locations yet")}</WarningAlert>
            }
        </BusyOverlay>
    </>;
}

export default AislesView;