import SelectInput from "Components/Form/SelectInput";
import { UserStatus } from "api/types/contracts/users";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const userStatusOptions: UserStatus[] = ["active", "pending", "passive", "suspend"];

const UserStatusSelect = (props: EnumSelectInput<UserStatus>) => {
    const { t } = useTranslation();

    const purposeOptions = useMemo(() => userStatusOptions.map<SelectOption<UserStatus>>(p => ({
        value: p,
        label: t(`enums:UserStatus.${p}`)
    })), [t]);
    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<UserStatus> isMulti value={value} onChange={onChange} {...rest} options={purposeOptions} placeholder={t("User Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={purposeOptions} placeholder={t("User Status")} />}
    </>;
};

export default UserStatusSelect;