import Loader from "Components/Common/Loader";
import CountryDisplay from "Components/Displays/CountryDisplay";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { FbaShipmentContract } from "api/types/contracts/shipping";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";

type TopInfoProps = {
    
}

const TopInfo = (props: TopInfoProps) => {
    const { t } = useTranslation();
    
    const selectProperties = createSelector(
        (state: RootState) => state.FbaShipping,
        (state) => ({
            fbaShipment: state.processingItem,
        })
    );
    
    // Inside your component
    const { fbaShipment } = useSelector(selectProperties);

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    return <Card body>
        <dl className="row align-items-center mb-0 g-2">
            <dt className="col-2">{t("ID")}</dt>
            <dd className="col-2 mb-0"># {fbaShipment.shipmentCode}</dd>
            <dt className="col-2">{t("Status")}</dt>
            <dd className="col-2 mb-0"><FbaShipmentStatusBadge value={fbaShipment.status} /></dd>
            <dt className="col-2">{t("Country")}</dt>
            <dd className="col-2 mb-0"><CountryDisplay country={fbaShipment.shipmentTarget.address.country} /></dd>
            <dt className="col-2">{t("Customer")}</dt>
            <dd className="col-2 mb-0"><CustomerDisplay customer={fbaShipment.customer} /></dd>
            <dt className="col-2">{t("W/T Products")}</dt>
            <dd className="col-2 mb-0">{_.sumBy(fbaShipment.items, "count")}/{0}</dd>
            <dt className="col-2">{t("Expected Total Price")}</dt>
            <dd className="col-2 mb-0"><Currency value={fbaShipment.actualCost.total} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></dd>
        </dl>
    </Card>;
}

export default TopInfo;