import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, UncontrolledButtonDropdown } from "reactstrap";

type SearchBoxProps<TMode extends string = string> = {
    value: string,
    onChange: (val: string, mode?: TMode) => void,
    placeholder?: string,
    modes?: { key: TMode, label: string }[],
    className?: string
}

const SearchBox = <T extends string,>(props: SearchBoxProps<T>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>(props.value);
    const [searchMode, setSearchMode] = useState<T>();

    return <div className={classNames("search-box d-inline-block w-100", props.className)}>
        <InputGroup>
            <Input 
                type="text" 
                value={value} onChange={e => setValue(prev => {
                    props.onChange(e.target.value, searchMode);
                    return e.target.value;
                })} 
                className="bg-light border-light search" placeholder={props.placeholder} />
            {props.modes && <UncontrolledButtonDropdown>
                <DropdownToggle caret color="light">
                    {searchMode ? props.modes.find(m => m.key === searchMode)?.label : t("All")}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => setSearchMode(prev => {
                        if (value) {
                            props.onChange(value);
                        }
                        return undefined;
                    })}>
                        {t("All")}
                    </DropdownItem>
                    {props.modes.map(mode => <DropdownItem onClick={() => setSearchMode(prev => {
                        if (value) {
                            props.onChange(value, mode.key);
                        }
                        return mode.key;
                    })}>
                        {(mode.label)}
                    </DropdownItem>)}
                </DropdownMenu>
            </UncontrolledButtonDropdown>}
        </InputGroup>
        <i className="bx bx-search-alt search-icon"></i>
    </div>;
}

export default SearchBox;