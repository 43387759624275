import SelectInput from "Components/Form/SelectInput";
import { ShipmentDutyType } from "api/types/contracts/shipping";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const statusOptions: SelectOption<ShipmentDutyType>[] = [{
    value: "sender",
    label: "Sender"
}, {
    value: "receiver",
    label: "Receiver"
}];

const ShipmentDutyTypeSelect = (props: EnumSelectInput<ShipmentDutyType>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<ShipmentDutyType> isMulti value={value} onChange={onChange} {...rest} options={statusOptions} placeholder={t("Shipment Duty Type")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={statusOptions} placeholder={t("Shipment Duty Type")} />}
    </>;
};

export default ShipmentDutyTypeSelect;