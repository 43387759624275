import { WarehouseType } from "api/types/contracts/warehouses";
import { useTranslation } from "react-i18next";

type WarehouseTypeDisplayProps = {
    value: WarehouseType
}

const WarehouseTypeDisplay = ({ value }: WarehouseTypeDisplayProps) => {
    const { t } = useTranslation();

    switch (value) {
        case "normal":
            return <span className="badge text-uppercase bg-success-subtle text-success">{t("Normal")}</span>;
        case "thirdPartyLogistics":
            return <span className="badge text-uppercase bg-danger-subtle text-danger">{t("3PL")}</span>;
        default:
            return undefined;
    }
}

export default WarehouseTypeDisplay;