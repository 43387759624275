import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "slices";
import { loginSuccess } from "./reducer";
import { getCurrentCustomer } from "api/customer";
import { setCustomer } from "slices/tenant/reducer";

const loginListener = createListenerMiddleware<RootState>();

loginListener.startListening({
    actionCreator: loginSuccess,
    effect: async (action, listener) => {
        if (action.payload.user.userType === "customer") {
            var customer = await getCurrentCustomer();

            listener.dispatch(setCustomer(customer));
        }
        else {
            listener.dispatch(setCustomer(undefined));
        }
    }
});

export default loginListener;