import { APIClient, PagedList } from "helpers/api_helper";
import { GetCustomerQuery, ListCustomersQuery, ListCustomersQueryForSelect } from "./types/queries";
import { CustomerContract } from "./types/contracts/customers";
import { DeleteCustomerCommand, RegisterCustomerCommand, UpdateCustomerCommand } from "./types/commands";

const api = new APIClient();

export const getCustomer = async (data: GetCustomerQuery): Promise<CustomerContract | undefined> => {
    const result = await api.get(`/api/customer/${data.customerId}`);
    return result;
}

export const getCurrentCustomer = async (): Promise<CustomerContract | undefined> => {
    const result = await api.get(`/api/customer/current`);
    return result;
}

export const getCustomerList = async (data: ListCustomersQuery): Promise<PagedList<CustomerContract>> => {
    const result = await api.get(`/api/customer`, data);
    return result;
}

export const getCustomerListForSelect = async (data: ListCustomersQueryForSelect): Promise<CustomerContract[]> => {
    const result = await api.get(`/api/customer/select`, data);
    return result;
}

export const postCustomerRegister = async (data: RegisterCustomerCommand): Promise<CustomerContract | undefined> => {
    const response = await api.post(`/api/customer/register`, data);

    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putCustomer = async (data: UpdateCustomerCommand): Promise<void> => {
    await api.put(`/api/customer/${data.customerId}`, { ...data.customer });
}

export const deleteCustomer = async (data: DeleteCustomerCommand): Promise<void> => {
    await api.delete(`/api/customer/${data.customerId}`);
}