import classNames from "classnames"
import { PropsWithChildren, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Tooltip } from "reactstrap"

type ClipboardCopyProps = PropsWithChildren & {
    text: string,
    className?: string
}

const ClipboardCopy = ({ text, className, children }: ClipboardCopyProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<Tooltip>(null);
    const { t } = useTranslation();
    const [tooltipShown, setTooltipShown] = useState(false);

    const onCopyClick = () => {
        navigator.clipboard.writeText(text);
        setTooltipShown(true);
        setTimeout(() => setTooltipShown(false), 500);
    }

    return <>
        {children ? <>
            <Button innerRef={buttonRef} type="button" onClick={onCopyClick} color="sm" className={classNames("", className)}>
                {children}
            </Button>
        </> : <Button innerRef={buttonRef} type="button" onClick={onCopyClick} color="ghost-dark" className={classNames("btn-icon", className)} size="sm">
            <i className="ri-file-copy-line fs-16"></i>
        </Button>}
        <Tooltip target={buttonRef} ref={tooltipRef} placement="right" isOpen={tooltipShown}>
            {t("Copied!")}
        </Tooltip>
    </>
}

export default ClipboardCopy;