import SelectInput from "Components/Form/SelectInput"
import { ProductCategoryContract } from "api/types/contracts/products";
import { ListProductCategoriesQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadList } from "slices/product/category/thunk";
import { useImmer } from "use-immer";

type ProductCategorySelectProps = EntitySelectInputProps<ProductCategoryContract, "categoryId"> & {
    parentsOnly?: boolean,
    userId?: string
}

const ProductCategorySelect = ({ isMulti, value, onChange, onSelect, name, isValid, parentsOnly, userId, ...rest }: ProductCategorySelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListProductCategoriesQuery>({
        page: 1,
        pageSize: 99,
        asHierarchical: true,
        search: "",
        userId
    });

    const selectProperties = createSelector(
        (state: RootState) => state.ProductCategory,
        (productCategoryState) => ({
            categories: productCategoryState.list?.items || [],
            loading: productCategoryState.loading.list
        })
    );

    const { categories, loading } = useSelector(selectProperties);
    const productCategoryList = useMemo(() => parentsOnly ? categories.map(s => ({ 
        label: s.name, 
        value: s.categoryId 
    })) : categories.flatMap(c => [{
        label: <strong>{c.name}</strong>,
        value: c.categoryId
    }, ...(c.subCategories?.map(s => ({ 
        label: ` ▪ ${s.name}`, 
        value: s.categoryId 
    })) ?? []  )]), [parentsOnly, categories]);

    const flatCategories = useMemo(() => parentsOnly ? categories : categories.flatMap(c => c.subCategories || []), [parentsOnly, categories]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = flatCategories.filter(c => val.includes(c.categoryId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = flatCategories.find(c => c.categoryId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.categoryId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadList(query)(dispatch);
    }, [dispatch, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => loadList(query)(dispatch)
        };
    }, [dispatch, query]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        updateQuery(q => {
            q.parentsOnly = parentsOnly;
        });
    }, [parentsOnly, updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (categories.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flatCategories]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Product Category"),
        busy: loading,
        options: productCategoryList,
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default forwardRef(ProductCategorySelect) as (
    props: ProductCategorySelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof ProductCategorySelect>;