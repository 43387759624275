import SelectInput from "Components/Form/SelectInput";
import { CarrierRateSource } from "api/types/contracts/carrier";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const statusOptions: SelectOption<CarrierRateSource>[] = [{
    value: "api",
    label: <span><i className="ri-settings-4-line me-1"></i> API Rates</span>
}, {
    value: "lookupTable",
    label: <span><i className="ri-file-text-line me-1"></i> Lookup Rates</span>
}];

const CarrierRateSourceSelect = (props: EnumSelectInput<CarrierRateSource>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<CarrierRateSource> isMulti value={value} onChange={onChange} {...rest} options={statusOptions} placeholder={t("Rate Source Type")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={statusOptions} placeholder={t("Rate Source Type")} />}
    </>;
};

export default CarrierRateSourceSelect;