import { ColumnDef } from "@tanstack/react-table";
import { CarrierOverweightRuleContract } from "api/types/contracts/carrier";
import { ListCarrierOverweightRulesQuery } from "api/types/queries";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer, { selectRowColumn, TableContainerRef } from "Components/Common/TableContainer";
import DateDisplay from "Components/Displays/DateDisplay";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { forwardRef, Ref, RefAttributes, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadOverweightRules, removeOverweightRule } from "slices/carrierRules/thunk";
import { useImmer } from "use-immer";

export type OverweightCarrierRuleListRef = {
    reload: VoidFunction
}

const OverweightCarrierRuleList = (props: {}, ref: Ref<OverweightCarrierRuleListRef>) => {
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<CarrierOverweightRuleContract[]>([]);
    const rule = useMemo(() => selectedItems[0], [selectedItems]);
    
    const [query, updateQuery] = useImmer<ListCarrierOverweightRulesQuery>({
        page: 1,
        pageSize: 10
    })

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const selectProperties = createSelector(
        (state: RootState) => state.CarrierRules,
        (carrierRules) => ({
            overweightRules: carrierRules.overweight,
            loading: carrierRules.loading.overweight,
        })
    );

    setPageTitle(`${t("Overweight Rule List")} - ${t("Carrier Rules")} - ${t("Management")}`);
    
    // Inside your component
    const { overweightRules, loading } = useSelector(selectProperties);
    const debouncedLoadList = useDebounce(() => {
        loadOverweightRules(query)(dispatch).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                debouncedLoadList();
            }
        };
    }, [debouncedLoadList]);

    const deleteRule = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            let success = false;
            for (const item of selectedItems) {
                success &&= await removeOverweightRule({
                    carrierOverweightRuleId: item.carrierOverweightRuleId
                })(dispatch);
            }

            return success;
        }
        else if (deleteMode === "single" && rule) {
            return await removeOverweightRule({
                carrierOverweightRuleId: rule.carrierOverweightRuleId
            })(dispatch);
        }

        return false;
    }, [rule, deleteMode, selectedItems, dispatch]);

    const handleDeleteClick = (arg: CarrierOverweightRuleContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
    };

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode]);

    // Column
    const columns = useMemo<ColumnDef<CarrierOverweightRuleContract, any>[]>(() => [
        selectRowColumn<CarrierOverweightRuleContract>(),
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Link to={`/carrier-rules/overweight-rules/edit/${cell.row.original.carrierOverweightRuleId}`} className="btn btn-ghost-dark px-1 py-0">
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                        <Button color="ghost-danger" className="px-1 py-0"
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </div>
                </>;
            },
        },
        {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/carrier-rules/overweight-rules/edit/${cell.row.original.carrierOverweightRuleId}`} className="fw-medium link-secondary">{cell.row.original.name}</Link>;
            },
        },
        {
            header: t("CREATE DATE"),
            accessorFn: item => item.createdAt,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Carrier Overweight Rule List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Restricted require="management.carrier" write>
                                        <Link to="/carrier-rules/overweight-rules/new" className="btn btn-info add-btn">
                                            {t("Create New Overweight Rule")}
                                        </Link>
                                    </Restricted>
                                    {selectedItems.length > 0 && deleteMode !== "single" && <>
                                        <Restricted require="management.carrier" delete>
                                            <button className="btn btn-soft-danger" onClick={() => handleMultiDeleteClick()}><i
                                                className="ri-delete-bin-2-line"></i></button>
                                        </Restricted>
                                    </>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Col sm={6} lg={4} className="">
                                    <SearchBox value={query.search || ""} placeholder="Search name"
                                        onChange={val => updateQuery(q => {
                                            q.search = val;
                                        })}></SearchBox>
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={loading.list}
                                columns={columns}
                                nowrap
                                data={(overweightRules?.items || [])}
                                totalDataLength={overweightRules?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                onSelectionChanged={selection => {
                                    setSelectedItems(selection);
                                }}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
        message={`Do you want to continue?`} title={t(selectedItems.length === 1 ? "Deleting rule '{{name}}'" : "Deleting {{count}} rule", { name: rule?.name, count: deleteMode === "multi" ? selectedItems.length : 1 })}
        onButtonClick={(button, hide) => {
            if (button === "yes") {
                deleteRule().then(success => {
                    if (success) {
                        debouncedLoadList();
                    }
                }).finally(() => {
                    setDeleteMode(undefined);
                    setSelectedItems([]);
                });
            }
            else {
                setDeleteMode(undefined);
            }
        }} />}
    </>;
}

export default forwardRef(OverweightCarrierRuleList) as (
    props: RefAttributes<OverweightCarrierRuleListRef>
) => ReturnType<typeof OverweightCarrierRuleList>;