import { FormikValidation } from "helpers/types";
import { FormFeedback } from "reactstrap";
import ColorPicker, { ColorPickerProps } from "../ColorPicker";

export type ValidatedColorPickerProps<T> = Omit<ColorPickerProps, "name"> & {
    validation: FormikValidation<T>,
    field: keyof T
}

const ValidatedColorPicker = <T,>(props: ValidatedColorPickerProps<T>) =>  {
    const fieldName = props.field.toString(); 
    const { error, touched, value } = props.validation.getFieldMeta(fieldName);

    const setValue = (color: string | undefined) => {
        props.validation.setFieldValue(fieldName, color);
    };

    return (<>
        <div className={props.className}>
            <ColorPicker name={fieldName} onChange={setValue} value={value} />
            {touched && error ? (
                <FormFeedback type="invalid" tooltip>
                    <div>{error}</div>
                </FormFeedback>
            ) : null}
        </div>
    </>)
}

export default ValidatedColorPicker;