import { UpdateUserAvatarCommand, UpdateUserStatusCommand } from "api/types/commands";
import { patchUpdateUserAvatar, patchUpdateUserStatus } from "api/user";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { toast } from "react-toastify";
import i18n from "i18n";

export const updateAvatar = (params: UpdateUserAvatarCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await patchUpdateUserAvatar(params);

        toast.success(i18n.t("User avatar updated"));
        
        return result;
    } catch (error) {
        toast.error(i18n.t("User avatar update failed"));

        throw error;
    }
};

export const updateStatus = (params: UpdateUserStatusCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await patchUpdateUserStatus(params);

        toast.success(i18n.t("User status updated"));
        
        return result;
    } catch (error) {
        toast.error(i18n.t("User status update failed"));

        throw error;
    }
};