import { apiError, loading, setPutawayBinHistory, setPutawayItemHistory } from "./reducer";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { ApiError } from "helpers/types";
import { AssignPutawayBinCommand, AssignPutawayItemCommand, UndoLocationCommand } from "api/types/commands";
import { listPutawayBinHistory, listPutawayItemHistory, postPutawayBin, postPutawayItem } from "api/inventory";
import { GetBinByLabelQuery, GetBinLocationByLabelQuery } from "api/types/queries";
import { getBinByLabel } from "api/bin";
import { getBinLocationByLabel } from "api/binLocation";
import { toast } from "react-toastify";
import i18n from "i18n";
import { deleteLocationHistory } from "api/location";
import { trimLabel } from "helpers/string";

export const getLocationByLabel = ({ label, warehouseId }: GetBinByLabelQuery | GetBinLocationByLabelQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["location", true]));

        const trimmedLabel = trimLabel(label);

        if (trimmedLabel.startsWith("BIN-")) {
            return await getBinByLabel({ label: trimmedLabel, warehouseId });
        }
        else {
            return await getBinLocationByLabel({ label: trimmedLabel, warehouseId });
        }
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["location", false]));
    }
}

export const assignPutawayBin = ({ binOrBoxLabel, ...params }: AssignPutawayBinCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["putaway", true]));

        const result = await postPutawayBin({
            ...params,
            binOrBoxLabel: trimLabel(binOrBoxLabel)
        });

        toast.success(i18n.t("Putaway successful! Item has been placed."));

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["putaway", false]));
    }
}

export const assignPutawayItem = ({ productLabel, ...params }: AssignPutawayItemCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["putaway", true]));

        const result = await postPutawayItem({
            ...params,
            productLabel: trimLabel(productLabel)
        });

        toast.success(i18n.t("Putaway successful! Item has been placed."));

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["putaway", false]));
    }
}

export const undoLocationHistory = (params: UndoLocationCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["historyUndo", true]));

        await deleteLocationHistory(params);

        toast.success(i18n.t("Item placement has been undone"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["historyUndo", false]));
    }
}

export const loadPutawayBinHistory = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        const result = await listPutawayBinHistory();

        dispatch(setPutawayBinHistory(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
}

export const loadPutawayItemHistory = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        const result = await listPutawayItemHistory();

        dispatch(setPutawayItemHistory(result));
    }
    catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
}