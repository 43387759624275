import { useEffect, useRef } from "react";
import { Container, Modal, ModalBody } from "reactstrap";

//import images 
import { useTranslation } from "react-i18next";

import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import List, { WarehouseListRef } from "./_List";
import { useLoaderData, useNavigate } from "react-router-dom";
import WizardForm from "./_WizardForm";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import { useMetadata } from "Components/Hooks/MetadataHooks";

type WarehousesPageProps = {
    edit?: boolean
}

const WarehousesPage = (props: WarehousesPageProps) => {
    const loaderData = useLoaderData() as any;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<WarehouseListRef>(null);

    return <Restricted require="management.warehouse" fallback={() => <Unauthorized />}>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Warehouses")} parents={[t("Management")]} />
                <List ref={listRef} />
                {props.edit && 
                    <Modal size="xl" isOpen toggle={() => navigate("/warehouses")} unmountOnClose>
                        <ModalBody>
                            <ResolveWrapper promise={loaderData?.warehouse}>
                                {(resolvedWarehouse) => <>
                                    <ModalCloseButton onClick={() => navigate("/warehouses")} />
                                    <WizardForm warehouse={resolvedWarehouse} onSuccess={() => {
                                        navigate("/warehouses");
                                        listRef.current?.reload();
                                    }} />
                                </>}
                            </ResolveWrapper>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </div>
    </Restricted>;
};

export default WarehousesPage;
