import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { Container } from "reactstrap";
import EditForm from "./_EditForm";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import Restricted from "Components/Common/Restricted";
import { InboundContract } from "api/types/contracts/inbound";

const InboundEditPage = () => {
    const loaderData = useLoaderData() as any;
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    if (loaderData?.inbound) {
        setPageTitle(`${t("Inbound Update")} - ${t("Inbounds")}`);
    }
    else {
        setPageTitle(`${t("New Inbound")} - ${t("Inbounds")}`);
    }

    return <>
        <div className="page-content">
            <Container fluid>
                {loaderData?.inbound ? <>
                    <ResolveWrapper promise={loaderData?.inbound}>
                        {(inbound: InboundContract) => <Restricted require="receiving.inbound" ownership={inbound} write throw>
                            
                            <TitleBreadcrumb active={`Edit #${inbound.inboundCode}`} parents={[t("Receiving"), { title: t("Inbounds"), to: "/inbounds" }]} />
                            <EditForm inbound={inbound} />
                        </Restricted>}
                    </ResolveWrapper>
                </>
                : <Restricted require="receiving.inbound" create throw>
                    <TitleBreadcrumb active={t("New")} parents={[t("Receiving"), { title: t("Inbounds"), to: "/inbounds" }]} />
                    <EditForm />
                </Restricted>}
            </Container>
        </div>
    </>;
}

export default InboundEditPage;