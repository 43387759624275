import classNames from "classnames";

export const hover = (className: string, hoverClassName: string) => {

    return {
        className: className,
        onMouseEnter: (e: React.MouseEvent) => {
            e.currentTarget.className = classNames(e.currentTarget.className, hoverClassName);
        },
        onMouseLeave: (e: React.MouseEvent) => {
            e.currentTarget.className = className;
        }
    }
}