import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { ProductBrandContract, ProductBrandForm } from "api/types/contracts/products";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Form, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { createProductBrand, updateProductBrand } from "slices/product/brand/thunk";
import * as Yup from "yup";

type FormProps = {
    productBrand?: ProductBrandContract,
    onSuccess: (productBrandId: string) => void
}

const defaultForm: Partial<ProductBrandForm> = {
    
};

const createProductBrandForm = (productBrand: ProductBrandContract): ProductBrandForm => {
    return {
        name: productBrand.name,
    };
};

const BrandForm = (props: FormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const product = props.productBrand ? createProductBrandForm(props.productBrand) : defaultForm;

    const selectProperties = createSelector(
        (state: RootState) => state.ProductBrand,
        (product) => ({
            error: product.error,
            loading: product.loading
        })
    );
    const { loading, error } = useSelector(selectProperties); 

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: product,
        validationSchema: Yup.object<ProductBrandForm, TypedShape<ProductBrandForm>>({
            name: Yup.string().required(t("Name is required"))
        }),
        onSubmit: (values) => {
            const productBrand = values as ProductBrandForm;
            const productBrandId = props.productBrand?.brandId;
            
            if (productBrandId) {
                updateProductBrand({
                    productBrandId: productBrandId,
                    productBrand
                })(dispatch).then(success => success && props.onSuccess?.(productBrandId));
            }
            else {
                createProductBrand({
                    productBrand
                })(dispatch).then(created => created && props.onSuccess?.(created.brandId));
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <Container>
                <Row>
                    <Col className="g-3">
                        <RequiredLabel>{t("Brand Name")}</RequiredLabel>
                        <ValidatedInput validation={validation} field="name" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto" className="g-3 ms-auto">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton validation={validation} color="primary" type="submit">
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </Col>
                </Row>
            </Container>
        </Form>
    </>;
}

export default BrandForm;