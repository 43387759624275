import { AreaContract } from "api/types/contracts/locations";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Modal, ModalBody } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import AreaFormView from "./_AreaFormView";
import { reloadLocations, removeArea, selectArea } from "slices/location/thunk";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "Components/Common/ModalCloseButton";

type AreaViewProps = {
    area: AreaContract
}

const AreaItem = (props: AreaViewProps) => {
    const dialogRef = useRef<DialogRef>(null);
    const areaCardDivRef = useRef<HTMLElement>(null);
    const [editModal, setEditModal] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => ({
            ...location
        })
    );

    const { warehouse, loading } = useSelector(selectProperties);

    const reload = () => reloadLocations({ warehouseId: warehouse!.warehouseId })(dispatch);

    const deleteArea = async () => {
        await removeArea({ areaId: props.area.areaId })(dispatch);
        await reload();
        dialogRef.current?.hide();
    };

    const onAreaUpdated = () => { 
        setEditModal(false);
        reload();
    };

    useEffect(() => {
        areaCardDivRef.current?.style.setProperty("--area-color", props.area.settings.color || "white");
    }, [areaCardDivRef, props.area])

    return <>
        <Card innerRef={areaCardDivRef} className="area-item shadow">
            <CardBody>
                <div className="position-relative w-100 h-100">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="area-name d-flex flex-column gap-1 text-center">
                            <h5 className="mb-0 p-1 rounded">{props.area.name}</h5>
                            {props.area.settings.notes && <small className="mx-auto px-1 rounded text-muted">{props.area.settings.notes}</small>}
                        </div>
                    </div>
                    <div className="position-absolute top-0 start-0 gap-2 d-flex area-attributes">
                        {props.area.settings.isClimateControl && <span className="fs-5 px-1 rounded-circle"><i className="las la-snowflake"></i></span>}
                        {props.area.settings.isHazardousArea && <span className="fs-5 px-1 rounded-circle"><i className="las la-skull-crossbones"></i></span>}
                        {props.area.settings.isFBAPrep && <span className="fs-5 px-1 rounded-circle"><i className="lab la-amazon"></i></span>}
                    </div>
                    <div className="auto-hide-actions">
                        <div className="position-absolute top-0 end-0 d-flex">
                            <Button color="ghost-primary" size="sm" className="btn-icon" onClick={() => setEditModal(true)} type="button">
                                <i className=" ri-edit-2-line"></i>
                            </Button>
                            <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => dialogRef.current?.show()} type="button">
                                <i className="ri-delete-bin-line"></i>
                            </Button>
                        </div>
                    </div>
                    <Button color="ghost-dark" size="sm" className="position-absolute bottom-0 end-0 btn-icon" type="button" onClick={() => selectArea(props.area)(dispatch)}>
                        <i className="ri-arrow-right-down-line"></i>
                    </Button>
                </div>
            </CardBody>
        </Card>
        <Modal isOpen={editModal} toggle={() => setEditModal(prev => !prev)} size="lg" unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setEditModal(false)} />
                <AreaFormView warehouseId={warehouse!.warehouseId} area={props.area} onSuccess={onAreaUpdated} />
            </ModalBody>
        </Modal>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting \"Area {{areaName}}\"", { areaName: props.area.name })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteArea();
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default AreaItem;