import { deleteCarrierAccount, deleteCarrierAccountService, getCarrierAccountList, getCarrierAccountServiceList, getCarrierList, getCarrierPackageTypeList, postCarrierAccount, postCreateShipment, postShipmentRateQuery, putCarrierAccount, putCarrierAccountService } from "api/carrier";
import { GetShipmentRateQuery, ListCarrierAccountServicesQuery, ListCarrierAccountsQuery, ListCarrierPackageTypesQuery, ListCarriersQuery } from "api/types/queries";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { apiError, loading, setCarrierAccountServiceCharge, setCarrierAccountServiceLoading, setCarrierAccountServices, setCarrierAccounts, setCarriers, setPackageTypes } from "./reducer";
import { ApiError } from "helpers/types";
import { CreateCarrierAccountCommand, CreateShipmentCommand, DeleteCarrierAccountCommand, DeleteCarrierAccountServiceCommand, UpdateCarrierAccountCommand, UpdateCarrierAccountServiceCommand } from "api/types/commands";
import { toast } from "react-toastify";
import i18n from "i18n";

export const loadCarriers = (params: ListCarriersQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true ]));

        var result = await getCarrierList(params);
        
        dispatch(setCarriers(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false ]));
    }
};

export const loadCarrierAccounts = (params: ListCarrierAccountsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["listAccounts", true ]));

        var result = await getCarrierAccountList(params);
        
        dispatch(setCarrierAccounts(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["listAccounts", false ]));
    }
}

export const loadCarrierAccountServices = (params: ListCarrierAccountServicesQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true ]));

        var result = await getCarrierAccountServiceList(params);

        dispatch(setCarrierAccountServices(result));
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false ]));
    }
}

export const getCarrierAccountServiceCost = (params: GetShipmentRateQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(setCarrierAccountServiceLoading([params.carrierAccountServiceId, true]));

        var result = await postShipmentRateQuery(params);

        dispatch(setCarrierAccountServiceCharge([params.carrierAccountServiceId, result ]));

        return result;
    } catch (error) {
        console.error(error);
        
    }
    finally {
        dispatch(setCarrierAccountServiceLoading([params.carrierAccountServiceId, false]));
    }
}

export const createCarrierAccount = (params: CreateCarrierAccountCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true ]));

        await postCarrierAccount(params);

        toast.success(i18n.t("Carrier account created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false ]));
    }
}

export const updateCarrierAccount = (params: UpdateCarrierAccountCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true ]));

        await putCarrierAccount(params);

        toast.success(i18n.t("Carrier account updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false ]));
    }
}

export const removeCarrierAccount = (params: DeleteCarrierAccountCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true ]));

        await deleteCarrierAccount(params);

        toast.success(i18n.t("Carrier account deleted"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["delete", false ]));
    }
}

export const updateCarrierAccountService = (params: UpdateCarrierAccountServiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true ]));

        await putCarrierAccountService(params);

        toast.success(i18n.t("Carrier service updated"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false ]));
    }
}

export const removeCarrierAccountService = (params: DeleteCarrierAccountServiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true ]));

        await deleteCarrierAccountService(params);

        toast.success(i18n.t("Carrier service deleted"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["delete", false ]));
    }
}

export const loadCarrierPackageTypes = (params: ListCarrierPackageTypesQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["listPackageTypes", true ]));

        var result = await getCarrierPackageTypeList(params);

        dispatch(setPackageTypes(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["listPackageTypes", false ]));
    }
}

export const createShipment = (params: CreateShipmentCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["createShipment", true ]));

        var result = await postCreateShipment(params);

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["createShipment", false ]));
    }
}