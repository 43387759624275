import config from "config"

export const withCdn = (path: string) => isFullUrl(path) ? path : `${config.cdn.FILES_URL}/${path}`;
export const isCdnUrl = (path: string) => path.startsWith(config.cdn.FILES_URL);
export const isFullUrl = (path: string) => path.startsWith("http://") || path.startsWith("https://");
export const downloadUrl = (path: string) => {
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = path;
	a.download = 'YOUR_FILENAME';
	document.body.appendChild(a);
	a.click();
	setTimeout(function() {
		document.body.removeChild(a); 
	}, 0); 
}

/*
const a = (strs: TemplateStringsArray, ...values: any[]) => {
	console.log(strs, values);
	return "";
}
const b = a`Hello ${"world"}`;
*/