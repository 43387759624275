import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer";

export const userForgetPassword = (user: any) => async (dispatch: any) => {
    // try {
    //     let response;
    //     if (process.env.DEFAULTAUTH === "jwt") {
    //     //response = postJwtForgetPwd(user.email);
    //     } else {
    //     //response = postFakeForgetPwd(user.email);
    //     }

    //     const data = await response;

    //     if (data) {
    //     dispatch(
    //         userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //         )
    //     );
    //     }
    // } catch (forgetError) {
    //     dispatch(userForgetPasswordError(forgetError));
    // }
};

