import { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
// import logoSm from "../assets/images/tyloc/logo-sm.png";
// import logoDark from "../assets/images/tyloc/logo.png";
import logoLight from "../assets/images/tyloc/logo.png";

//Import Components
import VerticalLayout from "./VerticalLayout";
import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
import HorizontalLayout from "./HorizontalLayout";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useTenant } from "Components/Hooks/TenantHooks";
import { withCdn } from "helpers/urlHelper";

type SidebarProps = {

}

const Sidebar = (props: SidebarProps) => {
    const { tenant } = useTenant();

    const selectLayoutProperties = createSelector(
        (state: any) => state.Layout,
        (layout) => ({
            layoutType: layout.layoutType
        })
    );
    // Inside your component
    const { layoutType } = useSelector(selectLayoutProperties);

    useEffect(() => {
        var verticalOverlay = document.getElementsByClassName("vertical-overlay");
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener("click", function () {
                document.body.classList.remove("vertical-sidebar-enable");
            });
        }
    });

    const onSmHoverMenu = () => {
        // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        } else {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        }
    };

    const tenantLogo = tenant?.logo ? withCdn(tenant.logo) : logoLight;

    return (
        <>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={tenantLogo} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={tenantLogo} alt="" className="mw-100" style={{ maxHeight: "100px" }} />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={tenantLogo} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={tenantLogo} alt="" className="mw-100" style={{ maxHeight: "100px" }} />
                        </span>
                    </Link>
                    <button
                        onClick={onSmHoverMenu}
                        type="button"
                        className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover"
                    >
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                {layoutType === "horizontal" ? (
                    <div id="scrollbar">
                        <Container fluid>
                            <div id="two-column-menu"></div>
                            <ul className="navbar-nav" id="navbar-nav">
                                <HorizontalLayout />
                            </ul>
                        </Container>
                    </div>
                ) : layoutType === 'twocolumn' ? (
                    <>
                        <TwoColumnLayout />
                        <div className="sidebar-background"></div>
                    </>
                ) : (
                    <>
                        <SimpleBar id="scrollbar" className="h-100">
                            <Container fluid>
                                <div id="two-column-menu"></div>
                                <ul className="navbar-nav" id="navbar-nav" style={{ marginBottom: "10rem" }}>
                                    <VerticalLayout />
                                </ul>
                            </Container>
                        </SimpleBar>
                        <div className="sidebar-background"></div>
                    </>
                )}
            </div>
            <div className="vertical-overlay"></div>
        </>
    );
};

export default Sidebar;
