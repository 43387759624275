const API_URL = process.env.REACT_APP_API_URL;
if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined');
}

const FILES_URL = process.env.REACT_APP_FILES_URL;
if (!FILES_URL) {
    throw new Error('REACT_APP_FILES_URL is not defined');
}

const config = {
    env: {
        isDevelopment: process.env.NODE_ENV === "development",
        isProduction: process.env.NODE_ENV === "production",
        isTest: process.env.NODE_ENV === "test"
    },
    api: {
        API_URL
    },
    cdn: {
        FILES_URL
    },
};

export default config;
