import { deleteArea, getAreaList, postArea, putArea } from "api/area";
import { CreateAisleCommand, CreateAreaCommand, CreateBayCommand, CreateBinLocationCommand, CreateShelfCommand, DeleteAisleCommand, DeleteAreaCommand, DeleteBayCommand, DeleteBinLocationCommand, DeleteShelfCommand, UpdateAisleCommand, UpdateAreaCommand, UpdateBayCommand, UpdateBinLocationCommand, UpdateShelfCommand } from "api/types/commands";
import { GetWarehouseQuery, ListAreasQuery } from "api/types/queries";
import { setAreas, loading, apiError, LocationViewModes, setView, setWarehouse, setSelectedArea, setSelectedBay, setSelectedAisle, setSelectedShelf, reset } from "./reducer";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { ApiError } from "helpers/types";
import { getWarehouse } from "api/warehouse";
import { toast } from "react-toastify";
import i18n from "i18n";
import { AisleContract, AreaContract, BayContract, ShelfContract } from "api/types/contracts/locations";
import { deleteAisle, postAisle, putAisle } from "api/aisle";
import { deleteBay, postBay, putBay } from "api/bay";
import { deleteShelf, postShelf, putShelf } from "api/shelf";
import { deleteBinLocation, postBinLocation, putBinLocation } from "api/binLocation";

export const resetData = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(reset());
}

export const changeView = (view: LocationViewModes) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setView(view));
}

export const selectArea = (area?: AreaContract) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setView("aisle"));
    dispatch(setSelectedShelf(undefined));
    dispatch(setSelectedBay(undefined));
    dispatch(setSelectedAisle(undefined));
    dispatch(setSelectedArea(area));
}

export const selectAisle = (aisle?: AisleContract) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setView("bay"));
    dispatch(setSelectedShelf(undefined));
    dispatch(setSelectedBay(undefined));
    dispatch(setSelectedAisle(aisle));
}

export const selectBay = (bay?: BayContract, aisle?: AisleContract) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setView("shelf"));
    dispatch(setSelectedShelf(undefined));
    dispatch(setSelectedAisle(aisle));
    dispatch(setSelectedBay(bay));
}

export const selectShelf = (shelf?: ShelfContract, bay?: BayContract) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setView("bin"));
    dispatch(setSelectedBay(bay));
    dispatch(setSelectedShelf(shelf));
}

export const loadWarehouse = (params: GetWarehouseQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["init", true]));
        dispatch(reset());
        var warehouse = await getWarehouse(params);
        
        if (!warehouse) {
            throw new Response("Not Found", { status: 404 });
        }

        var areas = await getAreaList(params);

        dispatch(setAreas(areas));
        dispatch(setWarehouse(warehouse));
        dispatch(setView("area"));
    } 
    finally {
        dispatch(loading(["init", false]));
    }
}

export const reloadLocations = (params: ListAreasQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["data", true]));
        var areas = await getAreaList(params);

        dispatch(setAreas(areas));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["data", false]));
    }
};

export const createArea = (params: CreateAreaCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await postArea(params);
        
        toast.success(i18n.t("New area added"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
};

export const updateArea = (params: UpdateAreaCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await putArea(params);

        toast.success(i18n.t("Area updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
};

export const removeArea = (params: DeleteAreaCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));
        await deleteArea(params);

        toast.success(i18n.t("Area deleted"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
};

export const createAisle = (params: CreateAisleCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await postAisle(params);
        
        toast.success(i18n.t("Aisle added", { count: params.aisleCount}));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
};

export const updateAisle = (params: UpdateAisleCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await putAisle(params);
        
        toast.success(i18n.t("Aisle updated"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
};

export const removeAisle = (params: DeleteAisleCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));
        await deleteAisle(params);
        
        toast.success(i18n.t("Aisle deleted"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
};

export const createBay = (params: CreateBayCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await postBay(params);
        
        toast.success(i18n.t("Bay added", { count: params.bayCount}));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateBay = (params: UpdateBayCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await putBay(params);
        
        toast.success(i18n.t("Bay updated"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeBay = (params: DeleteBayCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));
        await deleteBay(params);
        
        toast.success(i18n.t("Bay deleted"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const createShelf = (params: CreateShelfCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await postShelf(params);
        
        toast.success(i18n.t("Shelf added", { count: params.shelfCount}));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateShelf = (params: UpdateShelfCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await putShelf(params);
        
        toast.success(i18n.t("Shelf updated"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeShelf = (params: DeleteShelfCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));
        await deleteShelf(params);
        
        toast.success(i18n.t("Shelf deleted"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const createBinLocation = (params: CreateBinLocationCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await postBinLocation(params);
        
        toast.success(i18n.t("Bin location added", { count: params.count}));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateBinLocation = (params: UpdateBinLocationCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await putBinLocation(params);
        
        toast.success(i18n.t("Bin location updated"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeBinLocation = (params: DeleteBinLocationCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));
        await deleteBinLocation(params);
        
        toast.success(i18n.t("Bin location deleted"));
        
        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}