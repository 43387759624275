import { AnyAction, Dispatch } from "redux";
import { setList, loading, apiError, setProcessingItem } from "./reducer";
import { GetInboundQuery, ListInboundsQuery, SearchInboundsQuery } from "api/types/queries";
import { CreateInboundCommand, DeleteInboundCommand, QuickAddProductsCommand, SetInboundCompletedCommand, UpdateInboundCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";
import { deleteInbound, generateInboundCode, getInbound, getInboundList, patchCompleteInbound, postInbound, putInbound, searchInbounds } from "api/inbound";
import { postQuickAddProduct } from "api/product";

export const loadList = (params: ListInboundsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getInboundList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const searchList = (params: SearchInboundsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await searchInbounds(params);

        return result;
    } catch (error) {
        const apiError = error as ApiError;
    }
    finally {
        
    }
}

export const resetList = () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setList({
        items: [],
        currentPage: 1,
        totalCount: 0
    }));
}

export const createInbound = (params: CreateInboundCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await postInbound(params);
        toast.success(i18n.t("Inbound created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateInbound = (params: UpdateInboundCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putInbound(params);
        toast.success(i18n.t("Inbound updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeInbound = (params: DeleteInboundCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteInbound(params);
        toast.success(i18n.t("Inbound deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultipleInbounds = (params: DeleteInboundCommand[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteInbound(item);
        }

        toast.success(i18n.t("Inbound deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const newInboundCode = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await generateInboundCode();

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
}

export const completeInbound = (params: SetInboundCompletedCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await patchCompleteInbound(params);
        toast.success(i18n.t("Updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const loadInbound = (params: GetInboundQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["load", true]));

        var result = await getInbound(params);
        
        dispatch(setProcessingItem(result));

        dispatch(loading(["load", false]));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
}

export const quickAddProducts = (params: QuickAddProductsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["quickAdd", true]));

        const result = await postQuickAddProduct(params);

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return [];
    }
    finally {
        dispatch(loading(["quickAdd", false]));
    }
}