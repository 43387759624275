import SelectInput from "Components/Form/SelectInput";
import { BinType } from "api/types/contracts/bin";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const typeOptions: SelectOption<BinType>[] = [{
    label: "Normal",
    value: "normal"
}, {
    label: "PutAway",
    value: "putAway",
}, {
    label: "PigeonHole",
    value: "pigeonHole",
}, {
    label: "Stackable",
    value: "stackable",
}, {
    label: "Drawer",
    value: "drawer",
}, {
    label: "Hanging",
    value: "hanging",
}, {
    label: "Tilt",
    value: "tilt",
}];

const BinTypeSelect = (props: EnumSelectInput<BinType>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<BinType> isMulti value={value} onChange={onChange} {...rest} options={typeOptions} placeholder={t("Bin Type")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={typeOptions} placeholder={t("Bin Type")} />}
    </>;
};

export default BinTypeSelect;