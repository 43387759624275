import { ColumnDef } from "@tanstack/react-table";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer from "Components/Common/TableContainer";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import DateRangeInput from "Components/Form/DateRangeInput";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { FbaShipmentContract, FbaShipmentStatus } from "api/types/contracts/shipping";
import { ListFbaShipmentsQuery } from "api/types/queries";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadList, removeFbaShipment } from "slices/shipping/fbaShipping/thunk";
import { useImmer } from "use-immer";
import StatusPills from "./_StatusPills";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CountryDisplay from "Components/Displays/CountryDisplay";

const ListFbaShipmentsPage = () => {
    const [query, updateQuery] = useImmer<ListFbaShipmentsQuery>({
        page: 1,
        pageSize: 10
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [deleteMode, setDeleteMode] = useState<"single">();
    const { setPageTitle } = useMetadata();
    const dialogRef = useRef<DialogRef>(null);
    const [selectedItems, setSelectedItems] = useState<FbaShipmentContract[]>([]);
    const fbaShipment = useMemo(() => selectedItems[0], [selectedItems]);
    const [selectedStatus, setSelectedStatus] = useState<FbaShipmentStatus>();

    setPageTitle(`${t("FBA Shipments List")} - ${t("FBA Prep & Shipping")}`);

    const selectProperties = createSelector(
        (state: RootState) => state.FbaShipping,
        (fbaShipping) => ({
            loading: fbaShipping.loading,
            list: fbaShipping.list,
            error: fbaShipping.error,
        })
    );

    // Inside your component
    const { list, loading } = useSelector(selectProperties);
    const debouncedLoadList = useDebounce(() => {
        loadList(query)(dispatch);
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    const filteredList = useMemo(() => (list?.items || []).filter(i => selectedStatus ? i.status === selectedStatus : true), [list, selectedStatus]);

    const deleteFbaShipment = useCallback(async () => {
        if (deleteMode === "single" && fbaShipment) {
            return await removeFbaShipment({
                fbaShipmentId: fbaShipment.fbaShipmentId
            })(dispatch);
        }

        return false;
    }, [fbaShipment, deleteMode, dispatch]);

    const handleDeleteClick = (arg: FbaShipmentContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode]);

    const columns = useMemo<ColumnDef<FbaShipmentContract, any>[]>(() => [
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Restricted require="shipping.fbashipping" write ownership={cell.row.original} fallback={() => <Button color="ghost-info" className="px-1 py-0" disabled>
                                <i className="ri-eye-fill fs-16"></i>
                            </Button>}>
                            <Link to={`/fba-shipment/${cell.row.original.fbaShipmentId}`} className="btn btn-ghost-info px-1 py-0">
                                <i className="ri-eye-fill fs-16"></i>
                            </Link>
                        </Restricted>
                        {["created", "productPending", "fnskuPending", "ready"].includes(cell.row.original.status) && <>
                            <Restricted require="shipping.fbashipping" delete ownership={cell.row.original} fallback={() => <Button color="ghost-danger" className="px-1 py-0" disabled>
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Button>}>
                                <Link to="#" onClick={() => handleDeleteClick(cell.row.original)} 
                                    className="btn btn-ghost-danger px-1 py-0">
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </Restricted>
                        </>}
                    </div>
                </>;
            },
        },
        {
            header: t("ID"),
            accessorFn: item => item.shipmentCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Restricted require="shipping.fbashipping" write ownership={cell.row.original} fallback={() => <span># {cell.row.original.shipmentCode}</span>}>
                <Link to={`/fba-shipment/${cell.row.original.fbaShipmentId}`} className="fw-medium link-secondary">
                    # {cell.row.original.shipmentCode}
                </Link>
            </Restricted>,
        },
        {
            header: t("CUSTOMER"),
            accessorFn: item => `${item.customer.name} ${item.customer.surname}`,
            enableColumnFilter: false,
            cell: (cell) => <CustomerDisplay customer={cell.row.original.customer} />,
        },
        {
            header: t("CREATE DATE"),
            accessorFn: item => item.createdAt,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />,
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.status,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <FbaShipmentStatusBadge value={cell.getValue()} />,
        },
        {
            header: t("TOTAL PRODUCT"),
            accessorFn: item => _.sumBy(item.items, i => i.count),
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("RESERVED"),
            accessorFn: item => _.sumBy(item.items, i => i.reserved),
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("SKU COUNT"),
            accessorFn: item => item.items.length,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("TOTAL PRICE"),
            accessorFn: item => item.estimatedCost.total,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.getValue()} currency={cell.row.original.warehouse.settings.unitOfCurrency} />
        },
        {
            header: t("TO COUNTRY"),
            accessorFn: item => item.shipmentTarget.address.country.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <CountryDisplay country={cell.row.original.shipmentTarget.address.country} />
        },
    ], [t]);

    const statusCounts: any = useMemo(() => {
        const items = list.items || [];
        return { ..._.chain(items).groupBy("status").mapValues(v => v.length).value(), all: items.length };
    }, [list.items]);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("FBA Shipments List")} parents={[t("FBA Prep & Shipping")]} />
                <Restricted require="shipping.fbashipping" throw>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center gy-3">
                                        <div className="col-sm">
                                            <h5 className="card-title mb-0">{t("FBA Shipments List")}</h5>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div className="d-flex gap-1 flex-wrap">
                                                <Restricted require="shipping.fbashipping" create>
                                                    <Link to="/get-a-quote" className="btn btn-info">
                                                        {t("Get A Quote")}
                                                    </Link>
                                                </Restricted>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <div>
                                        <Row className="mb-2">
                                            <Restricted require="management.company" read>
                                                <Col sm={6} md={4}>
                                                    <CompanySelect value={query.companyId} onSelect={company => updateQuery(q => {
                                                        q.companyId = company?.companyId;
                                                    })} />
                                                </Col>
                                            </Restricted>
                                            <Restricted require="management.customer" read>
                                                <Col sm={6} md={4}>
                                                    <CustomerSelect companyId={query.companyId} onSelect={customer => updateQuery(q => {
                                                        q.customerId = customer?.customerId;
                                                    })} />
                                                </Col>
                                            </Restricted>
                                            <Col sm={6} md={4}>
                                                <DateRangeInput value={query.createDateRange} onChange={val => updateQuery(q => {
                                                    q.createDateRange = val;
                                                })} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xs={12}>
                                                <SearchBox value={query.search || ""} placeholder="Search by Shipment ID (FBA- prefix)"
                                                    onChange={val => updateQuery(q => {
                                                        q.search = val;
                                                    })}></SearchBox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <StatusPills value={selectedStatus} onChange={setSelectedStatus} statusCounts={statusCounts} />
                                            </Col>
                                        </Row>
                                        <TableContainer
                                            busy={loading.list}
                                            columns={columns}
                                            nowrap
                                            data={filteredList}
                                            totalDataLength={list?.totalCount}
                                            pagination={{
                                                pageIndex: query.page - 1,
                                                pageSize: query.pageSize
                                            }}
                                            onPaginationChanged={pagination => updateQuery(q => {
                                                q.page = pagination.pageIndex + 1;
                                                q.pageSize = pagination.pageSize;
                                            })}
                                            divClass="mb-1"
                                            tableClass="align-middle"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
                        message={`Do you want to continue?`} title={`Deleting "${fbaShipment?.shipmentCode}"`}
                        onButtonClick={(button, hide) => {
                            if (button === "yes") {
                                deleteFbaShipment().then(success => {
                                    if (success) {
                                        debouncedLoadList();
                                    }
                                }).finally(() => {
                                    setDeleteMode(undefined);
                                    setSelectedItems([]);
                                });
                            }
                            else {
                                setDeleteMode(undefined);
                            }
                        }} />}
                </Restricted>
            </Container>
        </div>
    </>;
}

export default ListFbaShipmentsPage;