import { useProfile } from "Components/Hooks/ProfileHooks";
import { useTenant } from "Components/Hooks/TenantHooks";
import { ProductContract } from "api/types/contracts/products";
import { stockOf } from "helpers/stockOf";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, UncontrolledPopover, UncontrolledTooltip } from "reactstrap";

type ProductStockDisplayProps = {
    product: ProductContract
}

const ProductStockDisplay = ({ product }: ProductStockDisplayProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const infoRef = useRef<HTMLElement>(null);
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const { company } = useTenant();
    const stock = useMemo(() => stockOf(product, userProfile?.user.warehouse?.warehouseId), [product, userProfile?.user.warehouse?.warehouseId]);
    const totalStock = useMemo(() => stock.available + stock.onHold + stock.reserved + stock.unfulfillable + stock.researching, [stock]);
    const warehouse = useMemo(() => company?.warehouses.find(w => w.warehouseId === stock.warehouseId), [company, stock.warehouseId]);

    return <>
        <Button type="button" color="ghost-dark" innerRef={buttonRef} className="d-flex align-items-center">
            <span>{totalStock}</span>
            <i className="ri-arrow-down-s-line ms-1"></i>
        </Button>
        <UncontrolledPopover target={buttonRef} innerClassName="p-2" trigger="legacy">
            <table className="table table-borderless table-sm mb-0">
                <thead>
                    <tr>
                        <th colSpan={2} className="text-center text-muted">{warehouse?.name}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{t("Available")}</td>
                        <td>{stock.available}</td>
                    </tr>
                    <tr>
                        <td>{t("Inbound")}</td>
                        <td>{stock.inbound}</td>
                    </tr>
                    <tr>
                        <td>{t("On Hold")}</td>
                        <td>{stock.onHold}</td>
                    </tr>
                    <tr>
                        <td>{t("Reserved")}</td>
                        <td>{stock.reserved}</td>
                    </tr>
                    <tr>
                        <td>{t("Unfulfillable")}</td>
                        <td>{stock.unfulfillable}</td>
                    </tr>
                    <tr>
                        <td>{t("Researching")}</td>
                        <td>{stock.researching}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>{t("Total")}</th>
                        <th>{totalStock}</th>
                    </tr>
                </tfoot>
            </table>
        </UncontrolledPopover>
        <UncontrolledTooltip target={infoRef}>
            {t("Shows stock of {{warehouse}}", { warehouse: warehouse?.name })}
        </UncontrolledTooltip>
    </>;
}

export default ProductStockDisplay;