import { AnyAction, Dispatch } from "redux";
import { setList, loading, apiError } from "./reducer";
import { ListPrepServicesQuery } from "api/types/queries";
import { CreatePrepServiceCommand, DeletePrepServiceCommand, UpdatePrepServiceCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deletePrepService, getPrepServiceList, postPrepService, putPrepService } from "api/prepService";

export const loadPrepServiceList = (params: ListPrepServicesQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getPrepServiceList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createPrepService = (params: CreatePrepServiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await postPrepService(params);
        toast.success(i18n.t("Prep service created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updatePrepService = (params: UpdatePrepServiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putPrepService(params);
        toast.success(i18n.t("Prep service updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removePrepService = (params: DeletePrepServiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deletePrepService(params);

        toast.success(i18n.t("Prep service deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiPrepServices = (params: DeletePrepServiceCommand[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deletePrepService(item);
        }

        toast.success(i18n.t("Prep services deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const resetError = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(apiError(undefined));
}
