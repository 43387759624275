import { InboundStatus } from "api/types/contracts/inbound";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";


const InboundStatusBadge = (props: StatusBadgeProps<InboundStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const status = t(`InboundStatus.${props.value}`);

    switch (props.value) {
        case "draft": return <Badge color="dark-subtle" className="text-dark">{status}</Badge>;
        case "confirmed": return <Badge color="secondary">{status}</Badge>;
        case "checkIn": return <Badge color="primary">{status}</Badge>;
        case "receiving": return <Badge color="warning">{status}</Badge>;
        case "completed": return <Badge color="success">{status}</Badge>;
    }
}

export default InboundStatusBadge;