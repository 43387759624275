import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomerContract } from "api/types/contracts/customers";
import { InboundContract, ShippingPurpose } from "api/types/contracts/inbound";
import { UnusableProductReasonContract } from "api/types/contracts/inventory";
import { ReceivingBoxContract, ReceivingEntryContract } from "api/types/contracts/receivings";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type ReceivingLoadingStates = LoadingStates<"load" | "latest" | "list" | "new" | "newLabel" | "scan" | "saveStock" | "save">;

export type ReceivingState = {
    list: Partial<PagedList<ReceivingEntryContract>>,
    processedEntry?: ReceivingEntryContract,
    unusableReasons: UnusableProductReasonContract[],
    selectedBox?: ReceivingBoxContract,
    gateEntry: {
        latest: ReceivingEntryContract[],
        inbound: InboundContract | undefined,
        customer: CustomerContract | undefined,
        receiving: ReceivingEntryContract | undefined,
        warehouse: WarehouseContract | undefined,
        shippingPurpose: ShippingPurpose | undefined,
    },
    loading: ReceivingLoadingStates,
    error?: ApiError
}

export const initialState: ReceivingState = {
    list: {},
    unusableReasons: [],
    gateEntry: {
        latest: [],
        inbound: undefined,
        customer: undefined,
        receiving: undefined,
        warehouse: undefined,
        shippingPurpose: undefined
    },
    loading: {
        load: false,
        list: false,
        latest: false,
        new: false,
        scan: false,
        newLabel: false,
        saveStock: false,
        save: false
    }
};

const ReceivingSlice = createSlice({
    name: "ReceivingSlice",
    initialState,
    reducers: {
        setGateEntryLatest(state, action: PayloadAction<ReceivingEntryContract[]>) {
            state.gateEntry.latest = action.payload;
        },
        setGateEntryInbound(state, action: PayloadAction<InboundContract | undefined>) {
            state.gateEntry.inbound = action.payload;
        },
        setGateEntryCustomer(state, action: PayloadAction<CustomerContract | undefined>) {
            state.gateEntry.customer = action.payload;
        },
        setGateEntryReceiving(state, action: PayloadAction<ReceivingEntryContract | undefined>) {
            state.gateEntry.receiving = action.payload;
        },
        setGateEntryWarehouse(state, action: PayloadAction<WarehouseContract | undefined>) {
            state.gateEntry.warehouse = action.payload;
        },
        setGateEntryShippingPurpose(state, action: PayloadAction<ShippingPurpose | undefined>) {
            state.gateEntry.shippingPurpose = action.payload;
        },
        setList(state, action: PayloadAction<PagedList<ReceivingEntryContract>>) {
            state.list = action.payload;
        },
        setProcessingEntry(state, action: PayloadAction<ReceivingEntryContract>) {
            state.processedEntry = action.payload;
        },
        setSelectedBox(state, action: PayloadAction<ReceivingBoxContract | undefined>) {
            state.selectedBox = action.payload;
        },
        loading(state, action: PayloadAction<[keyof ReceivingLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setGateEntryLatest,
    setGateEntryInbound,
    setGateEntryCustomer,
    setGateEntryReceiving,
    setGateEntryWarehouse,
    setGateEntryShippingPurpose,
    setList,
    setProcessingEntry,
    setSelectedBox,
    loading,
    apiError
} = ReceivingSlice.actions;

export default ReceivingSlice.reducer;
