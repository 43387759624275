import { ListProductSizesQuery } from "api/types/queries";
import { CreateProductSizeCommand, DeleteProductSizeCommand, UpdateProductSizeCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductSizeContract } from "./types/contracts/products";

const api = new APIClient();

export const getProductSizeList = async (data: ListProductSizesQuery): Promise<PagedList<ProductSizeContract>> => {
    const result = await api.get(`/api/productsize`, data);
    return result;
}

export const postProductSize = async (data: CreateProductSizeCommand): Promise<ProductSizeContract | undefined> => {
    const response = await api.post(`/api/productsize`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putProductSize = async (data: UpdateProductSizeCommand): Promise<void> => {
    await api.put(`/api/productsize/${data.productSizeId}`, { ...data.productSize });
}

export const deleteProductSize = async (data: DeleteProductSizeCommand): Promise<void> => {
    await api.delete(`/api/productsize/${data.productSizeId}`);
}
