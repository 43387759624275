import ListSummaryDisplay from "Components/Common/ListSummaryDisplay"
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay"
import CustomerSuitDisplay from "Components/Displays/CustomerSuitDisplay"
import InboundStatusBadge from "Components/Displays/InboundStatusBadge"
import _ from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Button, Card, Col, Progress, Row } from "reactstrap"
import DateDisplay from "Components/Displays/DateDisplay"
import BusyOverlay from "Components/Common/BusyOverlay"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "slices"
import { completeInbound, loadInbound } from "slices/inbound/thunk"

type OverviewProps = {
    onViewContentsClick?: () => void,
}

const Overview = ({ onViewContentsClick }: OverviewProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (inbound) => ({
            error: inbound.error,
            inbound: inbound.processingItem!,
            loading: inbound.loading
        })
    );
    const { loading, inbound, error } = useSelector(selectProperties); 

    const expectedCount = useMemo(() => _.sumBy(inbound.items, item => item.quantity), [inbound.items]);
    const locatedCount = useMemo(() => _.sumBy(inbound.items, item => item.received), [inbound.items]);

    const complete = async () => {
        await completeInbound({ inboundId: inbound.inboundId })(dispatch);
        await loadInbound({ inboundId: inbound.inboundId })(dispatch);
    }

    return <>
        <Card body>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-6">{t("Inbound ID")}</dt>
                        <dd className="col-6 mb-0"># {inbound.inboundCode}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Create Date")}</dt>
                        <dd className="col-6 mb-0"><DateDisplay date={inbound.createdAt} /></dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Last Updated")}</dt>
                        <dd className="col-6 mb-0"><DateDisplay date={inbound.createdAt} /></dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Status")}</dt>
                        <dd className="col-6 mb-0">
                            <div className="vstack gap-1">
                                <InboundStatusBadge value={inbound.inboundStatus} />
                                {inbound.inboundStatus === "receiving" && <BusyOverlay busy={loading.save || loading.load} size="sm">
                                    <Button color="success" size="sm" className="w-100" onClick={() => complete()}>
                                        {t("Set as Completed")}
                                    </Button>
                                </BusyOverlay>}
                            </div>
                        </dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-6">{t("Shipping Purpose")}</dt>
                        <dd className="col-6 mb-0">{t(`enums:ShippingPurpose.${inbound.shippingPurpose}`)}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Supplier", { count: inbound.fromAmazon ? 1 : inbound.suppliers.length })}</dt>
                        <dd className="col-6 mb-0">{inbound.fromAmazon ? "Amazon" : <ListSummaryDisplay items={inbound.suppliers} displayFn={s => s.name} />}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Warehouse")}</dt>
                        <dd className="col-6">{inbound.warehouse.name}</dd>
                        <dd className="text-muted">
                            <InlineAddressDisplay address={inbound.warehouse.address} />
                        </dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-6">{t("SKU Count")}</dt>
                        <dd className="col-6 mb-0">{inbound.items.length}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Units Expected")}</dt>
                        <dd className="col-6 mb-0">{expectedCount}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-6">{t("Units Located")}</dt>
                        <dd className="col-6 mb-0">{locatedCount}</dd>
                    </dl>
                    <dl className="row">
                        <dd className="col-12">
                            <Progress value={locatedCount} max={expectedCount} className="progress-xl border border-dark" color="success" />
                            <Button color="ghost-info mt-2" size="sm" onClick={onViewContentsClick}>{t("View Contents")}</Button>
                        </dd>
                    </dl>
                </Col>
                <Col>
                    <h5 className="text-center">{t("Customer")}</h5>
                    <dl className="row">
                        <dt className="col-5">{t("Suit No")}</dt>
                        <dd className="col-7 mb-0">{inbound.customer?.suitNumber && <CustomerSuitDisplay suit={inbound.customer.suitNumber} />}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-5">{t("Full Name")}</dt>
                        <dd className="col-7 mb-0">{inbound.customer?.name} {inbound.customer?.surname}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-5">{t("Email")}</dt>
                        <dd className="col-7 mb-0">{inbound.customer?.user.email}</dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-5">{t("Phone")}</dt>
                        <dd className="col-7 mb-0">{inbound.customer?.phone}</dd>
                    </dl>
                </Col>
            </Row>
        </Card>
    </>
}

export default Overview;