import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductSupplierContract } from "api/types/contracts/products";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type ProductSupplierLoadingStates = LoadingStates<"list" | "save" | "delete">;

export type ProductSupplierState = {
    list: Partial<PagedList<ProductSupplierContract>>,
    loading: ProductSupplierLoadingStates,
    error?: ApiError
}

export const initialState: ProductSupplierState = {
    list: {},
    loading: {
        list: false,
        save: false,
        delete: false
    }
};

const ProductSupplierSlice = createSlice({
    name: "ProductSupplierSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<ProductSupplierContract>>) {
            state.list = action.payload;
        },
        loading(state, action: PayloadAction<[keyof ProductSupplierLoadingStates, boolean]>) {
            const [key, value] = action.payload;
            state.loading[key] = value;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    loading,
    apiError
} = ProductSupplierSlice.actions;

export default ProductSupplierSlice.reducer;
