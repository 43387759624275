import SelectInput from "Components/Form/SelectInput";
import { CompanyStatus } from "api/types/contracts/companies";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const statusOptions: SelectOption<CompanyStatus>[] = [{
    value: "active",
    label: "Active"
}, {
    value: "passive",
    label: "Passive"
}];

const CompanyStatusSelect = (props: EnumSelectInput<CompanyStatus>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<CompanyStatus> isMulti value={value} onChange={onChange} {...rest} options={statusOptions} placeholder={t("Company Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={statusOptions} placeholder={t("Company Status")} />}
    </>;
};

export default CompanyStatusSelect;