import { useTranslation } from "react-i18next";
import { Col, Form, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TypedShape } from "helpers/types";
import { ReceivingEntryBoxForm } from "api/types/contracts/receivings";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import SelectInput from "Components/Form/SelectInput";
import CarrierSelect from "Components/EntitySelects/CarrierSelect";
import { useEffect, useState } from "react";
import NumberInput from "Components/Form/NumberInput";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { InboundTrackingContract } from "api/types/contracts/inbound";
import TextInput from "Components/Form/TextInput";
import { inputKeyEventHandler } from "helpers/componentHelpers";

type CreateBoxFormProps = {
    onSave: (quantity: number, box: ReceivingEntryBoxForm) => void,
    trackings?: InboundTrackingContract[]
};

const quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(q => ({ label: q.toString(), value: q }));

const defaultFormValues: Partial<ReceivingEntryBoxForm> = {

}

const CreateBoxForm = (props: CreateBoxFormProps) => {
    const { t } = useTranslation();
    const [boxQuantityDisabled, setBoxQuantityDisabled] = useState(false);
    const [boxQuantity, setBoxQuantity] = useState(1);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: defaultFormValues,
        validationSchema: Yup.object<ReceivingEntryBoxForm, TypedShape<ReceivingEntryBoxForm>>({
            trackingNumber: Yup.string().notRequired(),
            carrierId: Yup.string().notRequired(),
            weight: Yup.number().notRequired(),
            length: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            height: Yup.number().notRequired(),

        }),
        onSubmit: (values) => {
            props.onSave(boxQuantity, values as ReceivingEntryBoxForm);
        },
    });

    useEffect(() => {
        if (validation.values.trackingNumber) {
            setBoxQuantity(1);
            setBoxQuantityDisabled(true);

            if (props.trackings) {
                const found = props.trackings.find(t => t.trackingNumber === validation.values.trackingNumber);

                if (found) {
                    validation.setFieldValue("carrierId", found.carrier.carrierId);
                }
            }
        }
        else {
            setBoxQuantityDisabled(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.values.trackingNumber]);

    return <>
        <Form onSubmit={validation.handleSubmit}>
            <Row>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Tracking Number")}</Label>
                    <ValidationWrapper validation={validation} field="trackingNumber">
                        <TextInput placeholder="Tracking Number"  onKeyDown={inputKeyEventHandler("Enter", e => {})} />
                    </ValidationWrapper>
                </Col>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Box Quantity")}</Label>
                    <SelectInput options={quantityOptions} value={boxQuantity} onChange={val => setBoxQuantity(val || 1)} disabled={boxQuantityDisabled} />
                </Col>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Carrier")}</Label>
                    <ValidationWrapper validation={validation} field="carrierId">
                        <CarrierSelect />
                    </ValidationWrapper>
                </Col>
            </Row>
            <Row>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Width")} ({t("Optional")})</Label>
                    <ValidationWrapper validation={validation} field="width">
                        <NumberInput />
                    </ValidationWrapper>
                </Col>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Height")} ({t("Optional")})</Label>
                    <ValidationWrapper validation={validation} field="height">
                        <NumberInput />
                    </ValidationWrapper>
                </Col>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Length")} ({t("Optional")})</Label>
                    <ValidationWrapper validation={validation} field="length">
                        <NumberInput />
                    </ValidationWrapper>
                </Col>
                <Col lg={4} className="g-3">
                    <Label htmlFor="name">{t("Weight")} ({t("Optional")})</Label>
                    <ValidationWrapper validation={validation} field="weight">
                        <NumberInput />
                    </ValidationWrapper>
                </Col>
            </Row>
            <Row>
                <Col xs="auto" className="g-3 ms-auto">
                    <ValidatorButton validation={validation} type="submit" color="primary">
                        {t("Save")}
                    </ValidatorButton>
                </Col>
            </Row>
        </Form>
    </>;
}

export default CreateBoxForm;