import flagus from "../assets/images/flags/us.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  }
}

export default languages
