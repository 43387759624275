import { createSelector } from "reselect";
import Grid from "./_Grid";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import BusyOverlay from "Components/Common/BusyOverlay";

const ShelvesView = () => {

    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => {
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);

            return {
                view: location.view,
                loading: location.loading,
                selectedArea,
                selectedAisle,
                selectedBay
            };
        }
    );

    const { selectedBay, loading } = useSelector(selectProperties);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedBay && <Grid bay={selectedBay} />}
        </BusyOverlay>
    </>;
}

export default ShelvesView;