import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type MetadataState = {
    title: string | undefined,
    breadcrumb: {
        title: string,
        to: string
    }[]
};

export const initialState: MetadataState = {
    title: undefined,
    breadcrumb: []
};

const MetadataSlice = createSlice({
    name: "MetadataSlice",
    initialState,
    reducers: {
        setMetadataAction(state, payload: PayloadAction<[string, MetadataState["breadcrumb"]]>) {
            const [title, breadcrumb] = payload.payload;
            state.title = title;
            state.breadcrumb = breadcrumb;
        }
    }
});

export const {
    setMetadataAction
} = MetadataSlice.actions;

export default MetadataSlice.reducer;
