import BusyOverlay from "Components/Common/BusyOverlay";
import UnusableReasonSelect from "Components/EntitySelects/UnusableProductReasonSelect";
import DateInput from "Components/Form/DateInput";
import NumberInput from "Components/Form/NumberInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { CreateReceivingProductLabelsCommand } from "api/types/commands";
import { UnusableProductReasonContract } from "api/types/contracts/inventory";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Label } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { createReceivingProductLabels } from "slices/receivings/thunk";
import * as Yup from "yup";

type CreateUnusableProductLabelProps = {
    productId: string,
    onCreated?: (unusableReason: UnusableProductReasonContract, labels: string[]) => void,
}

type FormType = {
    unusableProductReasonId: string,
    count: number,
    expireDate?: Yup.Maybe<Date>,
    productId: string,
    receivingBoxId: string,
    receivingEntryId: string
};

const CreateUnusableProductLabel = (props: CreateUnusableProductLabelProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [reason, setReason] = useState<UnusableProductReasonContract>();
    const [busy, setBusy] = useState(false);

    const selectProperties = createSelector(
        (state: RootState) => state.Receiving,
        (receiving) => ({
            processedEntry: receiving.processedEntry!,
            selectedBox: receiving.selectedBox!
        })
    );
    
    const { processedEntry, selectedBox } = useSelector(selectProperties);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            productId: props.productId,
            receivingBoxId: selectedBox.receivingBoxId,
            receivingEntryId: processedEntry.receivingEntryId,
            count: 1
        } as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            unusableProductReasonId: Yup.string().required(t("You must select a reason")),
            count: Yup.number().required(t("You must enter quantity")).min(1, t("Quantity must be greater than zero")),
            expireDate: Yup.date().notRequired(),
            productId: Yup.string().required(t("You must select a product")),
            receivingBoxId: Yup.string().required(t("You must select a box")),
            receivingEntryId: Yup.string().required(t("You must select an entry"))
        }),
        onSubmit: (values) => {
            setBusy(true); 
            createReceivingProductLabels(values as CreateReceivingProductLabelsCommand)(dispatch).then(labels => {
                setBusy(false);
                props.onCreated?.(reason!, labels);
            }); 
        }
    });

    return <>
        <Form onSubmit={validation.handleSubmit}>
            <div className="hstack gap-3 justify-content-between text-nowrap">
                <ValidationWrapper validation={validation} field="unusableProductReasonId">
                    <UnusableReasonSelect onSelect={setReason} className="w-lg" />
                </ValidationWrapper>
                <ValidationWrapper validation={validation} field="count">
                    <NumberInput min={1} className="w-10 text-center" />
                </ValidationWrapper>
                <div className="d-flex align-items-center gap-2">
                    <Label className="mb-0">{t("Expire Date")}</Label>
                    <ValidationWrapper validation={validation} field="expireDate">
                        <DateInput />
                    </ValidationWrapper>
                </div>
                <BusyOverlay size="sm" busy={busy}>
                    <ValidatorButton validation={validation} type="submit" color="danger">{t("Create Unusable Product Label")}</ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default CreateUnusableProductLabel;