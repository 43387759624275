import ProductCard from "./_ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { useImmer } from "use-immer";
import { ListPrepServicesQuery } from "api/types/queries";
import { loadPrepServiceList } from "slices/shipping/prepService/thunk";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useEffect } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { createSelector } from "reselect";
import { RootState } from "slices";
import Loader from "Components/Common/Loader";
import BusyOverlay from "Components/Common/BusyOverlay";
import { loadFbaShipment, updateFbaShipment } from "slices/shipping/fbaShipping/thunk";

type ProductCardGridProps = {
    
}

const ProductCardGrid = (props: ProductCardGridProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.FbaShipping,
        (state) => ({
            fbaShipment: state.processingItem,
            loading: state.loading
        })
    );
    
    // Inside your component
    const { fbaShipment, loading } = useSelector(selectProperties);

    const [query, updateQuery] = useImmer<ListPrepServicesQuery>({
        page: 1,
        pageSize: 99,
        companyId: fbaShipment?.warehouse.company.companyId
    });

    const loadPrepServiceListDebounced = useDebounce(() => {
        loadPrepServiceList(query)(dispatch);
    }, 200, [query]);

    useEffect(() => {
        loadPrepServiceListDebounced();
    }, [loadPrepServiceListDebounced]);

    useEffect(() => {
        if (!fbaShipment) {
            return;
        }

        updateQuery(draft => {
            draft.companyId = fbaShipment.warehouse.company.companyId;
        });
    }, [fbaShipment, updateQuery]);

    if (!fbaShipment) {
        return <Loader height="200px" />;
    }

    const updateStatusToProcessing = async () => {
        await updateFbaShipment({
            fbaShipmentId: fbaShipment.fbaShipmentId,
            status: "processing"
        })(dispatch);

        await loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId })(dispatch);
    };

    return <>
        {["productPending", "fnskuPending", "ready"].includes(fbaShipment.status) && <div className="d-flex justify-content-center mt-2">
            <BusyOverlay busy={loading.save || loading.load} size="sm">
                <Button color="success" onClick={updateStatusToProcessing}>{t("Start Processing")}</Button>
            </BusyOverlay>
        </div>}
        <Card body className="mt-3">
            <h5 className="mb-0">
                {t("Selected Products ({{skuCount}} SKU & {{count}} items)", { 
                    skuCount: fbaShipment.items.length, 
                    count: _.sumBy(fbaShipment.items, i => i.count)
                })}
            </h5>
        </Card>
        <Row className="row-cols-3 mb-2">
            {fbaShipment.items.map((item, index) => {
                return <Col key={index}>
                    <ProductCard fbaShipment={fbaShipment} fbaShipmentItem={item} canEdit={fbaShipment.status === "processing"} />
                </Col>;
            })}
        </Row>
    </>;
};

export default ProductCardGrid;