import { useProfile } from "Components/Hooks/ProfileHooks";
import { UnitOfLength } from "api/types/contracts/common";
import _ from "lodash";

type DimensionsProps = {
    value: {
        width: number | undefined,
        height: number | undefined,
        length: number | undefined,
    },
    unit: UnitOfLength | undefined
}

const Dimensions = ({ value, unit }: DimensionsProps) => {
    const { userProfile } = useProfile();
    const displayUnit = unit || userProfile?.user.warehouse?.settings?.unitOfLength || "inch";

    if (value.width || value.height || value.length) {

        const width = value.width ? _.round(value.width, 2).toString() : "-";
        const height = value.height ? _.round(value.height, 2).toString() : "-";
        const length = value.length ? _.round(value.length, 2).toString() : "-";

        return <span className="text-nowrap">
            <span>{width} x {height} x {length}</span>
            <span> {displayUnit}</span>
        </span>;
    }
    else {
        return <span>-</span>;
    }
}

export default Dimensions;