import { createSelector } from "reselect";
import List from "./_List";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import BusyOverlay from "Components/Common/BusyOverlay";

const BinLocationsView = () => {

    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => {
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);
            const selectedShelf = selectedBay?.shelves?.find(s => s.shelfId === location.selectedShelfId);

            return {
                view: location.view,
                loading: location.loading,
                selectedArea,
                selectedAisle,
                selectedShelf,
                selectedBay
            };
        }
    );

    const { selectedShelf, loading } = useSelector(selectProperties);

    console.log(selectedShelf);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedShelf && <List binLocations={selectedShelf.binLocations} />}
        </BusyOverlay>
    </>;
}

export default BinLocationsView;