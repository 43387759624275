import _ from "lodash";
import {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

const useDebounce = (
    callback: VoidFunction,
    timeout: number,
    deps: unknown[] = []
) => {
    const ref = useRef<VoidFunction>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return _.debounce(func, timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeout, ...deps]);

    return debouncedCallback;
};

const useThrottle = (
    callback: VoidFunction,
    timeout: number,
    deps: unknown[] = []
) => {
    const ref = useRef<VoidFunction>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const throttledCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return _.throttle(func, timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeout, ...deps]);

    return throttledCallback;
};

type PersistedState<T> = [T | undefined, Dispatch<SetStateAction<T | undefined>>];

const createStorageStateHook = (storage: Storage) => <T>(key: string): PersistedState<T> => {
    const [value, setValue] = useState<T | undefined>(() => {
        const value = storage.getItem(key);

        return value ? (JSON.parse(value) as T) : undefined;
    });

    useEffect(() => {
        console.log("useEffect", key, value);
        if (value) {
            storage.setItem(key, JSON.stringify(value));
        }
        else {
            storage.removeItem(key);
        }
    }, [key, value]);

    return [value, setValue];
}

const useLocalState = createStorageStateHook(window.localStorage);
const useSessionState = createStorageStateHook(window.sessionStorage);

export { useDebounce, useLocalState, useSessionState, useThrottle };
