import { ForwardedRef, PropsWithChildren, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, } from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import BusyOverlay from "./BusyOverlay";

type ButtonType = "yes" | "no" | "cancel" | "ok" | "retry";
type ButtonConfigs = Record<ButtonType, { label: string, color: string }>;

export type DialogRef = {
    show: VoidFunction,
    hide: VoidFunction
}

export type DialogProps = PropsWithChildren & {
    title?: string,
    message?: string,
    color?: string,
    busy?: boolean,
    show?: boolean,
    iconClass?: string,
    buttons?: ButtonType[],
    buttonConfig?: (config: ButtonConfigs) => ButtonConfigs,
    onButtonClick?: (button: ButtonType, hide: VoidFunction) => void
}

const defaultButtonConfigs: ButtonConfigs = {
    yes: {
        label: "Yes",
        color: "default"
    },
    no: {
        label: "No",
        color: "light"
    },
    cancel: {
        label: "Cancel",
        color: "light"
    },
    ok: {
        label: "Ok",
        color: "default"
    },
    retry: {
        label: "Retry",
        color: "secondary"
    }
};

const Dialog = (props: DialogProps, ref: ForwardedRef<DialogRef>) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(props.show || false);

    const buttonConfigs = useMemo(() => {
        return props.buttonConfig ? props.buttonConfig(_.cloneDeep(defaultButtonConfigs)) : defaultButtonConfigs;
    }, [props]);

    const buttons = useMemo(() => 
        _.chain<ButtonType>(props.buttons || ["ok"]).uniq().map(button => ({ type: button, ...buttonConfigs[button] })).value()
    , [buttonConfigs, props.buttons]);

    const onButtonClick = (button: ButtonType) => {
        props.onButtonClick?.(button, hide);
    };
    const toggle = useCallback(() => setIsOpen(prev => !prev), [setIsOpen]);
    const show = useCallback(() => setIsOpen(true), [setIsOpen]);
    const hide = useCallback(() => setIsOpen(false), [setIsOpen]);

    const color = props.color || "info";

    useImperativeHandle(ref, () => {
        return {
            show,
            hide
        };
    }, [show, hide]);

    return (<>
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static" centered>
            <ModalBody className="py-3 px-5">
                {props.children || <div className="mt-2 text-center">
                    {props.iconClass && <i className={classNames(props.iconClass, "display-5", `text-${color}`)}></i>}
                    <div className="mx-4 mt-2 fs-15 mb-0 mx-sm-5">
                        <h4>{props.title}</h4>
                        <p className="text-muted mx-4 mb-0">
                            {props.message}
                        </p>
                    </div>
                </div>}
                <div className="mt-4 mb-2">
                    <BusyOverlay busy={props.busy || false} backgroundColor="white" spinnerColor="dark" opaque>
                        <div className="d-flex gap-2 justify-content-center">
                            {buttons.map(b => 
                                <Button type="button" key={b.type} color={(b.color === "default" ? color : b.color)} onClick={() => onButtonClick(b.type)}>{t(b.label)}</Button>
                            )}
                        </div>
                    </BusyOverlay>
                </div>
            </ModalBody>
        </Modal>
    </>);
}

export default forwardRef(Dialog);