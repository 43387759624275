import { Permission } from "helpers/permissions";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";

const useProfile = () => {
    const selectProperties = createSelector(
        (state: RootState) => state,
        (state) => ({
            userProfile: state.Login.user,
            customer: state.Tenant.customer,
            customerLoading: state.Tenant.customerLoading
        })
    );
    
    // Inside your component
    const { userProfile, customer } = useSelector(selectProperties);

    /**
     * Checks if the user has the provided permission.
     * @param permission - The permission to check.
     * @returns True if the user has the permission, false otherwise.
     */
    const hasPermission = (permission: Permission) => {
        if (userProfile && userProfile.user.permissions && permission in userProfile.user.permissions) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Retrieves the permission details for a given permission.
     * @param permission - The permission to retrieve details for.
     * @returns An object containing the permission details.
     */
    const getPermission = (permission: Permission) => {
        if (hasPermission(permission)) {
            const permissionString = userProfile!.user!.permissions![permission];
            return {
                create: permissionString.includes("c"),
                read: permissionString.includes("r"),
                write: permissionString.includes("w"),
                delete: permissionString.includes("d")
            };
        }
        else {
            return {
                create: false,
                read: false,
                write: false,
                delete: false
            };
        }
    }
    
    /**
     * Checks if the user has the permission to create based on the provided permission.
     * @param permission - The permission to check.
     * @returns True if the user has the permission to create, false otherwise.
     */
    const canCreate = (permission: Permission) => {
        return getPermission(permission).create;
    }

    /**
     * Checks if the user has the permission to write based on the provided permission.
     * @param permission - The permission to check.
     * @returns True if the user has the permission to write, false otherwise.
     */
    const canWrite = (permission: Permission) => {
        return getPermission(permission).write;
    };

    /**
     * Checks if the user has the permission to read based on the provided permission.
     * @param permission - The permission to check.
     * @returns True if the user has the permission to read, false otherwise.
     */
    const canRead = (permission: Permission) => {
        return getPermission(permission).read;
    };

    /**
     * Checks if the user has the permission to delete based on the provided permission.
     * @param permission - The permission to check.
     * @returns True if the user has the permission to delete, false otherwise.
     */
    const canDelete = (permission: Permission) => {
        return getPermission(permission).delete;
    };

    return { userProfile, customer, canWrite, canRead, canDelete, canCreate, hasPermission };
};

export { useProfile };
