import SelectInput from "Components/Form/SelectInput";
import { PrepServiceChannel } from "api/types/contracts/shipping";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const PrepServiceChannelSelect = (props: EnumSelectInput<PrepServiceChannel>) => {
    const { t } = useTranslation();

    const purposeOptions = useMemo(() => ["fba", "d2c"].map<SelectOption<PrepServiceChannel>>(p => ({
        value: p as PrepServiceChannel,
        label: t(`enums:PrepServiceChannel.${p}`)
    })), [t]);
    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<PrepServiceChannel> isMulti value={value} onChange={onChange} {...rest} options={purposeOptions} placeholder={t("Prep Service Channel")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={purposeOptions} placeholder={t("Prep Service Channel")} />}
    </>;
};

export default PrepServiceChannelSelect;