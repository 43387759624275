import { postCustomerRegister } from "api/customer";
import { RegisterCustomerCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { AnyAction, Dispatch } from "redux";
import { loading, setApiError, setRegisteredCustomer } from "./reducer";

export const registerCustomer = (params: RegisterCustomerCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["register", true]));

        const registeredCustomer = await postCustomerRegister(params);

        if (registeredCustomer) {
            dispatch(setRegisteredCustomer(registeredCustomer));

            return true;
        }
        else {
            return false;
        }
    } catch (error) {
        const apiError = error as ApiError;
        dispatch(setApiError(apiError.message));

        return false;
    }
    finally {
        dispatch(loading(["register", false]));
    }
};