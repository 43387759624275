import { CountryContract } from "api/types/contracts/common";

type CountryDisplayProps = {
    country: CountryContract
}

const CountryDisplay = ({ country }: CountryDisplayProps) => {
    return <div className="hstack gap-1">
        <img src={`/flags/${country.code.toLowerCase()}.svg`} alt={country.name} className="flag" style={{ height: "1rem" }} />
        {country.name}
    </div>;
}

export default CountryDisplay;