import { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";

//import images
import { useTranslation } from 'react-i18next';
import UpdateForm from './_UpdateForm';
import Activities from './_Activities';
import { CustomerContract } from 'api/types/contracts/customers';
import AvatarUpload from 'Components/AvatarUpload';
import { useMetadata } from 'Components/Hooks/MetadataHooks';
import UserStatusBadge from 'Components/Displays/UserStatusBadge';
import { useTenant } from 'Components/Hooks/TenantHooks';
import { loadCustomer } from 'slices/customer/thunk';
import { useDispatch } from 'react-redux';
import { updateStatus } from 'slices/user/thunk';
import BusyOverlay from 'Components/Common/BusyOverlay';
import DateDisplay from 'Components/Displays/DateDisplay';
type ProfileProps = {
    customer: CustomerContract,
    onSuccess?: VoidFunction
}

const Profile = (props: ProfileProps) => {
    const [customer, setCustomer] = useState(props.customer);
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const { company } = useTenant();
    const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const refresh = async () => {
        const customer = await loadCustomer({
            customerId: props.customer.customerId
        })(dispatch);

        if (customer) {
            setCustomer(customer);
        }
        
    };

    const activateUser = async () => {
        try {
            setStatusUpdateLoading(true);
            await updateStatus({
                userId: customer.user.userId,
                userStatus: "active"
            })(dispatch);
            await refresh();
        } finally {
            setStatusUpdateLoading(false);
        }
    };

    const suspendUser = async () => {
        try {
            setStatusUpdateLoading(true);
            await updateStatus({
                userId: customer.user.userId,
                userStatus: "suspend"
            })(dispatch);
            await refresh();
        } finally {
            setStatusUpdateLoading(false);
        }
    };
    
    setPageTitle(`${t("{{user}}'s Profile", { user: `${customer.name} ${customer.surname}` })} - ${t("Management")}`);

    return <>
        <Row>
            <Col xxl={3}>
                <Card body className='mb-0'>
                    <div>
                        <div className="text-center mb-3">
                            <AvatarUpload userId={customer.user.userId} avatar={customer.user.properties.avatar} onChange={refresh} />
                            <h5 className="fs-16 mb-1">{customer.user.name}</h5>
                            <p className="mb-1">{customer.user.email}</p>
                            <p className='text-muted mb-1'>{customer.company.name}</p>
                        </div>
                    </div>
                    <div>
                        <div className="text-center mb-3">
                            <h5>{t("Last Login")}</h5>
                            <p><DateDisplay date={customer.user.lastLoginDate} /></p>
                        </div>
                        <div className="text-center mb-3">
                            <h5>{t("Status")}</h5>
                            <div>
                                <p>
                                    <UserStatusBadge value={customer.user.userStatus} />
                                </p>
                            </div>
                            {customer.user.userStatus !== "active" && <div>
                                <BusyOverlay size='sm' busy={statusUpdateLoading}>
                                    <Button size='sm' color="success" onClick={activateUser}>{t("Activate")}</Button>
                                </BusyOverlay>
                            </div>}
                        </div>
                        {company && <div className="text-center mb-3">
                            <h5>{t("Warehouses")}</h5>
                            <ul className="list-group">
                                {company.warehouses.filter(w => customer.properties.availableWarehouses.includes(w.warehouseId))
                                    .map(w => <li className="list-group-item" key={w.warehouseId}>{w.name}</li>)}
                            </ul>
                        </div>}
                    </div>
                    <div className='vstack gap-3'>
                        <Button size='sm' color="soft-warning">{t("Send Password Reset")}</Button>
                        {customer.user.userStatus !== "suspend" && <div>
                            <BusyOverlay busy={statusUpdateLoading} size='sm'>
                                <Button size='sm' color="soft-danger" className='w-100' onClick={suspendUser}>{t("Suspend")}</Button>
                            </BusyOverlay>
                        </div>}
                    </div>
                </Card>
            </Col>

            <Col xxl={9}>
                <Card className='mb-0'>
                    <CardHeader>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => tabChange("1")}>
                                    <i className="fas fa-home"></i>
                                    {t("Personal Details")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="#" className={classnames({ active: activeTab === "2" })} onClick={() => tabChange("2")} type="button">
                                    <i className="far fa-user"></i>
                                    {t("Activities")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody className="p-4">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <UpdateForm customer={customer} onSuccess={props.onSuccess} />
                            </TabPane>
                            <TabPane tabId="2">
                                <Activities />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
};

export default Profile;