import BusyOverlay from "Components/Common/BusyOverlay";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import FbaShipmentItemsForm from "Components/Shared/Shipment/FbaShipmentItemsForm";
import { FbaShipmentFormItem } from "api/types/commands";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { addFbaShipmentItems, loadFbaShipment } from "slices/shipping/fbaShipping/thunk";

const AddProductForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [addModal, setAddModal] = useState(false);
    const [items, setItems] = useState<FbaShipmentFormItem[]>();

    const toggleAddModal = () => setAddModal(prev => !prev);

    const selectProperties = createSelector(
        (state: RootState) => state.FbaShipping,
        (state) => ({
            fbaShipment: state.processingItem,
            loading: state.loading
        })
    );
    
    // Inside your component
    const { fbaShipment, loading } = useSelector(selectProperties);

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const addItems = async (items: FbaShipmentFormItem[]) => {
        if (items.length > 0) {
            const existing = fbaShipment.items.filter(p => items.filter(i => i.productId === p.product.productId));

            await addFbaShipmentItems({
                fbaShipmentId: fbaShipment.fbaShipmentId,
                items
            })(dispatch);
            
            setItems(undefined);
            toggleAddModal();

            await loadFbaShipment({
                fbaShipmentId: fbaShipment.fbaShipmentId
            })(dispatch);
        }
    }

    return <>
        <Button color="primary" type="button" onClick={toggleAddModal}>
            {t("Add New Product")}
        </Button>
        <Modal isOpen={addModal} toggle={toggleAddModal} size="xl">
            <ModalCloseButton onClick={toggleAddModal} />
            <ModalHeader>
                {t("Add Product")}
            </ModalHeader>
            <ModalBody>
                <FbaShipmentItemsForm existingProducts={fbaShipment.items.map(i => i.product.productId)} value={items} onChange={setItems} warehouseId={fbaShipment.warehouse.warehouseId} size="sm" />
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-2">
                    <BusyOverlay busy={loading.save} size="sm">
                        <Button color="success" type="button" onClick={() => addItems(items ?? [])}>
                            {t("Save")}
                        </Button>
                    </BusyOverlay>
                    <Button color="danger" type="button" onClick={toggleAddModal}>
                        {t("Cancel")}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    </>
}

export default AddProductForm;