import { ListProductBrandsQuery } from "api/types/queries";
import { CreateProductBrandCommand, DeleteProductBrandCommand, UpdateProductBrandCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductBrandContract } from "./types/contracts/products";

const api = new APIClient();

export const getProductBrandList = async (data: ListProductBrandsQuery): Promise<PagedList<ProductBrandContract>> => {
    const result = await api.get(`/api/productbrand`, data);
    return result;
}

export const postProductBrand = async (data: CreateProductBrandCommand): Promise<ProductBrandContract | undefined> => {
    const response = await api.post(`/api/productbrand`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putProductBrand = async (data: UpdateProductBrandCommand): Promise<void> => {
    await api.put(`/api/productbrand/${data.productBrandId}`, { ...data.productBrand });
}

export const deleteProductBrand = async (data: DeleteProductBrandCommand): Promise<void> => {
    await api.delete(`/api/productbrand/${data.productBrandId}`);
}
