import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import CustomerSuitDisplay from "Components/Displays/CustomerSuitDisplay";
import TextInput from "Components/Form/TextInput";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Label, Row, Table } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadReceivingEntry, resumeReceivingEntry, selectBox } from "slices/receivings/thunk";
import DateDisplay from "Components/Displays/DateDisplay";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import ReceivingEntryStatusBadge from "Components/Displays/ReceivingEntryStatusDisplay";
import BusyOverlay from "Components/Common/BusyOverlay";
import CompleteButton from "./_CompleteButton";

const BoxSelection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [boxNumber, setBoxNumber] = useState<string>();

    const selectProperties = createSelector(
        (state: RootState) => state.Receiving,
        (receiving) => ({
            processedEntry: receiving.processedEntry!,
            gateEntry: receiving.gateEntry,
            loading: receiving.loading
        })
    );
    
    const { processedEntry, gateEntry, loading } = useSelector(selectProperties);

    useEffect(() => {
        if (boxNumber && processedEntry) {
            const box = processedEntry.boxes.find(b => b.box.boxNumber === boxNumber);

            if (box) {
                selectBox(box)(dispatch);
            }
        }
    }, [processedEntry, boxNumber, dispatch]);

    const resume = async () => {
        await resumeReceivingEntry({ receivingEntryId: processedEntry.receivingEntryId })(dispatch);
        await loadReceivingEntry({ receivingEntryId: processedEntry.receivingEntryId })(dispatch);
    }

    const canProcess = processedEntry.status !== "completed";
    const warehouse = processedEntry.warehouse ?? processedEntry.inbound?.warehouse ?? gateEntry.warehouse;

    return <>
        <div className="d-flex justify-content-end mb-2">
            {processedEntry.status === "completed" && <BusyOverlay size="sm" busy={loading.save || loading.load}>
                <Button color="primary" onClick={resume}>
                    {t("Continue Receiving")}
                </Button>
            </BusyOverlay>}
            {processedEntry.status === "grn" && <CompleteButton>{t("Complete Receiving")}</CompleteButton>}
        </div>
        <Row>
            <Col md={8}>
                <Card body>
                    <Row>
                        <Col>
                            <dl className="row">
                                <dt className="col-6">{t("Receiving ID")}</dt>
                                <dd className="col-6 mb-0">#{processedEntry.receivingNumber}</dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Create Date")}</dt>
                                <dd className="col-6 mb-0"><DateDisplay date={processedEntry.inbound?.createdAt} /></dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Shipping Purpose")}</dt>
                                <dd className="col-6">{t(`enums:ShippingPurpose.${processedEntry.inbound?.shippingPurpose}`)}</dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Warehouse")}</dt>
                                <dd className="col-6">{warehouse.name}</dd>
                                <dd className="text-muted">
                                    <InlineAddressDisplay address={warehouse.address} />
                                </dd>
                            </dl>
                        </Col>
                        <Col>
                            <dl className="row">
                                <dt className="col-6">{t("Inbound ID")}</dt>
                                <dd className="col-6 mb-0">#{processedEntry.inbound?.inboundCode || "-"}</dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Box Count")}</dt>
                                <dd className="col-6 mb-0">{processedEntry.boxes.length}</dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Supplier")}</dt>
                                <dd className="col-6">
                                    {processedEntry.inbound?.fromAmazon ? "Amazon" : <ListSummaryDisplay items={processedEntry.inbound?.suppliers || []} displayFn={s => s.name} />}
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Status")}</dt>
                                <dd className="col-6 mb-0">
                                    <ReceivingEntryStatusBadge value={processedEntry.status} />
                                </dd>
                            </dl>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col md={4}>
                <Card body>
                    <h5 className="text-center">{t("Customer")}</h5>
                    <dl className="row">
                        <dt className="col-5">{t("Suit No")}</dt>
                        <dd className="col-7 mb-0">
                            {processedEntry.customer.suitNumber && <CustomerSuitDisplay suit={processedEntry.customer.suitNumber} />}
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-5">{t("Full Name")}</dt>
                        <dd className="col-7 mb-0">
                            {processedEntry.customer.name} {processedEntry.customer.surname}
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-5">{t("Email")}</dt>
                        <dd className="col-7 mb-0">
                            {processedEntry.customer.user.email}    
                        </dd>
                    </dl>
                    <dl className="row">
                        <dt className="col-5">{t("Phone")}</dt>
                        <dd className="col-7 mb-0">
                            {processedEntry.customer.phone}    
                        </dd>
                    </dl>
                </Card>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col xs="auto">
                <div className="hstack gap-2 align-items-center">
                    <Label className="flex-shrink-0 mb-0">{t("Select Box")}</Label>
                    <TextInput placeholder={"Enter Box ID"} value={boxNumber} onChange={setBoxNumber} />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card body>
                    <div className="table-responsive table-card">
                        <Table size="sm" borderless className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>{t("Action")}</th>
                                    <th>{t("Box ID")}</th>
                                    <th>{t("Tracking Number")}</th>
                                    <th>{t("Dimentions")}</th>
                                    <th>{t("Weight")}</th>
                                    <th>{t("Create Date")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processedEntry?.boxes?.map((receivingBox, index) => {
                                    return <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <div className="hstack gap-2">
                                                    <Button disabled={!canProcess} color="soft-success" size="sm" onClick={() => selectBox(receivingBox)(dispatch)}>
                                                        {t("Select")}
                                                    </Button>
                                                    <Button disabled={!canProcess} color="soft-secondary" size="sm">{t("Print")}</Button>
                                                </div>
                                            </td>
                                            <td>#{receivingBox.box.boxNumber}</td>
                                            <td>{receivingBox.trackingNumber ?? "-"}</td>
                                            <td><Dimensions value={receivingBox.box} unit={processedEntry.warehouse.settings.unitOfLength} /></td>
                                            <td><Weight value={receivingBox.box.weight} unit={processedEntry.warehouse.settings.unitOfWeight} /></td>
                                            <td><DateDisplay date={receivingBox.createdAt} /></td>
                                        </tr>
                                        <tr className="table-light">
                                            <td colSpan={7} className="p-3">
                                                {receivingBox.box.contents.length > 0 ? <>
                                                    <Table size="sm" className="table-nowrap mb-0 bg-white shadow-lg">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("SKU")}</th>
                                                                <th>{t("ASIN")}</th>
                                                                <th>{t("Total Received")}</th>
                                                                <th>{t("Total Sellable")}</th>
                                                                <th>{t("Total Unusable")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {receivingBox.box.contents.map((content, index) => <tr key={index}>
                                                                <td>{content.product.sku}</td>
                                                                <td>{content.product.asin}</td>
                                                                <td>{content.unusableQuantity + content.sellableQuantity}</td>
                                                                <td>{content.sellableQuantity}</td>
                                                                <td>{content.unusableQuantity}</td>
                                                            </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </> : <>
                                                    <div className="text-center text-muted">{t("No products in this box")}</div>
                                                </>}
                                            </td>
                                        </tr>
                                    </React.Fragment>;
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </Col>
        </Row>
    </>;
}

export default BoxSelection;