import { UserStatus } from "api/types/contracts/users";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";


const UserStatusBadge = (props: StatusBadgeProps<UserStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const status = t(`UserStatus.${props.value}`);

    switch (props.value) {
        case "active": return <Badge color="success-subtle" className="text-success">{status}</Badge>;
        case "pending": return <Badge color="info-subtle" className="text-info">{status}</Badge>;
        case "passive": return <Badge color="dark-subtle" className="text-dark">{status}</Badge>;
        case "suspend": return <Badge color="warning-subtle" className="text-warning">{status}</Badge>;
    }
}

export default UserStatusBadge;