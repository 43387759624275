import { SliderPicker } from "react-color";
import { CSSProperties, useRef } from "react";
import { Button, Input, InputGroup, PopoverBody, UncontrolledPopover } from "reactstrap";
import { ValidatableInputProps } from "helpers/types";

export type ColorPickerProps = ValidatableInputProps<string> & {
    type?: "slider"
    className?: string,
    style?: CSSProperties,
    placeholder?: string
}

const ColorPicker = (props: ColorPickerProps) =>  {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const setColor = (color: string | undefined) => props.onChange?.(color);
    const type = props.type || "slider";

    return (<>
        <InputGroup>
            <Button innerRef={buttonRef} color="light" type="button" style={{backgroundColor: props.value}}></Button>
            <Input 
                valid={props.isValid === true} 
                invalid={props.isValid === false} 
                name={props.name} 
                type="text" 
                placeholder={props.placeholder} 
                value={props.value || ""} 
                onChange={e => setColor(e.target.value)} />
        </InputGroup>
        <UncontrolledPopover target={buttonRef} placement="bottom" trigger="legacy">
            <PopoverBody style={{width: "200px"}}>
                {type === "slider" && <SliderPicker color={props.value || ""} onChange={e => e ? setColor(e.hex) : null} />}
            </PopoverBody>
        </UncontrolledPopover>
    </>)
}

export default ColorPicker;