import { PERLOADER_TYPES } from "helpers/constants/layout";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";

const Preloader = () => {
    const selectProperties = createSelector(
        (state: RootState) => state.Layout,
        (layout) => ({
            preloader: layout.preloader,
        })
    );
    
    const { preloader } = useSelector(selectProperties);
        
    return (<>
        {preloader === PERLOADER_TYPES.ENABLE && <div id="preloader">
            <div id="status">
                <div className="spinner-border text-primary avatar-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            </div>}
        </>);
}

export default Preloader;