import { InputGroup, InputGroupText } from "reactstrap";
import NumberInput, { NumberInputProps } from "../NumberInput";
import { useProfile } from "Components/Hooks/ProfileHooks";
import classNames from "classnames";
import { UnitOfLength } from "api/types/contracts/common";

export type LengthInputProps = NumberInputProps & {
    unit?: UnitOfLength
}

const LengthInput = ({ unit, className, size, ...rest }: LengthInputProps) => {
    const { userProfile } = useProfile();

    const lengthUnit = unit ?? userProfile?.user.warehouse?.settings.unitOfLength ?? "inch";

    return <InputGroup size={size}>
        <NumberInput {...rest} decimalPlaces={2} className={classNames("border-end-0", className)} />
        <InputGroupText className="bg-transparent pe-2 ps-0">
            {lengthUnit}
        </InputGroupText>
    </InputGroup>
}

export default LengthInput;