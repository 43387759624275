import { InfoAlert } from "Components/Alerts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";

type ProblemsProps = {
    
}

const Problems = (props: ProblemsProps) => {
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (inbound) => ({
            error: inbound.error,
            inbound: inbound.processingItem!,
            loading: inbound.loading
        })
    );
    const { loading, inbound, error } = useSelector(selectProperties); 

    return <>
        <InfoAlert className="mb-0">{t("No problems found")}</InfoAlert>
    </>;
}

export default Problems;