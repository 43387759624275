import { Button, Modal, ModalBody } from "reactstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import { reloadLocations } from "slices/location/thunk";
import CreateShelfFormView from "./_CreateShelfFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";

const NewShelfButton = () => {
    const [newModal, setNewModal] = useState(false);
    const { t } = useTranslation();
    //const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => {
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);

            return {
                ...location,
                selectedArea,
                selectedAisle,
                selectedBay
            };
        }
    );

    const { warehouse, selectedBay } = useSelector(selectProperties);

    const onShelfAdded = () => { 
        setNewModal(false);
        reloadLocations({ warehouseId: warehouse!.warehouseId })(dispatch);
    };

    return warehouse ? <>
        <Button color="info" onClick={() => setNewModal(true)}>
            <i className="ri-add-line align-bottom me-1"></i> {t("Add New Shelf")}
        </Button>
        <Modal isOpen={newModal} toggle={() => setNewModal(prev => !prev)} size="lg">
            <ModalBody>
                <ModalCloseButton onClick={() => setNewModal(false)} />
                <CreateShelfFormView bayId={selectedBay!.bayId} onSuccess={onShelfAdded} />
            </ModalBody>
        </Modal>
    </> : null;
};

export default NewShelfButton;