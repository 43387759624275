import { AisleContract } from "api/types/contracts/locations";
import AisleItem, { AisleItemRef } from "../Aisles/_AisleItem";
import ShelfGrid from "../Shelves/_Grid";
import { useRef, useCallback, useEffect } from "react";

type GridProps = {
    aisle: AisleContract
}

const Grid = (props: GridProps) => {
    const shelvesDivRef = useRef<HTMLDivElement>(null);
    const aisleItemRef = useRef<AisleItemRef>(null);

    const shelvesDivOnScroll = useCallback(function (this: HTMLDivElement, e: Event) {
        if (aisleItemRef.current && aisleItemRef.current.baysDivRef) {
            aisleItemRef.current.baysDivRef.scrollTo({
                left: this.scrollLeft,
                behavior: "instant"
            })
        }
    }, [aisleItemRef]);

    const aisleItemBaysDivOnScroll = useCallback(function (this: HTMLDivElement, e: Event) {
        if (shelvesDivRef.current) {
            shelvesDivRef.current.scrollTo({
                left: this.scrollLeft,
                behavior: "instant"
            })
        }
    }, [shelvesDivRef]);
    

    useEffect(() => {
        const shelvesDiv = shelvesDivRef.current;
        const aisleItemBaysDiv = aisleItemRef.current?.baysDivRef;

        if (shelvesDiv) {
            shelvesDiv.addEventListener("scroll", shelvesDivOnScroll);
        }

        if (aisleItemBaysDiv) {
            aisleItemBaysDiv.addEventListener("scroll", aisleItemBaysDivOnScroll);
        }

        return () => {
            shelvesDiv?.removeEventListener("scroll", shelvesDivOnScroll);
            aisleItemBaysDiv?.removeEventListener("scroll", aisleItemBaysDivOnScroll);
        }
    }, [shelvesDivRef, aisleItemRef, shelvesDivOnScroll, aisleItemBaysDivOnScroll]);

    return <>
        <AisleItem aisle={props.aisle} ref={aisleItemRef} />
        <div className="shelves bg-dark-subtle" ref={shelvesDivRef}>
            <div className="d-flex gap-2 p-2">
                {props.aisle.bays?.map(bay => <>
                    <ShelfGrid mini bay={bay} />
                </>)}
            </div>
        </div>
    </>;
};

export default Grid;