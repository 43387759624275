import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { CreateAisleCommand } from 'api/types/commands';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Label, Row } from 'reactstrap';
import { createSelector } from 'reselect';
import { RootState } from 'slices';
import { createAisle } from 'slices/location/thunk';
import * as Yup from 'yup';

type FormProps = {
    areaId: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateAisleCommand, "areaId">;

const CreateAisleFormView = (props: FormProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => ({
            error: location.error,
            loading: location.loading
        })
    );
    const { loading, error } = useSelector(selectProperties); 

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {} as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            aisleCount: Yup.number().required("You must enter aisle count").min(1, t("Aisle count must be greater than zero")),
            bayCount: Yup.number().notRequired(),
            aisleHeight: Yup.number().notRequired(),
            aisleWidth: Yup.number().notRequired(),
            aisleDepth: Yup.number().notRequired(),
            bayHeight: Yup.number().notRequired(),
            bayWidth: Yup.number().notRequired()
        }),
        onSubmit: (values) => {
            const savePromise = createAisle({ 
                areaId: props.areaId,
                ...values
            })(dispatch);

            savePromise.then(success => success && props.onSuccess?.());
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Add Aisles")}</h5>
                        <p className="text-muted">
                            
                        </p>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="aisleCount">{t("Aisle Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="aisleCount">
                            <NumberInput placeholder="Enter Aisle Count" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="bayCount">{t("Bay Count")}</Label>
                        <ValidationWrapper validation={validation} field="bayCount">
                            <NumberInput placeholder="Enter bay count on each aisle" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="aisleHeight">{t("Aisle Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="aisleHeight">
                            <NumberInput placeholder="Enter aisle height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="aisleWidth">{t("Aisle Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="aisleWidth">
                            <NumberInput placeholder="Enter aisle width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="aisleDepth">{t("Aisle Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="aisleDepth">
                            <NumberInput placeholder="Enter aisle depth" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                {validation.values.bayCount !== 0 && <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="bayHeight">{t("Bay Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="bayHeight">
                            <NumberInput placeholder="Enter bay height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="bayWidth">{t("Bay Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="bayWidth">
                            <NumberInput placeholder="Enter bay width" />
                        </ValidationWrapper>
                    </Col>
                </Row>}
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default CreateAisleFormView;