import { ProductContract, StockContract } from "api/types/contracts/products";

const defaultStock: StockContract = {
    available: 0,
    inbound: 0,
    onHold: 0,
    reserved: 0,
    unfulfillable: 0,
    researching: 0,
    warehouseId: "",
    updatedAt: new Date()
}

const stockOf = (product: ProductContract | undefined, warehouseId?: string): StockContract => {
    if (!product) {
        return { ...defaultStock };
    }

    const stockOfWarehouse = product.stocks.find(s => s.warehouseId === warehouseId);

    if (!stockOfWarehouse) {
        return { ...defaultStock, warehouseId: warehouseId || ""};
    }

    return stockOfWarehouse;
}

export { stockOf };