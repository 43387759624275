import { UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { FbaShipmentBoxContract, FbaShipmentBoxItemContract } from "api/types/contracts/shipping";
import { convertLength, convertWeight } from "./unitConversions";

type Item = {
    length: number,
    width: number,
    height: number,
    weight: number,
    lengthUnit: UnitOfLength,
    weightUnit: UnitOfWeight,
};
type Product = FbaShipmentBoxItemContract & Item;

const dimFactor: Record<UnitOfLength, Record<UnitOfWeight, number>> = {
    "cm": {
        "kg": 3000,
        "lbs": 6600,
    },
    "inch": {
        "kg": 63,
        "lbs": 139,
    },
}; // Dimensional weight factor
const boxDesiLimit = 50; // Kutunun maksimum ağırlık sınırı

type Box = FbaShipmentBoxContract & {

};

const addProduct = (box: Box, product: Product) => {
    box.desi += desi(product);
    box.weight += convertWeight(product.weight, product.weightUnit, box.weightUnit);

    const found = box.products.find(p => p.productId === product.productId);
    if (found) {
        found.count += 1;
    } else {
        box.products.push({
            productId: product.productId,
            asin: product.asin,
            count: 1
        });
    }
}

const canAddProduct = (box: Box, product: Product)  => {
    const productDesi = desi(product);

    return (box.desi + productDesi) <= boxDesiLimit;
}


export const desi = ({ width, height, length, weight, lengthUnit, weightUnit }: Item) => {
    const dimensionalWeight = (length * width * height) / 139;
    return dimensionalWeight > weight ? dimensionalWeight : weight;
}

export const packProducts = (products: Product[], lengthUnit: UnitOfLength, weightUnit: UnitOfWeight): Box[] => {
    const sortedProducts = products
        .flatMap((product) => Array<Product>(Math.ceil(product.count)).fill(product))
        .sort((a, b) => desi(b) - desi(a));

    const boxes: Box[] = [];
    for (const product of sortedProducts) {
        let packed = false;
        for (const box of boxes) {
            if (box.products.length === 0 || canAddProduct(box, product)) {
                addProduct(box, product);
                packed = true;
                break;
            }
        }

        if (!packed) {
            const newBox: Box = {
                boxNumber: boxes.length + 1,
                width: convertLength(0, "inch", lengthUnit),
                height: convertLength(0, "inch", lengthUnit),
                length: convertLength(0, "inch", lengthUnit),
                weight: 0,
                desi: 0,
                weightUnit: weightUnit,
                lengthUnit: lengthUnit,
                products: []
            };
            addProduct(newBox, product);
            boxes.push(newBox);
        }
    }
    return boxes;
}

