import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import Restricted from "Components/Common/Restricted";
import WizardForm from "./_WizardForm";

const GetAQuotePage = () => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    setPageTitle(`${t("Get A Quote")} - ${t("FBA Prep & Shipping")}`);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Get A Quote")} parents={[t("FBA Prep & Shipping")]} />
                <Restricted require="shipping.fbashipping" create throw>
                    <WizardForm />
                </Restricted>
            </Container>
        </div>
    </>;
}

export default GetAQuotePage;