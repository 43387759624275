import { APIClient } from "../helpers/api_helper";
import { CreateAisleCommand, UpdateAisleCommand, DeleteAisleCommand } from "./types/commands";

const api = new APIClient();

export const postAisle = async (data: CreateAisleCommand): Promise<void> => {
    await api.post(`/api/aisle`, data);
}

export const putAisle = async (data: UpdateAisleCommand): Promise<void> => {
    await api.put(`/api/aisle/${data.aisleId}`, { ...data.aisle });
}

export const deleteAisle = async (data: DeleteAisleCommand): Promise<void> => {
    await api.delete(`/api/aisle/${data.aisleId}`);
}