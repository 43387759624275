import { EnumSelectRadio } from "helpers/types";
import { useTranslation } from "react-i18next";
import Radiobox from "Components/Form/Radiobox";
import classNames from "classnames";
import { PrepServicePricingType } from "api/types/contracts/shipping";

const PrepServicePricingTypeList = ["fixPrice", "perUnit", "perBox", "perSku", "custom"] as PrepServicePricingType[];

const PrepServicePricingTypeRadio = (props: EnumSelectRadio<PrepServicePricingType>) => {
    const { t } = useTranslation();
    const { value, onChange, ...rest } = props;

    const handleRadioChange = (val: string) => {
        onChange?.(val as PrepServicePricingType);
    };

    return <div className={classNames("d-flex gap-3", props.className, {
        "flex-row": props.direction === "horizontal",
        "flex-column": props.direction === "vertical"
    })}>
        {PrepServicePricingTypeList.map((option) => (
            <Radiobox key={option} name="prepServicePricingType" id={`prepServicePricingType_${option}`} value={value === option} 
                disabled={props.disabledCallback?.(option as PrepServicePricingType) ?? false} 
                label={t(`enums:PrepServicePricingType.${option}`)} onChange={() => handleRadioChange(option)} {...rest} />
        ))}
    </div>;
};

export default PrepServicePricingTypeRadio;
