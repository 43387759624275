//import images 
import { useTranslation } from "react-i18next";

import Grid from "./_Grid";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import { WarningAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";

type AreasViewProps = {
    
}

const AreasView = (props: AreasViewProps) => {
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => ({
            areas: location.areas,
            loading: location.loading
        })
    );

    const { areas, loading } = useSelector(selectProperties);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {areas && areas.length > 0 ? 
                <Grid areas={areas} />
            :
                <WarningAlert>{t("There are no defined areas yet")}</WarningAlert>
            }
        </BusyOverlay>
    </>;
};

export default AreasView;
