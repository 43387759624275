import { ColumnDef } from "@tanstack/react-table";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import DateDisplay from "Components/Displays/DateDisplay";
import InboundCodeDisplay from "Components/Displays/InboundCodeDisplay";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import InboundStatusSelect from "Components/EnumSelects/InboundStatusSelect";
import ShippingPurposeSelect from "Components/EnumSelects/ShippingPurposeSelect";
import DateRangeInput from "Components/Form/DateRangeInput";
import NumericRangeInput from "Components/Form/NumericRangeInput";
import TextInput from "Components/Form/TextInput";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { CompanyContract } from "api/types/contracts/companies";
import { InboundContract, InboundStatus, ShippingPurpose } from "api/types/contracts/inbound";
import { ListInboundsQuery } from "api/types/queries";
import classNames from "classnames";
import _ from "lodash";
import { Ref, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadList, removeInbound, removeMultipleInbounds } from "slices/inbound/thunk";
import { useImmer } from "use-immer";
import SearchBox from "Components/Form/SearchBox";

export type ProductListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<ProductListRef>) => {
    const [company, setCompany] = useState<CompanyContract>();
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<InboundContract[]>([]);
    const inbound = useMemo(() => selectedItems[0], [selectedItems]);
    const { setPageTitle } = useMetadata();
    
    const [query, updateQuery] = useImmer<ListInboundsQuery>({
        page: 1,
        pageSize: 10,
        search: ""
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (inbound) => ({
            loading: inbound.loading,
            list: inbound.list,
            error: inbound.error,
        })
    );
    
    // Inside your component
    const { list, loading } = useSelector(selectProperties);
    const debouncedLoadList = useDebounce(() => {
        loadList(query)(dispatch).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    setPageTitle(`${t("Inbound List")} - ${t("Inbounds")}`);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                debouncedLoadList();
            }
        };
    }, [debouncedLoadList]);

    const deleteInbound = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            return await removeMultipleInbounds(selectedItems.map(item => ({
                inboundId: item.inboundId
            })))(dispatch);
        }
        else if (deleteMode === "single" && inbound) {
            return await removeInbound({
                inboundId: inbound.inboundId
            })(dispatch);
        }

        return false;
    }, [inbound, deleteMode, selectedItems, dispatch]);

    const handleDeleteClick = (arg: InboundContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
    };
    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode]);

    // Column
    const columns = useMemo<ColumnDef<InboundContract, any>[]>(() => [
        selectRowColumn<InboundContract>(),
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Link to={`/inbounds/${cell.row.original.inboundId}`} className="btn btn-ghost-secondary px-1 py-0">
                            <i className="ri-eye-fill fs-16"></i>
                        </Link>
                        <Link to={`/inbounds/edit/${cell.row.original.inboundId}`} className={classNames("btn btn-ghost-dark px-1 py-0", {
                            "disabled": !["draft", "confirmed"].includes(cell.row.original.inboundStatus)
                        })}>
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                        <Button color="ghost-danger" className="px-1 py-0" disabled={!["draft", "confirmed"].includes(cell.row.original.inboundStatus)}
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </div>
                </>;
            },
        }, {
            header: t("# ID"),
            accessorFn: item => item.inboundCode,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <InboundCodeDisplay inboundCode={cell.getValue()} />,
        }, {
            header: t("CREATION DATE"),
            accessorFn: item => item.createdAt,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <DateDisplay date={cell.getValue()} />,
        }, {
            header: t("EXPECTED ARRIVAL"),
            accessorFn: item => item.estimatedArrivalDate,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <DateDisplay date={cell.getValue()} />,
        }, {
            header: t("SKU COUNT"),
            accessorFn: item => item.items.length,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <>
                <Link to={`/inbounds/${cell.row.original.inboundId}`} className="link-secondary">{cell.getValue()}</Link>
            </>,
        }, {
            header: t("TOTAL EXPECTED"),
            accessorFn: item => _.sumBy(item.items, i => i.quantity),
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <>
                <Link to={`/inbounds/${cell.row.original.inboundId}`} className="link-secondary">{cell.getValue()}</Link>
            </>,
        }, {
            header: t("TOTAL RECEIVED"),
            accessorFn: item => _.sumBy(item.items, i => i.received),
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <>
                <Link to={`/inbounds/${cell.row.original.inboundId}`} className="link-secondary">{cell.getValue()}</Link>
            </>,
        }, {
            header: t("STATUS"),
            accessorFn: item => item.inboundStatus,
            enableColumnFilter: false,
            cell: (cell) => <InboundStatusBadge value={cell.getValue<InboundStatus>()}></InboundStatusBadge>
        }, {
            header: t("SUPPLIERS"),
            accessorFn: item => item.suppliers,
            enableColumnFilter: false,
            cell: (cell) => <>
                <ListSummaryDisplay items={cell.row.original.suppliers} displayFn={item => item.name} />
            </>
        }, {
            header: t("PURPOSE"),
            accessorFn: item => item.shippingPurpose,
            enableColumnFilter: false,
            cell: (cell) => <span>{t(`enums:ShippingPurpose.${cell.getValue<ShippingPurpose>()}`)}</span>
        }, {
            header: t("TRACKING IDs"),
            accessorFn: item => item.trackings,
            enableColumnFilter: false,
            cell: (cell) => <ListSummaryDisplay items={cell.row.original.trackings} displayFn={item => item.trackingNumber} />
        }, {
            header: t("DESTINATION"),
            accessorFn: item => item.warehouse.name,
            enableColumnFilter: false
        }, {
            header: t("INITIAL ENTRY"),
            accessorFn: item => item.checkInDate,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <DateDisplay date={cell.getValue()} />,
        }, {
            header: t("COMPLETION"),
            accessorFn: item => item.completionDate,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <DateDisplay date={cell.getValue()} />,
        }, {
            header: t("NOTES"),
            accessorFn: item => item.notes,
            enableHiding: true,
            enableColumnFilter: false,
        }],
        [t]
    );

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Inbounds")} parents={[t("Receving")]} />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">{t("Inbound List")}</h5>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="d-flex gap-1 flex-wrap">
                                            <Restricted require="receiving.inbound" create>
                                                <Link to="/inbounds/new" className="btn btn-info add-btn">
                                                    <i className="ri-add-line align-bottom me-1"></i> {t("Create New Inbound")}
                                                </Link>
                                            </Restricted>
                                            {selectedItems.length > 0 && deleteMode !== "single" && <Button color="soft-danger" onClick={() => handleMultiDeleteClick()}>
                                                <i className="ri-delete-bin-2-line"></i>
                                            </Button>}
                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row className="mb-2">
                                    <Restricted require="management.company" read>
                                        <Col>
                                            <CompanySelect onSelect={company => {
                                                setCompany(company);
                                                updateQuery(q => {
                                                    q.companyId = company?.companyId;
                                                });
                                            }} />
                                        </Col>
                                    </Restricted>
                                    <Restricted require="management.customer" read>
                                        <Col>
                                            <CustomerSelect value={query.customers} isMulti companyId={company?.companyId} onSelect={customers => {
                                                updateQuery(q => {
                                                    q.customers = customers.map(c => c.customerId);
                                                });
                                            }} />
                                        </Col>
                                    </Restricted>
                                    <Col>
                                        <WarehouseSelect value={query.destinationWarehouseId} companyId={company?.companyId} onSelect={warehouse => {
                                            updateQuery(q => {
                                                q.destinationWarehouseId = warehouse?.warehouseId;
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <InboundStatusSelect value={query.statuses} isMulti onChange={statuses => {
                                            updateQuery(q => {
                                                q.statuses = statuses;
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <ProductSupplierSelect value={query.suppliers} isMulti onSelect={suppliers => {
                                            updateQuery(q => {
                                                q.suppliers = suppliers.map(s => s.supplierId);
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <ShippingPurposeSelect value={query.shippingPurposes} isMulti onChange={purposes => {
                                            updateQuery(q => {
                                                q.shippingPurposes = purposes;
                                            });
                                        }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} md={6} lg={4} className="">
                                        <SearchBox value={query.search} placeholder="Search for tracking number or Inbound (INB- prefix)" onChange={trackingNumber => {
                                            updateQuery(q => {
                                                q.search = trackingNumber;
                                            });
                                        }} />
                                    </Col>
                                    <Col sm={6} md={6} lg={4} className="gap-2 hstack">
                                        <Label className="mb-0 text-nowrap">{("Exptected Arrival")}</Label>
                                        <DateRangeInput value={query.expectedArrivalDateRange} onChange={range => {
                                            updateQuery(q => {
                                                q.expectedArrivalDateRange = range;
                                            });
                                        }} />
                                    </Col>
                                    <Col sm={6} md={6} lg={4} className="gap-2 hstack">
                                        <Label className="mb-0 text-nowrap">{("Total Quantity")}</Label>
                                        <NumericRangeInput value={query.totalQuantityRange} onChange={range => {
                                            updateQuery(q => {
                                                q.totalQuantityRange = range;
                                            })
                                        }} />
                                    </Col>
                                </Row>
                                <TableContainer
                                    ref={tableRef}
                                    busy={loading.list}
                                    columns={columns}
                                    data={(list?.items || [])}
                                    totalDataLength={list?.totalCount}
                                    nowrap
                                    hiddenColumns={[t("EXPECTED ARRIVAL"), t("NOTES")]}
                                    pagination={{
                                        pageIndex: query.page - 1,
                                        pageSize: query.pageSize
                                    }}
                                    onPaginationChanged={pagination => updateQuery(q => {
                                        q.page = pagination.pageIndex + 1;
                                        q.pageSize = pagination.pageSize;
                                    })}
                                    onSelectionChanged={selection => {
                                        setSelectedItems(selection);
                                    }}
                                    divClass="mb-1"
                                    tableClass="align-middle"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
                    message={`Do you want to continue?`} title={t("Deleting inbound", { count: deleteMode === "multi" ? selectedItems.length : 1 })}
                    onButtonClick={(button, hide) => {
                        if (button === "yes") {
                            deleteInbound().then(success => {
                                if (success) {
                                    debouncedLoadList();
                                }
                            }).finally(() => {
                                setDeleteMode(undefined);
                                setSelectedItems([]);
                            });
                        }
                        else {
                            setDeleteMode(undefined);
                        }
                    }} />}
            </Container>
        </div>
    </>;
}

const InboundListPage = forwardRef(List) as (
    props: RefAttributes<ProductListRef>
) => ReturnType<typeof List>;
export default InboundListPage;