/*
public static readonly Guid UPS = new("00000000-0000-0000-0000-000000000001");
public static readonly Guid USPS = new("00000000-0000-0000-0000-000000000002");
public static readonly Guid FedEx = new("00000000-0000-0000-0000-000000000003");
public static readonly Guid DHL = new("00000000-0000-0000-0000-000000000004");
public static readonly Guid Amazon = new("00000000-0000-0000-0000-000000000005");
public static readonly Guid Purolator = new("00000000-0000-0000-0000-000000000006");
public static readonly Guid APC = new("00000000-0000-0000-0000-000000000007");
*/

export const Carriers = {
    UPS: "00000000-0000-0000-0000-000000000001",
    USPS: "00000000-0000-0000-0000-000000000002",
    FedEx: "00000000-0000-0000-0000-000000000003",
    DHL: "00000000-0000-0000-0000-000000000004",
    Amazon: "00000000-0000-0000-0000-000000000005",
    Purolator: "00000000-0000-0000-0000-000000000006",
    APC: "00000000-0000-0000-0000-000000000007"
}