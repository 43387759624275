import { ListProductCategoriesQuery } from "api/types/queries";
import { CreateProductCategoryCommand, DeleteProductCategoryCommand, UpdateProductCategoryCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductCategoryContract } from "./types/contracts/products";

const api = new APIClient();

export const getProductCategoryList = async (data: ListProductCategoriesQuery): Promise<PagedList<ProductCategoryContract>> => {
    const result = await api.get(`/api/productcategory`, data);
    return result;
}

export const postProductCategory = async (data: CreateProductCategoryCommand): Promise<ProductCategoryContract | undefined> => {
    const response = await api.post(`/api/productcategory`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putProductCategory = async (data: UpdateProductCategoryCommand): Promise<void> => {
    await api.put(`/api/productcategory/${data.productCategoryId}`, { ...data.productCategory });
}

export const deleteProductCategory = async (data: DeleteProductCategoryCommand): Promise<void> => {
    await api.delete(`/api/productcategory/${data.productCategoryId}`);
}