import { TrackingStatus } from "api/types/contracts/inbound";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";


const TrackingStatusBadge = (props: StatusBadgeProps<TrackingStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const status = t(`TrackingStatus.${props.value}`);

    switch (props.value) {
        case "waiting": return <Badge color="warning">{status}</Badge>;
        case "completed": return <Badge color="success">{status}</Badge>;
    }
}

export default TrackingStatusBadge;