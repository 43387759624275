import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductContract } from "api/types/contracts/products";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type ProductLoadingStates = LoadingStates<"list" | "save" | "delete" | "search">;

export type ProductState = {
    list: Partial<PagedList<ProductContract>>,
    loading: ProductLoadingStates,
    error?: ApiError
}

export const initialState: ProductState = {
    list: {},
    loading: {
        list: false,
        save: false,
        delete: false,
        search: false
    }
};

const ProductSlice = createSlice({
    name: "ProductSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<ProductContract>>) {
            state.list = action.payload;
        },
        loading(state, action: PayloadAction<[keyof ProductLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    loading,
    apiError
} = ProductSlice.actions;

export default ProductSlice.reducer;
