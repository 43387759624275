import SelectInput from "Components/Form/SelectInput"
import { CarrierContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadCarrierAccountServices, loadCarriers } from "slices/carrier/thunk";

type CarrierSelectProps = (EntitySelectInputProps<CarrierContract, "carrierId"> & {
    userDefinedOnly?: false
}) | (EntitySelectInputProps<CarrierContract, "carrierId"> & {
    userDefinedOnly: true,
    shipmentTargetId: string
})

const CarrierSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CarrierSelectProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectProperties = createSelector(
        (state: RootState) => state.Carrier,
        (carrier) => ({
            carriers: rest.userDefinedOnly ? _
                    .chain(carrier.carrierAccountServices)
                    .map(cas => cas.carrierAccountService.carrierService.carrier)
                    .uniqBy(c => c.carrierId)
                    .value() 
                : 
                carrier.carriers,
            loading: carrier.loading.list
        })
    );

    const { carriers, loading } = useSelector(selectProperties);
    const carrierList = useMemo(() => carriers.map(c => ({ value: c.carrierId, label: c.name})), [carriers]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = carriers.filter(c => val.includes(c.carrierId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = carriers.find(c => c.carrierId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (rest.userDefinedOnly) {
            loadCarrierAccountServices({
                shipmentTargetId: rest.shipmentTargetId
            })(dispatch);
        }
        else {
            loadCarriers({})(dispatch);
        }
    }, [rest.userDefinedOnly]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (carriers.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carriers]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Carrier"),
        busy: loading,
        options: carrierList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierSelect;
