import { PrepServicePricingType } from "api/types/contracts/shipping";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const PrepServicePricingTypeMap: Record<PrepServicePricingType, { color: string, className: string }> = {
    "fixPrice": { color: "primary", className: "" },
    "perBox": { color: "warning",className: "" },
    "perSku": { color: "secondary", className: "" },
    "perUnit": { color: "danger",className: "" },
    "custom": { color: "light", className: "text-body" }
};

const PrepServicePricingTypeBadge = (props: StatusBadgeProps<PrepServicePricingType>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`PrepServicePricingType.${props.value}`);
    const { className, color } = PrepServicePricingTypeMap[props.value];

    return <Badge className={className} color={color}>{statusName}</Badge>
}

export default PrepServicePricingTypeBadge;