import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocationHistoryContract } from "api/types/contracts/inventory";
import { ApiError, LoadingStates } from "helpers/types";

type InventoryStateLoadingStates = LoadingStates<"location" | "putaway" | "list" | "historyUndo">;

export type InventoryState = {
    lastBinPutaways: LocationHistoryContract[],
    lastItemPutaways: LocationHistoryContract[],
    error: undefined | ApiError,
    loading: InventoryStateLoadingStates
}

export const initialState: InventoryState = {
    lastBinPutaways: [],
    lastItemPutaways: [],
    error: undefined,
    loading: {
        location: false,
        putaway: false,
        list: false,
        historyUndo: false
    }
};

const InventorySlice = createSlice({
    name: "InventorySlice",
    initialState,
    reducers: {
        loading(state, action: PayloadAction<[keyof InventoryStateLoadingStates, boolean]>) {
            const [key, value] = action.payload;
            state.loading[key] = value;

            if (value) {
                state.error = undefined;
            }
        },
        setPutawayBinHistory(state, action: PayloadAction<LocationHistoryContract[]>) {
            state.lastBinPutaways = action.payload;
        },
        setPutawayItemHistory(state, action: PayloadAction<LocationHistoryContract[]>) {
            state.lastItemPutaways = action.payload;
        },
        apiError(state, action: PayloadAction<ApiError | undefined>) {
            state.error = action.payload;
        }
    },
});

export const {
    setPutawayBinHistory,
    setPutawayItemHistory,
    loading,
    apiError
} = InventorySlice.actions;

export default InventorySlice.reducer;
