import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import Radiobox from "Components/Form/Radiobox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { ListWarehousesQueryForSelect } from "api/types/queries";
import classNames from "classnames";
import { ValidatableInputProps } from "helpers/types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Label } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadSelectList } from "slices/warehouse/thunk";
import { useImmer } from "use-immer";

type WarehouseGridSelectProps = ValidatableInputProps<string> & {
    onSelect?: (warehouse: WarehouseContract) => void,
    className?: string
};

const WarehouseGridSelect = (props: WarehouseGridSelectProps) => {
    const dispatch = useDispatch();
    const [query, updateQuery] = useImmer<ListWarehousesQueryForSelect>({
        search: ""
    });

    const selectProperties = createSelector(
        (state: RootState) => state,
        (state) => ({
            warehouses: state.Warehouse.selectList,
            loading: state.Warehouse.loading.selectList
        })
    );

    const { warehouses, loading } = useSelector(selectProperties);

    const debouncedLoadList = useDebounce(() => {
        loadSelectList(query)(dispatch);
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (warehouse: WarehouseContract) => {
        props.onChange?.(warehouse.warehouseId);
        props.onSelect?.(warehouse);
    };

    useEffect(() => {
        if (!props.value) {
            return;
        }
        const found = warehouses.find(w => w.warehouseId === props.value);
        
        if (found){
            onChange(found);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses, props.value]);

    return <>
        <div className={classNames("w-100 hstack overflow-x-auto overflow-y-hidden p-2 gap-3", props.className)}>
            {warehouses.map(w => <div key={w.warehouseId} style={{ width: "15%" }}>
                <Card body className="mb-0 border border-dark">
                    <Label check>
                        <Radiobox name={`warehose_${w.warehouseId}`} value={props.value === w.warehouseId} onChange={() => onChange(w)}>
                            <strong>{w.name}</strong>
                        </Radiobox>
                        <span className="fw-light d-inline-block mt-3">
                            <InlineAddressDisplay address={w.address} />
                        </span>
                    </Label>
                </Card>
            </div>)}
        </div>
    </>;
}

export default WarehouseGridSelect;