import { InputGroup, InputGroupText } from "reactstrap";
import NumberInput, { NumberInputProps } from "../NumberInput";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { UnitOfCurrency } from "api/types/contracts/common";

export type CurrencyInputProps = NumberInputProps & {
    currency?: UnitOfCurrency,
    groupClassName?: string
}

const CurrencyInput = ({ currency, className, groupClassName, size, ...rest }: CurrencyInputProps) => {
    const { userProfile } = useProfile();
    const { i18n } = useTranslation();

    const currencyCode = (currency ?? userProfile?.user.warehouse?.settings.unitOfCurrency ?? "usd").toUpperCase();

    const symbol = Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: currencyCode
    }).formatToParts(1).find(p => p.type === "currency")?.value ?? currencyCode;

    return <InputGroup size={size} className={classNames(groupClassName)}>
        <NumberInput {...rest} decimalPlaces={2} className={classNames("border-end-0", className)} />
        <InputGroupText className={classNames("bg-transparent", {
            "p-2": !size,
            "disabled": rest.disabled
        })}>
            {symbol}
        </InputGroupText>
    </InputGroup>
}

export default CurrencyInput;