import { createSlice } from "@reduxjs/toolkit";

export type ForgetPasswordState = {
  forgetSuccessMsg?: string,
  forgetError?: string
}

export const initialState: ForgetPasswordState = {
  forgetSuccessMsg: undefined,
  forgetError: undefined,
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
      userForgetPasswordSuccess(state, action) {
          state.forgetSuccessMsg = action.payload
      },
      userForgetPasswordError(state, action) {
          state.forgetError = action.payload
      },
  },
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer;
