import SelectInput from "Components/Form/SelectInput"
import { useDebounce } from "Components/Hooks/HelperHooks";
import { CompanyContract } from "api/types/contracts/companies";
import { ListCompaniesQueryForSelect } from "api/types/queries";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadSelectList } from "slices/company/thunk";
import { useImmer } from "use-immer";

type CompanySelectProps = EntitySelectInputProps<CompanyContract, "companyId"> & {
    
}

const CompanySelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CompanySelectProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListCompaniesQueryForSelect>({
        search: ""
    });

    const selectProperties = createSelector(
        (state: RootState) => state,
        (state) => ({
            companies: state.Company.selectList,
            loading: state.Company.loading.selectList
        })
    );

    const { companies, loading } = useSelector(selectProperties);
    const companyList = useMemo(() => companies.map(c => ({ value: c.companyId, label: c.name})), [companies]);

    const debouncedLoadList = useDebounce(() => {
        loadSelectList(query)(dispatch);
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = companies.filter(c => val.includes(c.companyId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = companies.find(c => c.companyId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.companyId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (companies.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies]);

    const selectProps = {
        ...rest,
        placeholder: t("Company"),
        busy: loading,
        options: companyList,
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CompanySelect;