import React from 'react';

//constants
import { LAYOUT_MODE_TYPES, LAYOUT_SIDEBAR_TYPES } from "../helpers/constants/layout";
import { createSelector } from 'reselect';
import { changeLayoutMode, changeSidebarTheme } from 'slices/thunks';
import { useDispatch, useSelector } from 'react-redux';

type LightDarkProps = {
    
}
const LightDark = (props : LightDarkProps) => {
    const dispatch: any = useDispatch();
    /*
    layout settings
    */
    const layoutModeTypeProperties = createSelector(
        (state : any) => state.Layout,
        (layout) => layout.layoutModeType
    );
    const layoutModeType = useSelector(layoutModeTypeProperties);
    /*
    call dark/light mode
    */
    const onChangeTheme = (value : LAYOUT_MODE_TYPES) => {
        dispatch(changeLayoutMode(value));
        dispatch(changeSidebarTheme(value === LAYOUT_MODE_TYPES['DARKMODE'] ? LAYOUT_SIDEBAR_TYPES.DARK : LAYOUT_SIDEBAR_TYPES.LIGHT));
    };
    
    const mode = layoutModeType === LAYOUT_MODE_TYPES['DARKMODE'] ? LAYOUT_MODE_TYPES['LIGHTMODE'] : LAYOUT_MODE_TYPES['DARKMODE'];

    return (
        <div className="ms-1 header-item d-none d-sm-flex">
            <button
                onClick={() => onChangeTheme(mode)}
                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i className='bx bx-moon fs-22'></i>
            </button>
        </div>
    );
};

export default LightDark;