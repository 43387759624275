import { APIClient } from "../helpers/api_helper";
import { AssignPutawayBinCommand, AssignPutawayItemCommand } from "./types/commands";
import { BinContract } from "./types/contracts/bin";
import { BinLocationContract } from "./types/contracts/locations";
import { SearchBinOrBinLocationQuery } from "./types/queries";

const api = new APIClient();

export const searchBinOrBinLocation = async (params: SearchBinOrBinLocationQuery): Promise<BinContract | BinLocationContract | undefined> => {
    const result = await api.get(`/api/inventory/search-bin/${params.label}`);
    return result;
}

export const listPutawayItemHistory = async () => {
    const result = await api.get("/api/inventory/putaway-items");
    return result;
}

export const listPutawayBinHistory = async () => {
    const result = await api.get("/api/inventory/putaway-bins");
    return result;
}

export const postPutawayItem = async (data: AssignPutawayItemCommand) => {
    const result = await api.post("/api/inventory/putaway-items", { ...data });
    return result;
}

export const postPutawayBin = async (data: AssignPutawayBinCommand) => {
    const result = await api.post("/api/inventory/putaway-bins", { ...data });
    return result;
}