import SelectInput from "Components/Form/SelectInput";
import { AllocationMethod } from "api/types/contracts/products";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const statusOptions: SelectOption<AllocationMethod>[] = [{
    value: "firstInFirstOut",
    label: "First In First Out (FIFO)"
}, {
    value: "lastInFirstOut",
    label: "Last In First Out (LIFO)"
}, {
    value: "firstExpireFirstOut",
    label: "First Expire First Out (FEFO)"
}];

const AllocationMethodSelect = (props: EnumSelectInput<AllocationMethod>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<AllocationMethod> isMulti value={value} onChange={onChange} {...rest} options={statusOptions} placeholder={t("Allocation Method")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={statusOptions} placeholder={t("Allocation Method")} />}
    </>;
};

export default AllocationMethodSelect;