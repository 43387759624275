import SelectInput from "Components/Form/SelectInput";
import { InboundStatus } from "api/types/contracts/inbound";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const inboundStatusOptions: SelectOption<InboundStatus>[] = [{
    label: "Draft",
    value: "draft"
}, {
    label: "Confirmed",
    value: "confirmed"
}, {
    label: "Checkin",
    value: "checkIn"
}, {
    label: "Receiving",
    value: "receiving"
}, {
    label: "Completed",
    value: "completed"
}];

const InboundStatusSelect = (props: EnumSelectInput<InboundStatus>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<InboundStatus> isMulti value={value} onChange={onChange} {...rest} options={inboundStatusOptions} placeholder={t("Inbound Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={inboundStatusOptions} placeholder={t("Inbound Status")} />}
    </>;
};

export default InboundStatusSelect;