import { ColumnDef } from "@tanstack/react-table";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import TableContainer, { TableContainerRef } from "Components/Common/TableContainer";
import { BinLocationContract } from "api/types/contracts/locations";
import { useRef, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Modal, ModalBody } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { reloadLocations, removeBinLocation } from "slices/location/thunk";
import UpdateBinLocationFormView from "./_UpdateBinLocationFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";

type ListProps = {
    binLocations: BinLocationContract[] | undefined
}

const List = (props: ListProps) => {
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [editModal, setEditModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState<BinLocationContract[]>([]);
    const binLocation = useMemo(() => selectedItems[0], [selectedItems]);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const selectProperties = createSelector(
        (state: RootState) => state.Location,
        (location) => ({
            ...location
        })
    );

    const { warehouse, loading } = useSelector(selectProperties);

    const reload = () => reloadLocations({ warehouseId: warehouse!.warehouseId })(dispatch);

    const deleteBinLocation = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            let success = false;
            for (const item of selectedItems) {
                success &&= await removeBinLocation({
                    binLocationId: item.binLocationId
                })(dispatch);
            }

            return success;
        }
        else if (deleteMode === "single" && binLocation) {
            return await removeBinLocation({
                binLocationId: binLocation.binLocationId
            })(dispatch);
        }

        return false;
    }, [binLocation, deleteMode, selectedItems, dispatch]);

    const handleEditClick = (arg: BinLocationContract) => {
        setSelectedItems([arg]);
        setEditModal(true);
    };

    const handleDeleteClick = (arg: BinLocationContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    // Column
    const columns = useMemo<ColumnDef<BinLocationContract, any>[]>(() => [
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return (
                    <div className="hstack gap-1">
                        <Button className="btn-ghost-dark px-1 py-0" onClick={() => handleEditClick(cell.row.original)}>
                            <i className="ri-pencil-fill fs-16"></i>
                        </Button>
                        <Button className="btn-ghost-danger px-1 py-0" onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </div>
                );
            },
        },
        {
            header: t("BIN LOCATION NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => {
                return <span># {cell.row.original.name}</span>;
            },
        },
        {
            header: t("WIDTH"),
            accessorFn: item => item.properties.width,
            enableColumnFilter: false,
        },
        {
            header: t("HEIGHT"),
            accessorFn: item => item.properties.height,
            enableColumnFilter: false,
        },
        {
            header: t("DEPTH"),
            accessorFn: item => item.properties.depth,
            enableColumnFilter: false,
        },
        {
            header: t("PRODUCTS"),
            enableColumnFilter: false,
            cell: (cell) => {
                <span></span>
            }
        }],
        [t]
    );

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode])

    return <>
        <Card body>
            <TableContainer
                ref={tableRef}
                columns={columns}
                nowrap
                data={props.binLocations || []}
                totalDataLength={props.binLocations?.length || 0}
                onSelectionChanged={selection => {
                    setSelectedItems(selection);
                }}
                divClass="mb-1"
                tableClass="align-middle"
            />
        </Card>
        {binLocation && <Modal isOpen={editModal} toggle={() => setEditModal(prev => !prev)} size="lg" unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setEditModal(false)} />
                <UpdateBinLocationFormView binLocation={binLocation} onSuccess={() => { 
                    setEditModal(false); 
                    reload(); 
                    }} />
            </ModalBody>
        </Modal>}
        {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={t(`Do you want to continue?`)} title={t("Deleting bin location '{{name}}'", { name: binLocation?.name, count: deleteMode === "multi" ? selectedItems.length : 1 })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteBinLocation().then(success => {
                        if (success) {
                            reload();
                        }
                    }).finally(() => {
                        setDeleteMode(undefined);
                        setSelectedItems([]);
                    });
                }
                else {
                    setDeleteMode(undefined);
                }
            }} />}
    </>;
}

export default List;