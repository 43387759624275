import { PackageInfoContract } from "api/types/contracts/carrier";
import Loader from "Components/Common/Loader";
import { useProfile } from "Components/Hooks/ProfileHooks";
import CarrierSelectTable from "Components/Shared/Shipment/CarrierSelectTable";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadFbaShipment, updateFbaShipment } from "slices/shipping/fbaShipping/thunk";

const CarrierInfo = () => {
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const selectProperties = createSelector(
        (state: RootState) => state.FbaShipping,
        (state) => ({
            fbaShipment: state.processingItem,
        })
    );
    
    // Inside your component
    const { fbaShipment } = useSelector(selectProperties);

    const boxes = useMemo(() => {
        if (!fbaShipment) {
            return [];
        }

        if (fbaShipment.packages.length === 0) {
            return fbaShipment.estimatedBoxes.map<PackageInfoContract>(p => ({
                height: p.height,
                length: p.length,
                width: p.width,
                weight: p.weight,
                lengthUnit: p.lengthUnit,
                weightUnit: p.weightUnit,
                currency: fbaShipment.warehouse.settings.unitOfCurrency ?? "usd",
                value: 0,
                items: [],
                shipmentTargetId: fbaShipment.shipmentTarget.shipmentTargetId,
                identifier: `Package ${p.boxNumber}`
            }));
        }
        else {
            return fbaShipment.packages.map<PackageInfoContract>(p => ({
                height: p.box.height,
                length: p.box.length,
                width: p.box.width,
                weight: p.box.weight,
                lengthUnit: p.box.lengthUnit,
                weightUnit: p.box.weightUnit,
                currency: fbaShipment.warehouse.settings.unitOfCurrency ?? "usd",
                value: p.value ?? 0,
                items: p.box.products.map(p => ({
                    productId: p.productId,
                    quantity: p.count
                })),
                shipmentTargetId: p.shipmentTargetId ?? fbaShipment.shipmentTarget.shipmentTargetId,
                identifier: `Package ${p.box.boxNumber}`
            }));
        }
    }, [fbaShipment]);

    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    const staticMode = fbaShipment.owner.userId !== userProfile?.user.userId || ["paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status);
    const disabled = fbaShipment.owner.userId !== userProfile?.user.userId || ["paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status);
    
    return <>
        <Card>
            <CardBody>
                <div className="table-responsive table-card">
                    <CarrierSelectTable value={fbaShipment.carrierAccountService.carrierAccountServiceId} 
                        shipmentTargetId={fbaShipment.shipmentTarget.shipmentTargetId} 
                        originWarehouseId={fbaShipment.warehouse.warehouseId}
                        boxes={boxes} 
                        disableAutoRates
                        disabled={disabled}
                        static={staticMode}
                        initialRates={{
                            [fbaShipment.carrierAccountService.carrierAccountServiceId]: fbaShipment.shipmentCharges
                        }}
                        onSelect={val => {
                            updateFbaShipment({
                                carrierAccountServiceId: val.carrierAccountService.carrierAccountServiceId,
                                fbaShipmentId: fbaShipment.fbaShipmentId,
                                actualCost: {
                                    ...fbaShipment.actualCost,
                                    shipping: val.rates?.shippingCost ?? 0,
                                    import: val.rates?.importTaxCost ?? 0,
                                    insurance: val.rates?.insuranceCost ?? 0,
                                    oversize: val.rates?.oversizeCost ?? 0,
                                    overweight: val.rates?.overweightCost ?? 0,
                                },
                                shipmentCharges: {
                                    totalCost: val.rates?.totalCost ?? 0,
                                    shippingCost: val.rates?.shippingCost ?? 0,
                                    importTaxCost: val.rates?.importTaxCost ?? 0,
                                    insuranceCost: val.rates?.insuranceCost ?? 0,
                                    oversizeCost: val.rates?.oversizeCost ?? 0,
                                    overweightCost: val.rates?.overweightCost ?? 0,
                                    details: val.rates?.details ?? {}
                                }
                            })(dispatch).then(() => {
                                loadFbaShipment({
                                    fbaShipmentId: fbaShipment.fbaShipmentId
                                })(dispatch);
                            });
                        }} />
                </div>
            </CardBody>
        </Card>
    </>;
}

export default CarrierInfo;