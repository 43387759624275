import { UnitOfLength, UnitOfWeight } from "api/types/contracts/common";

type Dimensions = {
    length: number,
    width: number,
    height: number,
}

const lengthConversionTable: Record<UnitOfLength, Record<UnitOfLength, number>> = {
    "cm": {
        "cm": 1,
        "inch": 0.393701,
    },
    "inch": {
        "cm": 2.54,
        "inch": 1
    },
}

const weightConversionTable: Record<UnitOfWeight, Record<UnitOfWeight, number>> = {
    "kg": {
        "kg": 1,
        "lbs": 2.20462,
    },
    "lbs": {
        "kg": 0.453592,
        "lbs": 1
    },
}

export const convertLength = (value: number, from: UnitOfLength, to: UnitOfLength): number => {
    return Math.round(value * lengthConversionTable[from][to] * 100) / 100;
}

export const convertDimensions = <T extends Dimensions>(value: T, from: UnitOfLength, to: UnitOfLength): T => {
    return {
        ...value,
        length: convertLength(value.length, from, to),
        width: convertLength(value.width, from, to),
        height: convertLength(value.height, from, to),
    };
}

export const convertWeight = (value: number, from: UnitOfWeight, to: UnitOfWeight): number => {
    return Math.round(value * weightConversionTable[from][to] * 100) / 100;
}