import SelectInput from "Components/Form/SelectInput";
import { UnitOfCurrency } from "api/types/contracts/common";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const weightOptions: SelectOption<UnitOfCurrency>[] = [{
    label: "US Dollar ($)",
    value: "usd"
}, {
    label: "Euro (€)",
    value: "eur"
}, {
    label: "British Pound (£)",
    value: "gbp"
}, {
    label: "Australian Dollar (A$)",
    value: "aud"
}, {
    label: "Canadian Dollar (C$)",
    value: "cad"
}, {
    label: "Japanese Yen (¥)",
    value: "jpy"
}, {
    label: "Chinese Yuan (¥)",
    value: "cny"
}, {
    label: "New Zealand Dollar (NZ$)",
    value: "nzd"
}];

const UnitOfCurrencySelect = (props: EnumSelectInput<UnitOfCurrency>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<UnitOfCurrency> isMulti value={value} onChange={onChange} {...rest} options={weightOptions} placeholder={t("Currency")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={weightOptions} placeholder={t("Currency")} />}
    </>;
};

export default UnitOfCurrencySelect;