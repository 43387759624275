import SearchBox from "Components/Form/SearchBox";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ProductCard from "./_ProductCard";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import { InboundItemContract } from "api/types/contracts/inbound";

type ProductGridProps = {
    items: InboundItemContract[],
    onProductSelected: (product: InboundItemContract) => void
};

const ProductGrid = ({ items, onProductSelected }: ProductGridProps) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");

    // const selectProperties = createSelector(
    //     (state: RootState) => state.Receiving,
    //     (receiving) => ({
    //         processedEntry: receiving.processedEntry!,
    //         selectedBox: receiving.selectedBox,
    //         loading: receiving.loading
    //     }));
        
    // const { processedEntry, selectedBox, loading } = useSelector(selectProperties);
    

    return <>
        <Card>
            <CardHeader>
                <SearchBox placeholder={t("Quick Search")} value={search} onChange={setSearch} />
            </CardHeader>
            <CardBody>
                <Row>
                    {items.map(item => <React.Fragment key={item.product.productId}>
                        <Col xs={12} sm={6} md={4}>
                            <ProductCard item={item} selectable onSelect={onProductSelected} />
                        </Col>
                    </React.Fragment>)}
                </Row>
            </CardBody>
        </Card>
    </>;
}

export default ProductGrid;