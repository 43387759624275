import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import { useProfile } from "Components/Hooks/ProfileHooks";
import ShipmentCostBreakdownView from "Components/Shared/ShipmentCostBreakdownView";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import _ from "lodash";
import { loadFbaShipment, updateFbaShipment } from "slices/shipping/fbaShipping/thunk";
import CarrierSelectTable from "Components/Shared/Shipment/CarrierSelectTable";
import { PackageInfoContract } from "api/types/contracts/carrier";
import { Card, CardBody } from "reactstrap";
import { useMemo } from "react";

const CostBreakdown = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { canWrite, userProfile } = useProfile();
    const selectProperties = createSelector(
        (state: RootState) => state.FbaShipping,
        (state) => ({
            fbaShipment: state.processingItem,
        })
    );
    
    // Inside your component
    const { fbaShipment } = useSelector(selectProperties);

    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    return <>
        <ShipmentCostBreakdownView value={fbaShipment.actualCost ?? fbaShipment.estimatedCost} 
            currency={fbaShipment.warehouse.settings.unitOfCurrency}
            warehouseId={fbaShipment.warehouse.warehouseId} canAdd={canWrite("shipping.fbashipping")} canEdit={canWrite("shipping.fbashipping")}
            onChange={cost => {
                updateFbaShipment({
                    fbaShipmentId: fbaShipment.fbaShipmentId,
                    actualCost: cost
                })(dispatch).then(() => {
                    loadFbaShipment({
                        fbaShipmentId: fbaShipment.fbaShipmentId
                    })(dispatch);
                });
            }}
            />
        {fbaShipment.owner.userId === userProfile?.user.userId && <div className="w-75 mx-auto">
            <InfoAlert>{t("paragraphs:FbaShipmentInvoiceDescription")}</InfoAlert>
        </div>}
    </>;

}

export default CostBreakdown;