import { ProductContract } from "api/types/contracts/products";
import { Badge } from "reactstrap";

type ProductIdentifiersDisplayProps = {
    product: ProductContract,
    noAsin?: boolean,
    noUpc?: boolean,
    noEan?: boolean
};

export const ProductIdentifiersHeader = (props: Omit<ProductIdentifiersDisplayProps, "product">) => {
    return <div className="hstack">
        {!props.noAsin && <Badge color="dark-subtle" className="text-body rounded-end-0">ASIN</Badge>}
        {!props.noUpc && <Badge color="info-subtle" className="text-info rounded-end-0 rounded-start-0">UPC</Badge>}
        {!props.noEan && <Badge color="danger-subtle" className="text-danger rounded-start-0">EAN</Badge>}
    </div>;
};

const ProductIdentifiersDisplay = ({ product, noAsin, noEan, noUpc } : ProductIdentifiersDisplayProps) => {
    return <div className="vstack gap-1">
        {!noAsin && product.asin && <Badge color="dark-subtle" className="text-body user-select-all">{product.asin}</Badge>}
        {!noUpc && product.upc && <Badge color="info-subtle" className="text-info user-select-all">{product.upc}</Badge>}
        {!noEan && product.ean && <Badge color="danger-subtle" className="text-danger user-select-all">{product.ean}</Badge>}
    </div>;
}

export default ProductIdentifiersDisplay;