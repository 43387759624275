import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InboundSearch from "./_InboundSearch";
import CustomerSearch from "./_CustomerSearch";
import LastReceivings from "./_LastReceivings";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import ShippingPurposeSelect from "Components/EnumSelects/ShippingPurposeSelect";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { selectShippingPurposeForGateEntry, selectWarehouseForGateEntry } from "slices/receivings/thunk";

type Step1Props = {
    onComplete(): void
};

const Step1 = ({ onComplete }: Step1Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [searchMode, setSearchMode] = useState<"inbound" | "customer">("inbound");
    const [createReceivingModalOpen, setCreateReceivingModalOpen] = useState(false);
    const { userProfile } = useProfile();

    const toggleCreateReceivingModal = () => setCreateReceivingModalOpen(prev => !prev);

    const selectProperties = createSelector(
        (state: RootState) => state.Receiving,
        (receiving) => ({
            loading: receiving.loading,
            inbound: receiving.gateEntry.inbound,
            customer: receiving.gateEntry.customer,
            receiving: receiving.gateEntry.receiving,
            error: receiving.error,
        })
    );

    // Inside your component
    const { loading, inbound, customer, receiving } = useSelector(selectProperties);

    useEffect(() => {
        if (receiving) {
            onComplete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiving]);

    const createReceiving = () => {
        if (searchMode === "customer") {
            toggleCreateReceivingModal();
        }
        else if (searchMode === "inbound" && !userProfile?.user.warehouse?.warehouseId) {
            toggleCreateReceivingModal();
        }
        else {
            onComplete();
        }
    }

    return <>
        <div>
            <Card>
                <CardHeader>
                    {searchMode === "inbound" && <h5 className="mb-0">{t("Inbound")}</h5>}
                    {searchMode === "customer" && <h5 className="mb-0">{t("Customer")}</h5>}
                </CardHeader>
                <CardBody>
                    {searchMode === "inbound" && <>
                        <InboundSearch onCustomerSearchRequested={() => setSearchMode("customer")} />
                    </>}
                    {searchMode === "customer" && <>
                        <CustomerSearch onInboundSearchRequested={() => setSearchMode("inbound")} />
                    </>}
                </CardBody>
                <CardFooter>
                    <div className="d-flex justify-content-end">
                        <Button color="primary" className="ms-auto" disabled={inbound === undefined && customer === undefined} onClick={createReceiving}>
                            {t("Create New Receiving Entry")}
                        </Button>
                    </div>
                </CardFooter>
            </Card>
            <Card>
                <CardHeader>
                    <h5 className="mb-0">{t("Last Receivings")}</h5>
                </CardHeader>
                <CardBody>
                    <LastReceivings />
                </CardBody>
            </Card>
        </div>
        <Modal isOpen={createReceivingModalOpen} toggle={toggleCreateReceivingModal}>
            <ModalCloseButton onClick={toggleCreateReceivingModal} />
            <ModalHeader>
                {t("Create New Receiving Entry")}
            </ModalHeader>
            <ModalBody>
                <FormGroup row className="align-items-center">
                    <Col xs={4}>
                        {t("Warehouse")}
                    </Col>
                    <Col xs={8}>
                        <WarehouseSelect value={userProfile?.user.warehouse?.warehouseId} onSelect={warehouse => selectWarehouseForGateEntry(warehouse)(dispatch)} />
                    </Col>
                </FormGroup>
                {searchMode === "customer" && <FormGroup row className="align-items-center">
                    <Col xs={4}>
                        {t("Shipping Purpose")}
                    </Col>
                    <Col xs={8}>
                        <ShippingPurposeSelect onChange={val => selectShippingPurposeForGateEntry(val)(dispatch)} />
                    </Col>
                </FormGroup>}
                <FormGroup row>
                    <Col sm="12">
                        <Button color="primary" className="ms-auto" onClick={onComplete}>
                            {t("Create")}
                        </Button>
                    </Col>
                </FormGroup>
            </ModalBody>
        </Modal>
    </>;
};

export default Step1;