import SelectInput from "Components/Form/SelectInput";
import { UnitOfMeasurement } from "api/types/contracts/common";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const measurementList: SelectOption<UnitOfMeasurement>[] = [{
    label: "Item",
    value: "item"
},
{
    label: "Box",
    value: "box"
},
{
    label: "Carton",
    value: "carton"
},
{
    label: "Case",
    value: "case"
},
{
    label: "Packet",
    value: "packet"
},
{
    label: "Bag",
    value: "bag"
},
{
    label: "Bottle",
    value: "bottle"
},
{
    label: "Can",
    value: "can"
},
{
    label: "Jar",
    value: "jar"
},
{
    label: "Drum",
    value: "drum"
},
{
    label: "Roll",
    value: "roll"
},
{
    label: "Tube",
    value: "tube"
},
{
    label: "Pallet",
    value: "pallet"
},
{
    label: "Layer",
    value: "layer"
},
{
    label: "Rack",
    value: "rack"
},
{
    label: "Bin",
    value: "bin"
},
{
    label: "Liter",
    value: "liter"
},
{
    label: "Kilogram",
    value: "kilogram"
},
{
    label: "Pound",
    value: "pound"
},
{
    label: "Ounce",
    value: "ounce"
},
{
    label: "Dozen",
    value: "dozen"
},
{
    label: "Pair",
    value: "pair"
},
{
    label: "Set",
    value: "set"
},
{
    label: "Bundle",
    value: "bundle"
}];

const UnitOfMeasurementSelect = (props: EnumSelectInput<UnitOfMeasurement>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<UnitOfMeasurement> isMulti value={value} onChange={onChange} {...rest} options={measurementList} placeholder={t("Measurement Unit")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={measurementList} placeholder={t("Measurement Unit")} />}
    </>;
};

export default UnitOfMeasurementSelect;