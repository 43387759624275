import { APIClient } from "../helpers/api_helper";
import { CreateAreaCommand, DeleteAreaCommand, UpdateAreaCommand } from "./types/commands";
import { AreaContract } from "./types/contracts/locations";
import { ListAreasQuery } from "./types/queries";

const api = new APIClient();

export const getAreaList = async (data: ListAreasQuery): Promise<AreaContract[]> => {
    const result = await api.get(`/api/area`, data);
    return result;
}

export const postArea = async (data: CreateAreaCommand): Promise<void> => {
    await api.post(`/api/area`, data);
}

export const putArea = async (data: UpdateAreaCommand): Promise<void> => {
    await api.put(`/api/area/${data.areaId}`, { ...data.area });
}

export const deleteArea = async (data: DeleteAreaCommand): Promise<void> => {
    await api.delete(`/api/area/${data.areaId}`);
}