import { APIClient, PagedList } from "helpers/api_helper";
import { GetReceivingEntryQuery, LatestReceivingEntriesQuery, ListReceivingEntriesQuery } from "./types/queries";
import { ReceivingEntryContract } from "./types/contracts/receivings";
import { AddReceivingBoxCommand, AddReceivingDocumentCommand, AssignRecevingProductsCommand, CreateReceivingEntryCommand, CreateReceivingProductLabelsCommand, FinalizeReceivingCommand, RemoveProductLabelAssignmentCommand, RemoveReceivingBoxCommand, ResumeReceivingCommand, SaveReceivingProductStockCommand } from "./types/commands";

const api = new APIClient();

export const getReceivingEntry = async (data: GetReceivingEntryQuery): Promise<ReceivingEntryContract> => {
    const result = await api.get(`/api/receiving/${data.receivingEntryId}`);
    return result;
}

export const getLatestReceivingEntries = async (data: LatestReceivingEntriesQuery): Promise<ReceivingEntryContract[]> => {
    const result = await api.get(`/api/receiving/latest`, data);
    return result;
}

export const getReceivingEntriesList = async (data: ListReceivingEntriesQuery): Promise<PagedList<ReceivingEntryContract>> => {
    const result = await api.get(`/api/receiving`, data);
    return result;
}

export const postReceivingEntry = async (data: CreateReceivingEntryCommand): Promise<ReceivingEntryContract | undefined> => {
    const response = await api.post(`/api/receiving`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const patchReceivingEntryBox = async (data: AddReceivingBoxCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/box`, { box: data.box, boxQuantity: data.boxQuantity });
}

export const patchReceivingEntryDocument = async (data: AddReceivingDocumentCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/document`, { ...data.document });
}

export const postReceivingProductLabels = async (data: CreateReceivingProductLabelsCommand): Promise<string[]> => {
    const response = await api.post(`/api/receiving/${data.receivingEntryId}/labels`, data);

    return response.data;
}

export const patchAssignProductLabel = async (data: AssignRecevingProductsCommand): Promise<void> => {
    const { serialNumber, ...rest } = data;
    await api.patch(`/api/receiving/label/${serialNumber}/assign`, rest);
}

export const patchSaveStocks = async (data: SaveReceivingProductStockCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/box/${data.receivingBoxId}/stocks`, data.stocks);
}

export const deleteReceivingProductLabel = async (data: RemoveProductLabelAssignmentCommand): Promise<void> => {
    await api.delete(`/api/receiving/label/${data.serialNumber}`);
}

export const patchFinalizeReceivingEntry = async (data: FinalizeReceivingCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/finalize`, {});
}

export const patchResumeReceivingEntry = async (data: ResumeReceivingCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/resume`, {});
}

export const deleteReceivingBoxEntry = async (data: RemoveReceivingBoxCommand): Promise<void> => {
    await api.delete(`/api/receiving/${data.receivingEntryId}/box/${data.receivingBoxId}`);
}