import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import Restricted from "Components/Common/Restricted";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Unauthorized from "pages/Errors/_Unauthorized";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container, Modal, ModalBody } from "reactstrap";
import OversizeCarrierRuleList, { OversizeCarrierRuleListRef } from "./_List";
import EditForm from "./_EditForm";
import { CarrierOversizeRuleContract } from "api/types/contracts/carrier";

type OversizeCarrierRulePageProps = {
    edit?: boolean
}

const OversizeCarrierRulePage = (props: OversizeCarrierRulePageProps) => {
    const loaderData = useLoaderData() as { oversizeRule: Promise<CarrierOversizeRuleContract>};
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<OversizeCarrierRuleListRef>(null);

    return <Restricted require="management.carrier" throw fallback={() => <Unauthorized />}>
            <div className="page-content">
                <Container fluid>
                    <TitleBreadcrumb active={t("Oversize Rules")} parents={[t("Management"), t("Carrier Rules")]} />
                    <OversizeCarrierRuleList ref={listRef} />
                    {props.edit && 
                        <Modal isOpen toggle={() => navigate("/carrier-rules/oversize-rules")} unmountOnClose>
                            <ModalBody>
                                <ResolveWrapper promise={loaderData?.oversizeRule}>
                                    {(oversizeRule) => <>
                                        <ModalCloseButton onClick={() => navigate("/carrier-rules/oversize-rules")} />
                                        <EditForm oversizeRule={oversizeRule} onSuccess={() => {
                                            navigate("/carrier-rules/oversize-rules");
                                            listRef.current?.reload();
                                        }} />
                                    </>}
                                </ResolveWrapper>
                            </ModalBody>
                        </Modal>
                    }
                </Container>
            </div>
        </Restricted>;
};

export default OversizeCarrierRulePage;