import ClipboardCopy from "Components/Common/ClipboardCopy";
import { AddressContract } from "api/types/contracts/common";
import classNames from "classnames";

type AddressDisplayProps = {
    warehouseName: string,
    address: AddressContract,
    suitNumber: string,
    inboundCode: string,
    className?: string,
}

const AddressDisplay = ({ warehouseName, address, inboundCode, suitNumber, className }: AddressDisplayProps) => {
    return <div className={classNames("vstack gap-2", className)}>
        <div>
            {warehouseName} <ClipboardCopy text={warehouseName} />
        </div>
        <div>
            {`${address.address1} # ${suitNumber}`} <ClipboardCopy text={`${address.address1} # ${suitNumber}`} />
        </div>
        <div>
            {`# ${inboundCode}`}<ClipboardCopy text={`# ${inboundCode}`} />
        </div>
        <div>
            {address.city && <>{address.city}<ClipboardCopy text={address.city} />&nbsp;</>}
            {address.state && <>{address.state}<ClipboardCopy text={address.state} />&nbsp;</>}
            {address.zipCode && <>{address.zipCode}<ClipboardCopy text={address.zipCode} />&nbsp;</>}
        </div>
        {address.country && <div>
            {address.country.name} <ClipboardCopy text={address.country.name} />
        </div>}
        {address.phone && <div>
            {address.phone} <ClipboardCopy text={address.phone} />
        </div>}
    </div>
};

export default AddressDisplay;