export const inputKeyEventHandler = (key: string, callback: (e: React.KeyboardEvent<HTMLInputElement>) => void) => {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === key) {
            callback(e);
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        else {
            return true;
        }
    };
};