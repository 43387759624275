import TitleBreadcrumb from "Components/Common/TitleBreadcrumb"
import Loader from "Components/Common/Loader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Await, useLoaderData } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import Overview from "./_Overview";
import { InboundContract } from "api/types/contracts/inbound";
import classNames from "classnames";
import Trackings from "./_Trackins";
import Boxes from "./_Boxes";
import Products from "./_Products";
import Documents from "./_Documents";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { ReceivingEntryContract } from "api/types/contracts/receivings";
import Problems from "./_Problems";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import { setProcessingItem } from "slices/inbound/reducer";
import Restricted from "Components/Common/Restricted";
import Receivings from "./_Receivings";

const InboundDetailPage = () => {
    const dispatch = useDispatch();
    const loaderData = useLoaderData() as any;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const { setPageTitle } = useMetadata();

    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (state) => ({
            stateInbound: state.processingItem,
            loading: state.loading
        })
    );
    
    // Inside your component
    const { stateInbound, loading } = useSelector(selectProperties);
    
    setPageTitle(`${t("Inbound Detail")} - ${t("Inbounds")}`);

    return <>
        <div className="page-content">
            <Container fluid>
                <ResolveWrapper promise={loaderData?.inbound}>
                    {(resolvedInbound: InboundContract) => {
                        if (!stateInbound || stateInbound.inboundId !== resolvedInbound.inboundId) {
                            dispatch(setProcessingItem(resolvedInbound));
                        }

                        const inbound = stateInbound ?? resolvedInbound;

                        return <>
                            <TitleBreadcrumb active={`View #${inbound.inboundCode}`} parents={[t("Receving"), { title: t("Inbounds"), to: "/inbounds" }]} />
                            <Row>
                                <Col>
                                    <Overview onViewContentsClick={() => setActiveTab(1)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <Nav pills className="mb-3 nav-success">
                                                <NavItem>
                                                    <NavLink className={classNames("cursor-pointer", { "active": activeTab === 1, })} onClick={() => setActiveTab(1)}>
                                                        {t("Products")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classNames("cursor-pointer", { "active": activeTab === 2, })} onClick={() => setActiveTab(2)}>
                                                        {t("Track Shipments")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classNames("cursor-pointer", { "active": activeTab === 3, })} onClick={() => setActiveTab(3)}>
                                                        {t("Box Contents")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classNames("cursor-pointer", { "active": activeTab === 4, })} onClick={() => setActiveTab(4)}>
                                                        {t("Documents")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classNames("cursor-pointer", { "active": activeTab === 5, })} onClick={() => setActiveTab(5)}>
                                                        {t("Problems")}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <Products />
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <Trackings />
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <React.Suspense fallback={<Loader height="200px" />}>
                                                        <Await resolve={loaderData?.receivings}>
                                                            {(resolvedReceivings: ReceivingEntryContract[]) => <>
                                                                <Boxes receivings={resolvedReceivings} />
                                                            </>}
                                                        </Await>
                                                    </React.Suspense>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <React.Suspense fallback={<Loader height="200px" />}>
                                                        <Await resolve={loaderData?.receivings}>
                                                            {(resolvedReceivings: ReceivingEntryContract[]) => <>
                                                                <Documents receivings={resolvedReceivings} />
                                                            </>}
                                                        </Await>
                                                    </React.Suspense>
                                                </TabPane>
                                                <TabPane tabId={5}>
                                                    <Problems />
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                    <Restricted require="receiving.inbound" write>
                                        <h5>{t("Receivings")}</h5>
                                        <Card>
                                            <CardBody>
                                                <React.Suspense fallback={<Loader height="200px" />}>
                                                    <Await resolve={loaderData?.receivings}>
                                                        {(resolvedReceivings: ReceivingEntryContract[]) => <>
                                                            <Receivings receivings={resolvedReceivings} />
                                                        </>}
                                                    </Await>
                                                </React.Suspense>
                                            </CardBody>
                                        </Card>
                                    </Restricted>
                                </Col>
                            </Row>
                        </>;
                    }}
                </ResolveWrapper>
            </Container>
        </div>
    </>
};

export default InboundDetailPage;