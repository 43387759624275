import SelectInput from "Components/Form/SelectInput";
import { UnitOfWeight } from "api/types/contracts/common";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const weightOptions: SelectOption<UnitOfWeight>[] = [{
    label: "lbs",
    value: "lbs"
}, {
    label: "kg",
    value: "kg"
}];

const UnitOfWeightSelect = (props: EnumSelectInput<UnitOfWeight>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<UnitOfWeight> isMulti value={value} onChange={onChange} {...rest} options={weightOptions} placeholder={t("Weight Unit")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={weightOptions} placeholder={t("Weight Unit")} />}
    </>;
};

export default UnitOfWeightSelect;