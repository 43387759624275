import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CarrierImportTaxPricingRuleContract, CarrierInsuranceRuleContract, CarrierOversizeRuleContract, CarrierOverweightRuleContract } from "api/types/contracts/carrier";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type CarrierRuleLoadingStates = LoadingStates<"list" | "save" | "delete">;

export type CarrierRuleState = {
    importTaxPricing: PagedList<CarrierImportTaxPricingRuleContract> | undefined,
    insurance: PagedList<CarrierInsuranceRuleContract> | undefined,
    oversize: PagedList<CarrierOversizeRuleContract> | undefined,
    overweight: PagedList<CarrierOverweightRuleContract> | undefined,
    loading: {
        importTaxPricing: CarrierRuleLoadingStates,
        insurance: CarrierRuleLoadingStates,
        oversize: CarrierRuleLoadingStates,
        overweight: CarrierRuleLoadingStates
    },
    error?: ApiError
}

export const initialState: CarrierRuleState = {
    importTaxPricing: undefined,
    insurance: undefined,
    oversize: undefined,
    overweight: undefined,
    loading: {
        insurance: {
            list: false,
            save: false,
            delete: false
        },
        importTaxPricing: {
            list: false,
            save: false,
            delete: false
        },
        oversize: {
            list: false,
            save: false,
            delete: false
        },
        overweight: {
            list: false,
            save: false,
            delete: false
        }
    }
};

const CarrierRuleSlice = createSlice({
    name: "CarrierRuleSlice",
    initialState,
    reducers: {
        setOversizeRules(state, action: PayloadAction<PagedList<CarrierOversizeRuleContract>>) {
            state.oversize = action.payload;
        },
        setInsuranceRules(state, action: PayloadAction<PagedList<CarrierInsuranceRuleContract>>) {
            state.insurance = action.payload;
        },
        setImportTaxPricingRules(state, action: PayloadAction<PagedList<CarrierImportTaxPricingRuleContract>>) {
            state.importTaxPricing = action.payload;
        },
        setOverweightRules(state, action: PayloadAction<PagedList<CarrierOverweightRuleContract>>) {
            state.overweight = action.payload;
        },
        loading(state, action: PayloadAction<[keyof CarrierRuleState["loading"], keyof CarrierRuleLoadingStates, boolean]>) {
            const [loadingKey, operation, loadingState] = action.payload;
            state.loading[loadingKey][operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setOversizeRules,
    setInsuranceRules,
    setImportTaxPricingRules,
    setOverweightRules,
    loading,
    apiError
} = CarrierRuleSlice.actions;

export default CarrierRuleSlice.reducer;
