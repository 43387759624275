import { AnyAction, Dispatch } from "redux";
import { setList, loading, apiError, setSelectList } from "./reducer";
import { GetCompanyQuery, ListCompaniesQuery, ListCompaniesQueryForSelect } from "api/types/queries";
import { postCompany, getCompanyList, putCompany, deleteCompany, getCompany, getCompanyListForSelect, patchCompanyStatus } from "api/company";
import { CreateCompanyCommand, DeleteCompanyCommand, SetCompanyStatusCommand, UpdateCompanyCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";

export const loadCompany = (params: GetCompanyQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await getCompany(params);
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const loadList = (params: ListCompaniesQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getCompanyList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const loadSelectList = (params: ListCompaniesQueryForSelect) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["selectList", true]));

        var result = await getCompanyListForSelect(params);
        
        dispatch(setSelectList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["selectList", false]));
    }
};

export const resetList = () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(setList({
        items: [],
        currentPage: 1,
        totalCount: 0
    }));
}

export const createCompany = (params: CreateCompanyCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await postCompany(params);
        toast.success(i18n.t("Company created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateCompany = (params: UpdateCompanyCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putCompany(params);
        toast.success(i18n.t("Company updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeCompany = (params: DeleteCompanyCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteCompany(params);
        toast.success(i18n.t("Company deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultipleCompanies = (params: DeleteCompanyCommand[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteCompany(item);
        }

        toast.success(i18n.t("Company deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const setCompanyStatus = (params: SetCompanyStatusCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["status", true]));

        await patchCompanyStatus(params);
        toast.success(i18n.t("Company status updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["status", false]));
    }
}

export const resetError = () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch(apiError(undefined));
}
