import { CustomerContract } from "api/types/contracts/customers"
import { useRef } from "react"
import { UncontrolledPopover } from "reactstrap"

type CustomerDisplayProps = {
    customer: CustomerContract
}

const CustomerDisplay = ({ customer }: CustomerDisplayProps) => {
    const divRef = useRef<HTMLDivElement>(null);

    return <>
        <div className="d-flex align-items-center cursor-pointer" ref={divRef} aria-haspopup>
            <div className="flex-shrink-0">
                <div className="avatar-xs">
                    <div className="avatar-title bg-tenant-primary-subtle text-tenant-primary rounded-circle fs-24">
                        <i className="ri-user-6-fill"></i>
                    </div>
                </div>
            </div>
            <div className="flex-grow-1 ms-2 name">{customer.user.name}</div>
        </div>
        <UncontrolledPopover target={divRef} placement="bottom" trigger="legacy">
            <div className="vstack gap-2 p-3">
                <span className="fw-bold">{customer.user.name}</span>
                <span className="text-muted">{customer.user.email}</span>
                <span className="fw-bold">{customer.phone}</span>
            </div>
        </UncontrolledPopover>
    </>
}

export default CustomerDisplay;