import { useTenant } from 'Components/Hooks/TenantHooks';
import Preloader from 'Components/Preloader';
import Offline from 'pages/Errors/Offline';
import { PropsWithChildren, useEffect } from 'react';

//redux
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { RootState } from 'slices';

const NonAuthLayout = ({ children }: PropsWithChildren) => {
    const { loadingState } = useTenant();
    const selectProperties = createSelector(
        (state: RootState) => state.Layout.layoutModeType,
        (layoutModeType) => layoutModeType
    );
    // Inside your component
    const layoutModeType = useSelector(selectProperties);

    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-bs-theme", "dark");
        } else {
            document.body.setAttribute("data-bs-theme", "light");
        }
        return () => {
            document.body.removeAttribute("data-bs-theme");
        };
    }, [layoutModeType]);

    return (<>
        {(loadingState === "loading" || loadingState === "notloaded") && <Preloader />}
        {loadingState === "failed" ? <Offline /> : 
        <div>
            {children}
        </div>}
    </>);
};

export default NonAuthLayout;