import { NumericRange } from "helpers/api_helper";
import { CSSProperties, useEffect, useState } from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import NumberInput from "./NumberInput";
import { ValidatableInputProps } from "helpers/types";

export type NumericRangeInputProps = ValidatableInputProps<NumericRange> & {
    className?: string,
    style?: CSSProperties
}

const NumericRangeInput = (props: NumericRangeInputProps) => {
    const [min, setMin] = useState(props.value?.min);
    const [max, setMax] = useState(props.value?.max);

    const onChange = props.onChange;
    useEffect(() => {
        onChange?.({ min, max });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [min, max]);

    return <>
        <InputGroup>
            <NumberInput name="min" placeholder="Min" value={min} onChange={setMin} className="border-end-0" />
            <InputGroupText className="bg-white px-0 text-body-secondary">
                <i className="ri-arrow-right-line"></i>
            </InputGroupText>
            <NumberInput name="max" placeholder="Max" value={max} onChange={setMax} className="border-start-0" />
        </InputGroup>
    </>;
};

export default NumericRangeInput;