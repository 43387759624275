import { AisleContract } from "api/types/contracts/locations";
import AisleItem from "./_AisleItem";

type GridProps = {
    aisles: AisleContract[]
}

const Grid = (props: GridProps) => {
    return (<>
        <div className="vstack gap-2">
            {props.aisles.map(aisle => <div key={aisle.aisleId}>
                <AisleItem aisle={aisle} />
            </div>)}
        </div>
    </>);
};

export default Grid;