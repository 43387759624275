import { Col, Row } from "reactstrap";
import { ApiFormsProps } from "./ApiFormsProps";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import TextInput from "Components/Form/TextInput";
import { useDebounce } from "Components/Hooks/HelperHooks";
import * as Yup from "yup";

const UpsApiForm = (props: ApiFormsProps) => {
    const { t } = useTranslation();

    let value = props.value ?? {};

    const validation = useFormik({
        initialValues: {
            accountNumber: value["accountNumber"] ?? "",
            clientId: value["clientId"] ?? "",
            clientSecret: value["clientSecret"] ?? ""
        },
        validationSchema: Yup.object({
            accountNumber: Yup.string().required(t("Account Number is required")),
            clientId: Yup.string().required(t("Client ID is required")),
            clientSecret: Yup.string().required(t("Client Secret is required"))
        }),
        onSubmit: (values) => {
            props.onChange?.(values);
        }
    });

    const submitDebounced = useDebounce(() => validation.isValid && validation.submitForm(), 200);

    return <Row className="g-2">
        <Col xs={12}>
            <RequiredLabel>{t("Account Number")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="accountNumber">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Client ID")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="clientId">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Client Secret")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="clientSecret">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
    </Row>;
}

export default UpsApiForm;