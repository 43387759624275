import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import UnitOfCurrencySelect from "Components/EnumSelects/UnitOfCurrencySelect";
import UnitOfLengthSelect from "Components/EnumSelects/UnitOfLengthSelect";
import UnitOfWeightSelect from "Components/EnumSelects/UnitOfWeightSelect";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import Radiobox from "Components/Form/Radiobox";
import SelectInput from "Components/Form/SelectInput";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { CarrierInsuranceRuleContract, CarrierInsuranceRuleForm } from "api/types/contracts/carrier";
import { RangeValueDefinitionContract, UnitOfCurrency, UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Label, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { createInsuranceRule, updateInsuranceRule } from "slices/carrierRules/thunk";
import * as Yup from "yup";

type FormProps = {
    insuranceRule?: CarrierInsuranceRuleContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<CarrierInsuranceRuleForm> = {
    name: "",
    currencyCode: undefined,
    priceRanges: [],
    valuePercent: 100,
    includeShippingCost: true
};

const createInsuranceRuleForm = (rule: CarrierInsuranceRuleContract | undefined): CarrierInsuranceRuleForm | undefined => {
    return rule ? {
        name: rule.name,
        currencyCode: rule.currencyCode,
        priceRanges: [...rule.priceRanges],
        valuePercent: rule.valuePercent,
        includeShippingCost: rule.includeShippingCost
    } as CarrierInsuranceRuleForm : undefined
};

const EditForm = (props: FormProps) => {
    const dispatch = useDispatch();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const ruleForm = useMemo(() => createInsuranceRuleForm(props.insuranceRule) || defaultForm, [props.insuranceRule]);
    const [useProductValue, setUseProductValue] = useState(true);

    setPageTitle(`${t(props.insuranceRule ? "Update Insurance Rule" : "Create Insurance Rule")} - ${t("Carrier Rules")} - ${t("Management")}`);

    const selectProperties = createSelector(
        (state: RootState) => state.CarrierRules,
        (state) => ({
            error: state.error,
            loading: state.loading.insurance
        })
    );

    const { error, loading } = useSelector(selectProperties);
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...ruleForm,
            currencyCode: ruleForm.currencyCode ?? userProfile?.user.warehouse?.settings.unitOfCurrency
        },
        validationSchema: Yup.object<CarrierInsuranceRuleForm, TypedShape<CarrierInsuranceRuleForm>>({
            name: Yup.string().required(t("Name is required")),
            currencyCode: Yup.string<UnitOfCurrency>().required(t("Currency is required")),
            valuePercent: Yup.number().min(0, t("Valid percentage required")).max(100, t("Valid percentage required")).required(t("Value percent is required")),
            includeShippingCost: Yup.boolean().required(t("Include shipping cost is required")),
            priceRanges: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Fee is required"))
            })).min(1, t("At least one range must be defined")).required(),
        }),
        onSubmit: async values => {
            const carrierInsuranceRule = values as CarrierInsuranceRuleForm;

            if (props.insuranceRule?.carrierInsuranceRuleId) {
                const success = await updateInsuranceRule({ 
                        carrierInsuranceRuleId: props.insuranceRule.carrierInsuranceRuleId, 
                        carrierInsuranceRule 
                })(dispatch);

                if (success) {
                    props.onSuccess();
                }
            }
            else {
                const result = await createInsuranceRule({
                    carrierInsuranceRule
                })(dispatch);

                if (result) {
                    props.onSuccess();
                }
            }
        },
    });

    const addNewPriceRangeRow = () => {
        validation.setFieldValue("priceRanges", [...(validation.values.priceRanges || []), {
            from: 0,
            to: 0
        }]);
    };

    const removePriceRangeRow = (i: number) => {
        const newRanges = validation.values.priceRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("priceRanges", newRanges);
    }

    useEffect(() => {
        if (ruleForm.priceRanges?.length === 0) {
            addNewPriceRangeRow();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleForm]);

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Container fluid>
                <Row>
                    <Col className="mb-2">
                        <h4>{t(props.insuranceRule ? "Edit Insurance Rule" : "Create Insurance Rule")}</h4>
                        <p className="mb-0 fs-10">{t("paragraphs:CarrierRulesInsuranceRuleDescription")}</p>
                    </Col>
                </Row>
                <Row className="g-4 mb-2">
                    <Col xs={12}>
                        <RequiredLabel>{t("Rule Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <Label>{t("Insurance Value")}</Label>
                        <p className="fs-10">{t("paragraphs:CarrierRulesInsuranceValueDescription")}</p>
                        <Radiobox value={useProductValue} onChange={val => {
                            if (val) {
                                setUseProductValue(!useProductValue);
                                validation.setFieldValue("valuePercent", 100);
                            }
                        }}>
                            {t("Use Product Value")}
                        </Radiobox>
                        <Radiobox value={!useProductValue} className="mt-1" onChange={() => {
                            setUseProductValue(!useProductValue);
                        }}>
                            <div className="hstack gap-2">
                                <span>{"Use"}</span>
                                <ValidationWrapper validation={validation} field="valuePercent">
                                    <NumberInput disabled={useProductValue} size="sm" />
                                </ValidationWrapper>
                                <span className="text-nowrap">{"of Product Value"}</span>
                            </div>
                        </Radiobox>
                    </Col>
                    <Col xs={12}>
                        <Label>{t("Include Shipping Cost in coverage?")}</Label>
                        <p className="fs-10">{t("paragraphs:CarrierRulesIncludeShippingCostDescription")}</p>
                        <ValidationWrapper validation={validation} field="includeShippingCost">
                            <SelectInput options={[
                                { value: true, label: t("$t(Yes) - $t(Cost of shipping will be covered in a claim) ($t(recommended))") },
                                { value: false, label: t("$t(No) - $t(Cover item value only)") }
                            ]} display="radio" />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <RequiredLabel>{t("Currency")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="currencyCode">
                            <UnitOfCurrencySelect />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col className="vstack gap-2">
                        <div className="hstack gap-3 text-center">
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Price Range")}
                            </div>
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Insurance Fee")} (%)
                            </div>
                        </div>
                        {validation.values.priceRanges?.map((range, i) => <React.Fragment key={i}>
                            <div className="hstack gap-3 align-items-center" key={i}>
                                <span className="small text-muted">{t("from")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`priceRanges.${i}.from`}>
                                        <CurrencyInput size="sm" placeholder="From" className="text-center" currency={validation.values.currencyCode} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("to")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`priceRanges.${i}.to`}>
                                        <CurrencyInput size="sm" placeholder="To" className="text-center" currency={validation.values.currencyCode} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("then")}</span>
                                <div style={{ flex: 3 }}>
                                    <ValidationWrapper validation={validation} field={`priceRanges.${i}.value`}>
                                        <NumberInput size="sm" placeholder="Fee" />
                                    </ValidationWrapper>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removePriceRangeRow(i)}>
                                        <i className="ri-delete-bin-5-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>)}
                        <div className="d-flex justify-content-end">
                            <Button size="sm" type="button" className="btn-ghost-info" onClick={addNewPriceRangeRow}>
                                {t("Add another")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default EditForm;