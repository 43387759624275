import { ReceivingEntryContract } from "api/types/contracts/receivings";
import BoxSelection from "./_BoxSelection";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import ProcessStorage from "./Storage/_ProcessStorage";
import ProcessFBA from "./FBA/_ProcessFBA";
import { useDispatch, useSelector } from "react-redux";
import { processReceivingEntry, selectBox } from "slices/receivings/thunk";
import { createSelector } from "reselect";
import { RootState } from "slices";
import Loader from "Components/Common/Loader";
import { useEffect } from "react";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useSearchParams } from "react-router-dom";

type ProcessProps = {
    receivingEntry: ReceivingEntryContract
};

const Process = ({ receivingEntry }: ProcessProps) => {
    const [params, setParams] = useSearchParams();
    const boxId = params.get("box");
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const dispatch = useDispatch();
    
    const selectProperties = createSelector(
        (state: RootState) => state.Receiving,
        (receiving) => ({
            selectedBox: receiving.selectedBox,
            processedEntry: receiving.processedEntry,
        })
    );

    setPageTitle(`${t("Process Receiving")} - ${t("Receivings")}`);
    
    // Inside your component
    const { selectedBox, processedEntry } = useSelector(selectProperties);

    useEffect(() => {
        processReceivingEntry(receivingEntry)(dispatch).then(() => {
            if (boxId) {
                const box = receivingEntry.boxes.find(b => b.receivingBoxId === boxId);

                if (box) {
                    selectBox(box)(dispatch);
                }
            }
        });
    }, [dispatch, receivingEntry]);

    if (!processedEntry) {
        return <Loader height="500px" />;
    }
    return <>
        {!selectedBox && <BoxSelection />}
        {selectedBox && <>
            <Card body>
                <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                        <strong>{t("Receiving ID")}</strong>
                        <span># {processedEntry.receivingNumber}</span>
                    </div>
                    <div className="d-flex gap-2">
                        <strong>{t("Selected Box")}</strong>
                        <span># {selectedBox.box.boxNumber}</span>
                    </div>
                    <div className="d-flex gap-2">
                        <strong>{t("Inbound ID")}</strong>
                        <span>{processedEntry.inbound ? <># {processedEntry.inbound.inboundCode}</> : "-"}</span>
                    </div>
                </div>
            </Card>
            {processedEntry.inbound?.shippingPurpose === "fba" ? <>
                <ProcessFBA />
            </> 
            : <>
                <ProcessStorage />
            </>}
        </>}
    </>;
}

export default Process;