import { APIClient } from "helpers/api_helper";
import { CancelFbaShipmentInvoiceQuery, GetFbaShipmentInvoiceQuery, PayFbaShipmentInvoiceQuery, RefundFbaShipmentInvoiceQuery } from "./types/queries";

const api = new APIClient();

export const getInvoice = async (data: GetFbaShipmentInvoiceQuery) => {
    const result = await api.get(`/api/invoice/${data.fbaShipmentInvoiceId}/`);
    return result;
}

export const postCancelInvoice = async (data: CancelFbaShipmentInvoiceQuery) => {
    const result = await api.post(`/api/invoice/${data.fbaShipmentInvoiceId}/cancel`, {});
    return result;
}

export const postPayInvoice = async (data: PayFbaShipmentInvoiceQuery) => {
    const result = await api.post(`/api/invoice/${data.fbaShipmentInvoiceId}/pay`, {});
    return result;
}

export const postRefundInvoice = async (data: RefundFbaShipmentInvoiceQuery) => {
    const result = await api.post(`/api/invoice/${data.fbaShipmentInvoiceId}/refund`, {});
    return result;
}