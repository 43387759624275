import { useState } from "react";
import FileUpload from "./Form/FileUpload";

import defaultAvatar from "assets/images/users/user-dummy-img.jpg";
import { useDispatch } from "react-redux";
import { updateAvatar } from "slices/user/thunk";
import { withCdn } from "helpers/urlHelper";
import { useTranslation } from "react-i18next";

export type AvatarUploadProps = {
    userId: string,
    avatar?: string,
    onChange?: VoidFunction
}

const AvatarUpload = (props: AvatarUploadProps) => {
    const [uploadMode, setUploadMode] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const uploadSuccess = (files: string[]) => {
        if (files.length > 0) {
            updateAvatar({
                userId: props.userId,
                avatar: files[0]
            })(dispatch).then(() => {
                props.onChange?.();
                setUploadMode(false);
            });
        }
    };

    return <>
        {uploadMode ? <div className="mb-5">
            <h5>{t("Upload avatar")}</h5>
            <FileUpload onUploaded={uploadSuccess} />
        </div>
        :
            <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                <img src={props.avatar ? withCdn(props.avatar) : defaultAvatar} className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile" />
                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <button type="button" className="border-0 rounded-circle profile-photo-edit avatar-xs" onClick={e => { 
                        setUploadMode(true); 
                    }}>
                        <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                        </span>
                    </button>
                </div>
            </div>
        }
    </>
}

export default AvatarUpload;