import { Col, Row } from "reactstrap";
import { ApiFormsProps } from "./ApiFormsProps";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import TextInput from "Components/Form/TextInput";
import { useDebounce } from "Components/Hooks/HelperHooks";
import * as Yup from "yup";

const PurolatorApiForm = (props: ApiFormsProps) => {
    const { t } = useTranslation();

    let value = props.value ?? {};

    const validation = useFormik({
        initialValues: {
            clientId: value["clientId"] ?? "",
            username: value["username"] ?? "",
            password: value["password"] ?? ""
        },
        validationSchema: Yup.object({
            clientId: Yup.string().required(t("Client ID is required")),
            username: Yup.string().required(t("Username is required")),
            password: Yup.string().required(t("Password is required"))
        }),
        onSubmit: (values) => {
            props.onChange?.(values);
        }
    });

    const submitDebounced = useDebounce(() => validation.isValid && validation.submitForm(), 200);

    return <Row className="g-2">
        <Col xs={12}>
            <RequiredLabel>{t("Client ID")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="clientId">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Username")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="username">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Password")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="password">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
    </Row>;
}

export default PurolatorApiForm;