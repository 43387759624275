import SelectInput from "Components/Form/SelectInput"
import { CarrierPackageTypeContract } from "api/types/contracts/shipping";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadCarrierPackageTypes } from "slices/carrier/thunk";
import { loadCountries } from "slices/common/thunk";

type CarrierPackageTypeSelectProps = EntitySelectInputProps<CarrierPackageTypeContract, "packageTypeCode"> & {
    carrierId: string | undefined
}

const CarrierPackageTypeSelect = ({ isMulti, value, onChange, onSelect, name, isValid, carrierId, ...rest }: CarrierPackageTypeSelectProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectProperties = createSelector(
        (state: RootState) => state.Carrier,
        (carrier) => ({
            packageTypes: carrier.packageTypes,
            loading: carrier.loading.listAccounts
        })
    );

    const { packageTypes, loading } = useSelector(selectProperties);
    const shipmentTargetList = useMemo(() => [{
        value: "",
        label: t("Your Packaging")
    }, ...packageTypes.map(pt => ({ value: pt.packageTypeCode, label: pt.name}))], [packageTypes]);

    useEffect(() => {
        if (carrierId) {
            loadCarrierPackageTypes({
                carrierId
            })(dispatch);
        }
    }, [carrierId])

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = packageTypes.filter(pt => val.includes(pt.packageTypeCode));

        onSelect?.(selected);
        onChange?.(val);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = packageTypes.find(pt => pt.packageTypeCode === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.packageTypeCode);
                return;
            }
        }
        
        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadCountries()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (packageTypes.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packageTypes]);
    
    const selectProps = {
        ...rest,
        placeholder: carrierId ? t("Package Type") : t("Select Carrier First"),
        busy: loading,
        options: shipmentTargetList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierPackageTypeSelect;