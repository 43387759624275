import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationENG from "./locales/en.json";
import paragraphsENG from "./locales/en.paragraphs.json";
import enumsENG from "./locales/en.enums.json";

// the translations
const resources = {
    en: {
        translation: translationENG,
        paragraphs: paragraphsENG,
        enums: enumsENG
    },
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("I18N_LANGUAGE") || "en",
        defaultNS: "translation",
        fallbackLng: "en", // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss,
            format: (value, rawFormat, lng) => {
                if (rawFormat) {
                    const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
                    switch (format) {
                        case 'uppercase':
                            return value.toUpperCase();
                        case 'price':
                            return Intl.NumberFormat(lng, {
                                style: 'currency',
                                currency: additionalValues[0]
                            }).format(value);
                    }
                }

                return value;
            }
        },
    });

export default i18n;
