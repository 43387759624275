import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Label, LabelProps } from "reactstrap";

type RequiredLabelProps = PropsWithChildren<LabelProps> & {
    
};

const RequiredLabel = ({ children, className, ...rest }: RequiredLabelProps) => {
    const { t } = useTranslation();

    return <Label className={classNames("text-nowrap", className)} {...rest}>
        {children}
        {" "}
        <sup className="text-danger" title={t("Required field")}><i className="ri-asterisk"></i></sup>
    </Label>;
}

export default RequiredLabel;