import { useState } from "react";
import { Row, Col, Card, Container, Label, Form } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

//import images 
import { createSelector } from "reselect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTranslation } from "react-i18next";
import { RegisterCustomerCommand } from "api/types/commands";
import { TypedShape } from "helpers/types";
import { RootState } from "slices";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import TextInput from "Components/Form/TextInput";
import { CountryContract } from "api/types/contracts/common";
import Checkbox from "Components/Form/Checkbox";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import Copyright from "Components/Copyright";
import { useTenant } from "Components/Hooks/TenantHooks";
import { withCdn } from "helpers/urlHelper";
import { registerCustomer } from "slices/auth/register/thunk";
import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";

import logoLight from "../../assets/images/tyloc/logo.png";

type FormType = RegisterCustomerCommand & {
    confirmPassword: string,
    termsAndConditions: boolean
}

const Register = () => {
    const dispatch = useDispatch();
    const { tenant } = useTenant();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState<CountryContract>();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {} as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            name: Yup.string().required(t("Please Enter Your Name")),
            surname: Yup.string().required(t("Please Enter Your Surname")),
            preferredWarehouseId: Yup.string().notRequired(),
            firmName: Yup.string().notRequired(),
            countryCode: Yup.string().required(t("Please Select Your Country")),
            email: Yup.string().email(t("Please Enter Valid Email")).required(t("Please Enter Your Email")),
            phone: Yup.string().required(t("Please Enter Your Phone Number")),
            password: Yup.string().required(t("Please Enter Your Password")),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), ""], t("Passwords doesn't match")).required(t('Confirm Password is required')),
            termsAndConditions: Yup.boolean().oneOf([true], t('Please Accept Terms & Conditions')).required(t('Please Accept Terms & Conditions'))
        }),
        onSubmit: (values) => {
            registerCustomer(values)(dispatch);
        }
    });

    const registerdatatype = createSelector(
        (state: RootState) => state.Register,
        (register) => ({
            ...register
        })
    );
    // Inside your component
    const { loading, registeredCustomer, error } = useSelector(registerdatatype);
    const registerSuccess = registeredCustomer !== undefined;

    setPageTitle(t("Register Account"));

    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <Container>
                    <Row>
                        <Col lg={registerSuccess ? 4 : 12} className="mx-auto">
                            <Card className="overflow-hidden m-0">
                                <Row className="g-0">
                                    {registerSuccess ? 
                                    <>
                                        <Col>
                                            <div className="p-lg-5 p-4 text-center">
                                                <div className="avatar-lg mx-auto mt-2">
                                                    <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                        <i className="ri-checkbox-circle-fill"></i>
                                                    </div>
                                                </div>
                                                <div className="mt-4 pt-2">
                                                    <h4>{t("Well done !")}</h4>
                                                    <p className="text-muted mx-4">{t("Your registration completed")}</p>
                                                    <div className="mt-4">
                                                        <Link to="/login" className="btn btn-success w-100">{t("Login to Dashboard")}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">{t("Create an Account")}</h5>
                                                </div>
                                                <div className="mt-4">
                                                    <Form onSubmit={validation.handleSubmit} className="needs-validation" noValidate>
                                                        <Row>
                                                            <Col md={6} className="g-3">
                                                                <Label htmlFor="name">{t("Name")}</Label>
                                                                <ValidatedInput validation={validation} field="name" />
                                                            </Col>
                                                            <Col md={6} className="g-3">
                                                                <Label htmlFor="surname" >{t("Surname")}</Label>
                                                                <ValidatedInput validation={validation} field="surname" />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="g-3">
                                                                <Label htmlFor="countryCode">{t("Country")}</Label>
                                                                <ValidationWrapper validation={validation} field="countryCode">
                                                                    <CountrySelect onSelect={setSelectedCountry} />
                                                                </ValidationWrapper>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="g-3">
                                                                <Label htmlFor="preferredWarehouseId">{t("Preferred Warehouse")}</Label>
                                                                <ValidationWrapper validation={validation} field="preferredWarehouseId">
                                                                    <WarehouseSelect />
                                                                </ValidationWrapper>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="g-3">
                                                                <Label htmlFor="firmName">{t("Company")}</Label>
                                                                <ValidatedInput validation={validation} field="firmName" />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6} className="g-3">
                                                                <Label htmlFor="phone">{t("Phone")}</Label>
                                                                <ValidationWrapper validation={validation} field="phone">
                                                                    <TextInput mask={`${selectedCountry?.phoneCode || ""} 000 000 0000`} />
                                                                </ValidationWrapper>
                                                            </Col>
                                                            <Col md={6} className="g-3">
                                                                <Label htmlFor="email">{t("Email")}</Label>
                                                                <ValidatedInput validation={validation} field="email" />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6} className="g-3">
                                                                <Label htmlFor="password">{t("Password")}</Label>
                                                                <ValidatedInput type="password" validation={validation} field="password" />
                                                            </Col>
                                                            <Col md={6} className="g-3">
                                                                <Label htmlFor="confirmPassword">{t("Confirm Password")}</Label>
                                                                <ValidatedInput type="password" validation={validation} field="confirmPassword" />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="g-3">
                                                                <ValidationWrapper validation={validation} field="termsAndConditions">
                                                                    <Checkbox>
                                                                        <span>{t("Accept")}</span>{" "}
                                                                        <a href="#" className="text-info">{t("Terms and Conditions")}</a>
                                                                    </Checkbox>
                                                                </ValidationWrapper>
                                                            </Col>
                                                        </Row>
                                                        {error && <Row>
                                                            <Col>
                                                                <DangerAlert>{error}</DangerAlert>
                                                            </Col>
                                                        </Row>}
                                                        <Row>
                                                            <Col className="mt-3">
                                                                <BusyOverlay busy={loading.register} size="sm">
                                                                    <ValidatorButton type="submit" validation={validation} color="success" className="w-100">
                                                                        {t("Sign Up")}
                                                                    </ValidatorButton>
                                                                </BusyOverlay>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </div>
                                                <div className="mt-3 text-center">
                                                    <p className="mb-0">{t("Already have an account ?")} <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> {t("Login")}</Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </>}
                                    {!registerSuccess && <>
                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4 auth-one-bg h-100">
                                                <div className="bg-overlay bg-tenant-secondary"></div>
                                                <div className="position-relative h-100 d-flex flex-column text-center">
                                                    <div className="mb-4">
                                                        <Link to="/" className="d-block">
                                                        <img src={tenant?.logo ? withCdn(tenant.logo) : logoLight} alt="" style={{ height: "100px" }} />
                                                        </Link>
                                                    </div>
                                                    <div className="">
                                                        {tenant?.coverPageText}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </>}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer">
                <div className="text-center">
                    <Copyright />
                </div>
            </footer>
        </div>
    </>;
};

export default Register;
