import { useProfile } from "Components/Hooks/ProfileHooks";
import { UnitOfWeight } from "api/types/contracts/common";

export type WeightProps = {
    value: number | undefined,
    unit: UnitOfWeight | undefined
}

const Weight = ({ value, unit }: WeightProps) => {
    const { userProfile } = useProfile();
    const displayUnit = unit || userProfile?.user.warehouse?.settings?.unitOfWeight || "lbs";

    return value ? <span className="text-nowrap">{value.toFixed(2)} {displayUnit}</span> : "-";
}

export default Weight;