import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import { FbaShipmentContract, FbaShipmentItemContract, FbaShipmentItemStatus, FbaShipmentUpdateFormItem } from "api/types/contracts/shipping";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, Row, UncontrolledPopover } from "reactstrap";
import Weight from "Components/Displays/UnitDisplay/Weight";
import Currency from "Components/Displays/UnitDisplay/Currency";
import NumberInput from "Components/Form/NumberInput";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { commaSeperated } from "helpers/string";
import { RootState } from "slices";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import DateInput from "Components/Form/DateInput";
import { useThrottle } from "Components/Hooks/HelperHooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TypedShape } from "helpers/types";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { loadFbaShipment, updateFbaShipmentItems, updateFbaShipmentItemPrepService } from "slices/shipping/fbaShipping/thunk";
import TruncatableText from "Components/Common/TruncatableText";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductEditForm from "Components/Shared/ProductEditForm";

type ProductCardProps = {
    fbaShipment: FbaShipmentContract,
    fbaShipmentItem: FbaShipmentItemContract,
    canEdit?: boolean
}

const createUpdateForm = (fbaShipmentItem: FbaShipmentItemContract): FbaShipmentUpdateFormItem => {
    return {
        productId: fbaShipmentItem.product.productId,
        count: fbaShipmentItem.count,
        fnsku: fbaShipmentItem.fnsku,
        expireDate: fbaShipmentItem.expireDate,
        status: fbaShipmentItem.status,
        prepServices: fbaShipmentItem.prepServices.map(p => p.prepService.prepServiceId)
    };
}

const ProductCard = ({ fbaShipment, fbaShipmentItem, canEdit }: ProductCardProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const bundleItemsRef = useRef<HTMLAnchorElement>(null);
    const splitItemRef = useRef<HTMLAnchorElement>(null);
    const [fnskuLabelPrintCount, setFnskuLabelPrintCounts] = useState(1);
    const [fbaShipmentItemPrepServices, setFbaShipmentItemPrepServices] = useState(fbaShipmentItem.prepServices);
    const [productModal, setProductModal] = useState(false);

    const selectProperties = createSelector(
        (state: RootState) => state.PrepService,
        (state) => ({
            prepServices: state.list?.items || [], 
            loading: state.loading.list
        })
    );

    const validation = useFormik({
        initialValues: createUpdateForm(fbaShipmentItem),
        validationSchema: Yup.object<FbaShipmentUpdateFormItem, TypedShape<FbaShipmentUpdateFormItem>>({
            productId: Yup.string().required(),
            count: Yup.number().required(t("Count is required")),
            fnsku: Yup.string().notRequired(),
            expireDate: Yup.date().notRequired(),
            status: Yup.string<FbaShipmentItemStatus>().required(),
            prepServices: Yup.array(Yup.string().required()).notRequired()
        }),
        onSubmit: values => {
            updateFbaShipmentItems({
                fbaShipmentId: fbaShipment.fbaShipmentId,
                items: [values]
            })(dispatch).then(() => {
                loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId })(dispatch);
            })
        }
    });

    const throttledSave = useThrottle(() => {
        validation.submitForm();
    }, 5000);

    useEffect(() => {
        validation.setValues(createUpdateForm(fbaShipmentItem));
    }, [fbaShipmentItem]);

    useEffect(() => {
        setFbaShipmentItemPrepServices(fbaShipmentItem.prepServices);
    }, [fbaShipmentItem.prepServices]);

    const { prepServices, loading } = useSelector(selectProperties);
    const warehousePrepServices = useMemo(() => prepServices.filter(p => p.warehouse.warehouseId === fbaShipment.warehouse.warehouseId), 
        [prepServices, fbaShipment.warehouse.warehouseId]);

    return <>
            <Card className={classNames("ribbon-box right pt-4", {
                "border border-success": validation.values.status === "prepared"
            })}>
            <div className="position-absolute left-0 top-0 p-2">
                {canEdit && <i className={classNames("cursor-pointer ri-checkbox-circle-fill lh-1 fs-2", {
                    "text-success": validation.values.status === "prepared",
                    "text-muted": validation.values.status !== "prepared"
                })} onClick={() => {
                    validation.setFieldValue("status", fbaShipmentItem.status === "prepared" ? "waiting" : "prepared");
                    throttledSave();
                }}></i>}
            </div>
            <CardBody>
                <div className={classNames("ribbon round-shape", {
                    "ribbon-success": fbaShipmentItem.status === "prepared",
                    "ribbon-info": fbaShipmentItem.status === "ready",
                    "ribbon-warning": fbaShipmentItem.status === "waiting"
                })}>{t(`enums:FbaShipmentItemStatus.${fbaShipmentItem.status}`)}</div>
                <div className="d-flex align-items-start text-muted mb-3">
                    <div className="flex-shrink-0 me-3">
                        <ProductImageDisplay product={fbaShipmentItem.product} />
                    </div>
                    
                    <div className="flex-grow-1">
                        <Link to="#" onClick={() => setProductModal(true)} className="text-info fw-bold">
                            <TruncatableText maxLines={2}>
                                {fbaShipmentItem.product.name}
                            </TruncatableText>
                        </Link>
                    </div>
                </div>
                <Row>
                    <Col className="text-start">
                        <Dimensions value={fbaShipmentItem.product} unit={fbaShipmentItem.product.options.unitOfLength} />
                        <br />
                        <Weight value={fbaShipmentItem.product.weight} unit={fbaShipmentItem.product.options.unitOfWeight} />
                    </Col>
                    <Col className="text-center">
                        <Link to="#" onClick={() => setProductModal(true)} className="text-info">
                            {fbaShipmentItem.product.asin}
                        </Link>
                    </Col>
                    <Col className="text-end fs-4">
                        <Currency value={fbaShipmentItem.product.buyPrice} currency={fbaShipmentItem.product.options.unitOfCurrency} />
                    </Col>
                </Row>
            </CardBody>
            <CardBody className="border-top py-2">
                <FormGroup row className="align-items-center" noMargin>
                    <Label className="col-3">{t("Count")}</Label>
                    <Col xs={3}>
                        <ValidationWrapper validation={validation} field="count">
                            <NumberInput disabled={!canEdit} size="sm" className="text-center" />
                        </ValidationWrapper>
                    </Col>
                    <Label className="col-3">{t("Reserved")}</Label>
                    <Col xs={3}>
                        <Input type="text" bsSize="sm" plaintext readOnly className="text-center" value={fbaShipmentItem.reserved} />
                    </Col>
                </FormGroup>
                <Row>
                    <Col className="d-flex align-items-center gap-1">
                        {fbaShipmentItem.isBundle && <>
                            <i className="ri-spam-fill text-warning"></i>
                            <Link to="#" className="fs-10" ref={bundleItemsRef}>{t("Bundling from {{products}}", {
                                products: commaSeperated(fbaShipmentItem.bundleItems.map(p => p.product.asin ?? "???"))
                            })}</Link> 
                            <UncontrolledPopover target={bundleItemsRef} trigger="legacy" style={{ minWidth: "30rem" }}>
                                <ListGroup>
                                    {fbaShipmentItem.bundleItems.map(p => <ListGroupItem key={p.product.productId}>
                                        <div className="hstack gap-2">
                                            <ProductImageDisplay style={{ flex: 1 }} product={p.product} />
                                            <div style={{ flex: 8 }}><Badge color="primary" className="fs-13">{p.totalNeeded}</Badge> x <strong>{p.product.asin}</strong> ({p.product.name})</div>
                                        </div>
                                    </ListGroupItem>)}
                                </ListGroup>
                            </UncontrolledPopover>
                        </>}
                        {fbaShipmentItem.isSplit && fbaShipmentItem.splitItem && <>
                            <i className="ri-spam-fill text-warning"></i>
                            <Link to="#" className="fs-10" ref={splitItemRef}>{t("Splitting from {{product}}", {
                                product: fbaShipmentItem.splitItem.product.asin ?? "???"
                            })}</Link> 
                            <UncontrolledPopover target={splitItemRef} trigger="legacy" style={{ minWidth: "30rem" }}>
                                <div className="hstack gap-2">
                                    <ProductImageDisplay style={{ flex: 1 }} product={fbaShipmentItem.splitItem.product} />
                                    <div style={{ flex: 8 }}><Badge color="primary" className="fs-13">{fbaShipmentItem.splitItem.splittedItemCount}</Badge> x <strong>{fbaShipmentItem.splitItem.product.asin}</strong> ({fbaShipmentItem.splitItem.product.name})</div>
                                </div>
                            </UncontrolledPopover>
                        </>}
                    </Col>
                </Row>
            </CardBody>
            <CardBody className="border-top py-0 pt-2">
                <Row>
                    <Col>
                        <h5>{t("Options")}</h5>
                        <ul className="list-unstyled">
                            {warehousePrepServices.map(p => {
                                const prepService = fbaShipmentItemPrepServices.find(fp => fp.prepService.prepServiceId === p.prepServiceId);
                                const index = validation.values.prepServices?.findIndex(ps => ps === p.prepServiceId);

                                return <li key={p.prepServiceId} className="align-items-center d-flex gap-2 mb-1">
                                    <Input disabled={!canEdit} type="checkbox" defaultChecked={!!prepService} />
                                    <span>{p.name}</span>
                                    {prepService && <i className={classNames("cursor-pointer ri-checkbox-circle-fill lh-1 fs-4", {
                                        "text-success": prepService.completed,
                                        "text-muted": !prepService.completed
                                    })} onClick={() => {
                                        if (!canEdit) {
                                            return;
                                        }

                                        setFbaShipmentItemPrepServices(prepServices => prepServices.map(ps => {
                                            if (ps.prepService.prepServiceId === p.prepServiceId) {
                                                return {
                                                    ...ps,
                                                    completed: !prepService.completed
                                                };
                                            }
                                            return ps;
                                        }));

                                        updateFbaShipmentItemPrepService({
                                            fbaShipmentId: fbaShipment.fbaShipmentId,
                                            fbaShipmentItemId: fbaShipmentItem.fbaShipmentItemId,
                                            prepServiceId: p.prepServiceId,
                                            completed: !prepService.completed
                                        })(dispatch).then(() => {
                                            loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId })(dispatch);
                                        });
                                    }}></i>}
                                </li>;
                            })}
                        </ul>
                    </Col>
                    <Col>
                        <div className="vstack gap-2">
                            <div className="text-nowrap">
                                <span>{t("FNSKU")}: </span> 
                                <span className="fs-10">{fbaShipmentItem.fnsku ?? "-"}</span> 
                            </div>
                            <div className="hstack gap-2">
                                <NumberInput disabled={!fbaShipmentItem.fnsku} size="sm" value={fnskuLabelPrintCount} onChange={val => setFnskuLabelPrintCounts(val ?? 0)} />
                                <Button disabled={!fbaShipmentItem.fnsku} color="primary" size="sm">{t("Print")}</Button>
                            </div>
                            <Label>{t("Expire Date")}</Label>
                            <ValidationWrapper validation={validation} field="expireDate">
                                <DateInput disabled={!canEdit} size="sm" wrapperClassName="mb-3" />
                            </ValidationWrapper>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Modal isOpen={productModal} toggle={() => setProductModal(!productModal)} size="xl" unmountOnClose>
            <ModalCloseButton onClick={() => setProductModal(false)} />
            <ModalBody className="p-0">
                <ProductEditForm product={fbaShipmentItem.product} onSuccess={() => setProductModal(false)} />
            </ModalBody>
        </Modal>
    </>;
}

export default ProductCard;
