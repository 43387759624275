import { APIClient } from "helpers/api_helper";
import { CreateBayCommand, DeleteBayCommand, UpdateBayCommand } from "./types/commands";

const api = new APIClient();

export const postBay = async (data: CreateBayCommand): Promise<void> => {
    await api.post(`/api/bay`, data);
}

export const putBay = async (data: UpdateBayCommand): Promise<void> => {
    await api.put(`/api/bay/${data.bayId}`, { ...data.bay });
}

export const deleteBay = async (data: DeleteBayCommand): Promise<void> => {
    await api.delete(`/api/bay/${data.bayId}`);
}