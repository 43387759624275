import { ProductContract } from "api/types/contracts/products"
import classNames from "classnames";
import { withCdn } from "helpers/urlHelper";
import { Link } from "react-router-dom";
import { UncontrolledPopover } from "reactstrap";

type ProductImageDisplayProps = {
    product: ProductContract,
    largePreview?: boolean,
    size?: "xs" | "sm" | "md" | "lg" | "xl",
    className?: string,
    style?: React.CSSProperties,
    radius?: "circle" | "rounded" | "none"
}

const ProductImageDisplay = ({ product, largePreview, size, style, className, radius }: ProductImageDisplayProps) => {
    const image = product.options.image;
    size = size || "sm";
    const radiusClass = radius === "circle" ? "rounded-circle" : radius === "rounded" ? "rounded" : radius === "none" ? "" : "rounded";

    return image ? <>
        <div className={classNames(`avatar-${size}`, className)} style={style}>
            <Link to="#" id={`image-${product.productId}`} >
                <img alt={product.name} src={withCdn(image)} className={classNames(radiusClass, "aspect-1x1 mw-100 object-fit-cover d-block")} />
            </Link>
        </div>
        {largePreview && <UncontrolledPopover target={`image-${product.productId}`} trigger="legacy">
            <img alt={product.name} src={withCdn(image)} className="p-2" style={{ height: "20rem"}} />
        </UncontrolledPopover>}
    </>
    : <>
        <div className={classNames(`avatar-${size}`, className)}>
            <div className={classNames(radiusClass, "avatar-title bg-tenant-primary text-tenant-primary-subtle fs-4")}>
                {product.name[0].toUpperCase()}
            </div>
        </div>
    </>;
}

export default ProductImageDisplay;