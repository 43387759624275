import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import navigation, { HeaderMenuItem, MenuItem } from "Routes/navigation_data";

const findInItems = (items: MenuItem[], id: string): (MenuItem | undefined) => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        
        if (item.id === id) {
            return item;
        }
        else if (item.children && item.children.length) {
            return findInItems(item.children, id);
        }
    }
    
};

const findByPathInItems = (items: (MenuItem | HeaderMenuItem)[], path: string): (MenuItem | undefined) => {
    for (var i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.isHeader) {
            continue;
        }

        if (item.link === path || (item.link && path.startsWith(item.link))) {
            return item;
        }
        else if (item.children && item.children.length) {
            const found = findByPathInItems(item.children, path);

            if (found) {
                return found;
            }
        }
    }
};

const setOpenState = (item: MenuItem, updateParents: boolean = true) => {
    item.openState = true;
    if (updateParents && item.parent) {
        setOpenState(item.parent);
    }
};

const resetOpenState = (item: MenuItem) => {
    item.openState = false;

    if (item.children && item.children.length) {
        for (var i = 0; i < item.children.length; i++) {
            resetOpenState(item.children[i]);
        }
    }
};

const useMenu = () => {
    const location = useLocation();
    const [menu, setMenu] = useState(navigation);

    const openMenuItem = useCallback((item: MenuItem) => {
        setMenu(menuItems => {
            for (var i = 0; i < menuItems.length; i++) {
                const menuItem = menuItems[i];
                if (menuItem.isHeader) {
                    continue;
                }
                resetOpenState(menuItem);
            }
            
            setOpenState(item);
            
            return menuItems;
        });

    }, [setMenu]);

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');

        let found = findByPathInItems(navigation, location.pathname);
        if (found) {
            openMenuItem(found);
        }
    
        return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return { menu, openMenuItem };
};

export { useMenu };
