import { InfoAlert } from "Components/Alerts";
import DateDisplay from "Components/Displays/DateDisplay";
import ReceivingEntryStatusBadge from "Components/Displays/ReceivingEntryStatusDisplay";
import { ReceivingEntryContract } from "api/types/contracts/receivings";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion, AccordionItem, Button, Collapse, Table } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";

type ReceivingsProps = {
    receivings: ReceivingEntryContract[]
}

const Receivings = (props: ReceivingsProps) => {
    const { t } = useTranslation();
    const [openReceivingPanels, setOpenReceivingPanels] = useState<string[]>([]);

    useEffect(() => {
        setOpenReceivingPanels(props.receivings.map(r => r.receivingEntryId));
    }, [props.receivings]);

    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (inbound) => ({
            error: inbound.error,
            inbound: inbound.processingItem!,
            loading: inbound.loading
        })
    );
    const { loading, inbound, error } = useSelector(selectProperties);
    
    const toggleActive = (receivingEntryId: string) => {
        setOpenReceivingPanels(prev => prev.includes(receivingEntryId) ? prev.filter(id => id !== receivingEntryId) : [...prev, receivingEntryId]);
    }

    return <>
        <div>
            <Link to={`/gate-entry/inbound/${inbound.inboundId}`} className="btn btn-primary mb-2" >{t("New Receiving")}</Link>
        </div>
        {props.receivings.length > 0 ? <>
            <Accordion open={openReceivingPanels} flush toggle={a => {}}>
                {props.receivings.map((receiving, index) => {
                    const isOpen = openReceivingPanels.includes(receiving.receivingEntryId);

                    return <React.Fragment key={index}>
                        <AccordionItem id={receiving.receivingEntryId} className="mb-2">
                            <h2 className="accordion-header">
                                <button className={classNames("accordion-button border border-warning", {
                                    "collapsed rounded-3": !isOpen,
                                    "bg-warning-subtle rounded-top-3 border-bottom-0": isOpen
                                })} type="button" onClick={() => toggleActive(receiving.receivingEntryId)}>
                                    <span className="d-inline-flex gap-3">
                                        <span>
                                            <strong>{t("Receiving ID")}: </strong>
                                            <span>#{receiving.receivingNumber}</span>
                                        </span>
                                        <span>
                                            <strong>{t("Arrival Date")}: </strong>
                                            <span><DateDisplay date={receiving.createdAt} /></span>
                                        </span>
                                        <span>
                                            <strong>{t("Box Count")}: </strong>
                                            <span>{receiving.boxes.length}</span>
                                        </span>
                                        <span>
                                            <strong>{t("Status")}: </strong>
                                            {/* <span>{t(`enums:ReceivingEntryStatus.${receiving.status}`)}</span> */}
                                            <ReceivingEntryStatusBadge value={receiving.status} />
                                        </span>
                                    </span>
                                </button>
                            </h2>
                            <Collapse isOpen={isOpen}>
                                <div className="accordion-body border border-warning rounded-bottom-3">
                                    <Link to={`/gate-entry/${receiving.receivingEntryId}`} className="btn btn-info">
                                        {t("New Box")}
                                    </Link>
                                    {receiving.boxes?.length === 0 ? <>
                                        <InfoAlert className="mb-0 mt-2">
                                            <span>{t("No boxes found")}</span>
                                        </InfoAlert>
                                    </>
                                    : <>
                                        <div className="table-responsive table-card mt-2">
                                            <Table striped size="sm" className="table-nowrap align-middle">
                                                <thead>
                                                    <tr>
                                                        <th>{t("Operation")}</th>
                                                        <th>{t("Box ID")}</th>
                                                        <th>{t("Carrier")}</th>
                                                        <th>{t("Tracking ID")}</th>
                                                        <th>{t("Total Received")}</th>
                                                        <th>{t("Total Sellable")}</th>
                                                        <th>{t("Total Unusable")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {receiving.boxes.map((receivingBox, index) => <tr key={index}>
                                                        <td>
                                                            <Link to={`/receivings/${receiving.receivingEntryId}?box=${receivingBox.receivingBoxId}`} className="btn btn-success btn-sm">
                                                                {t("GRN")}
                                                            </Link>
                                                        </td>
                                                        <td># {receivingBox.box.boxNumber}</td>
                                                        <td>{receivingBox.carrier?.name}</td>
                                                        <td>{receivingBox.trackingNumber}</td>
                                                        <td>{_.sumBy(receivingBox.box.contents, c => c.sellableQuantity + c.unusableQuantity)}</td>
                                                        <td>{_.sumBy(receivingBox.box.contents, c => c.sellableQuantity)}</td>
                                                        <td>{_.sumBy(receivingBox.box.contents, c => c.unusableQuantity)}</td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>}
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </React.Fragment>;
                })}
            </Accordion>
        </> : <>
            <InfoAlert className="mb-0">{t("No receivings found")}</InfoAlert>
        </>}
    </>;
}

export default Receivings;