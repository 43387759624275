import { InfoAlert } from "Components/Alerts";
import PrivateDownload from "Components/Common/PrivateDownload";
import PrivateDownloadLink from "Components/Common/PrivateDownload";
import DateDisplay from "Components/Displays/DateDisplay";
import { ReceivingEntryContract } from "api/types/contracts/receivings";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";

type DocumentsProps = {
    receivings: ReceivingEntryContract[]
}

const Documents = ({ receivings }: DocumentsProps) => {
    const { t } = useTranslation();

    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (inbound) => ({
            error: inbound.error,
            inbound: inbound.processingItem!,
            loading: inbound.loading
        })
    );
    const { loading, inbound, error } = useSelector(selectProperties); 

    const allDocuments = useMemo(() => receivings.flatMap(receiving => receiving.documents.map(document => ({
        ...document,
        receivingNumber: receiving.receivingNumber,
    }))), [receivings]);

    return allDocuments.length > 0 ? <>
        <div className="table-responsive table-card mt-2">
            <Table size="sm" striped borderless className="align-middle mb-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t("Type")}</th>
                        <th>{t("Link")}</th>
                        <th>{t("Uploaded Date")}</th>
                    </tr>
                </thead>
                <tbody>
                    {allDocuments.map((document, index) => <tr key={index}>
                        <td>#{index + 1}</td>
                        <td>{document.documentTypeName}</td>
                        <td>
                            <PrivateDownload href={document.path}>
                                {url => <a href={url} className="btn btn-ghost-primary btn-sm">{t("Download")}</a>}
                            </PrivateDownload>
                        </td>
                        <td><DateDisplay date={document.createdAt} /></td>
                    </tr>)}
                </tbody>
            </Table>
        </div>
    </> 
    : <>
        <InfoAlert className="mb-0">{t("No documents found")}</InfoAlert>
    </>;
}

export default Documents;