import classNames from 'classnames';
import { CSSProperties } from 'react';
import { Spinner } from 'reactstrap';


export type LoaderProps = {
    className?: string,
    style?: CSSProperties,
    height: `${number}px`
}

const Loader = (props: LoaderProps) => {
    return <>
        <div className={classNames("d-flex justify-content-center align-items-center", props.className)} style={{ ...props.style, height: props.height }}>
            <Spinner className='text-tenant-primary' />
        </div>
    </>;
};

export default Loader;
