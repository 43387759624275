import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "slices";
import { getCurrentCustomer } from "api/customer";
import { initialize, setCustomer } from "slices/tenant/reducer";

const tenantListener = createListenerMiddleware<RootState>();

tenantListener.startListening({
    actionCreator: initialize,
    effect: async (action, listener) => {
        if (listener.getState().Login.user?.user.userType === "customer") {
            var customer = await getCurrentCustomer();

            listener.dispatch(setCustomer(customer));
        }
        else {
            listener.dispatch(setCustomer(undefined));
        }
    }
});

export default tenantListener;