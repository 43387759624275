import { ListProductColorsQuery } from "api/types/queries";
import { CreateProductColorCommand, DeleteProductColorCommand, UpdateProductColorCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductColorContract } from "./types/contracts/products";

const api = new APIClient();

export const getProductColorList = async (data: ListProductColorsQuery): Promise<PagedList<ProductColorContract>> => {
    const result = await api.get(`/api/productcolor`, data);
    return result;
}

export const postProductColor = async (data: CreateProductColorCommand): Promise<ProductColorContract | undefined> => {
    const response = await api.post(`/api/productcolor`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putProductColor = async (data: UpdateProductColorCommand): Promise<void> => {
    await api.put(`/api/productcolor/${data.productColorId}`, { ...data.productColor });
}

export const deleteProductColor = async (data: DeleteProductColorCommand): Promise<void> => {
    await api.delete(`/api/productcolor/${data.productColorId}`);
}
