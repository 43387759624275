import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CountryContract, StateContract, UnitOfCurrency } from "api/types/contracts/common";
import { LoadingStates } from "helpers/types";

type CommonLoadingStates = LoadingStates<"countries" | "states">;

export type CommonState = {
    countries: CountryContract[],
    states: StateContract[],
    loading: CommonLoadingStates,
    usdExchangeRates: Record<UnitOfCurrency, number>
};

export const initialState: CommonState = {
    countries: [],
    states: [],
    usdExchangeRates: {
        "usd": 1,
        "eur": 0.85,
        "gbp": 0.73,
        "cad": 1.29,
        "aud": 1.35,
        "jpy": 109.92,
        "cny": 6.46,
        "nzd": 1.44
    },
    loading: {
        countries: false,
        states: false
    }
};

const CommonSlice = createSlice({
    name: "CommonSlice",
    initialState,
    reducers: {
        setCountryList(state, action: PayloadAction<CountryContract[]>) {
            state.countries = action.payload;
            state.loading.countries = false;
        },
        setStateList(state, action: PayloadAction<StateContract[]>) {
            state.states = action.payload;
            state.loading.states = false;
        },
        loading(state, action: PayloadAction<[keyof CommonLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
    },
});

export const {
    setCountryList,
    setStateList,
    loading
} = CommonSlice.actions;

export default CommonSlice.reducer;
