import { AnyAction, Dispatch } from "redux";
import { setList, loading, apiError } from "./reducer";
import { ListProductColorsQuery } from "api/types/queries";
import { CreateProductColorCommand, DeleteProductColorCommand, UpdateProductColorCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteProductColor, getProductColorList, postProductColor, putProductColor } from "api/productColor";

export const loadList = (params: ListProductColorsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getProductColorList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createProductColor = (params: CreateProductColorCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        const color = await postProductColor(params);
        toast.success(i18n.t("Product color created"));

        return color;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return undefined;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateProductColor = (params: UpdateProductColorCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putProductColor(params);
        toast.success(i18n.t("Product color updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeProductColor = (params: DeleteProductColorCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteProductColor(params);

        toast.success(i18n.t("Product color deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiProductColors = (params: DeleteProductColorCommand[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteProductColor(item);
        }

        toast.success(i18n.t("Product colors deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}