import { APIClient } from "helpers/api_helper";
import { CreateShelfCommand, DeleteShelfCommand, UpdateShelfCommand } from "./types/commands";

const api = new APIClient();

export const postShelf = async (data: CreateShelfCommand): Promise<void> => {
    await api.post(`/api/shelf`, data);
}

export const putShelf = async (data: UpdateShelfCommand): Promise<void> => {
    await api.put(`/api/shelf/${data.shelfId}`, { ...data.shelf });
}

export const deleteShelf = async (data: DeleteShelfCommand): Promise<void> => {
    await api.delete(`/api/shelf/${data.shelfId}`);
}