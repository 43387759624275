import { combineReducers } from "redux";

// Front
import LayoutReducer, { LayoutState } from "./layouts/reducer";

// Authentication
import LoginReducer, { LoginState } from "./auth/login/reducer";
import RegisterReducer, { RegisterState } from "./auth/register/reducer";
import ForgetPasswordReducer, { ForgetPasswordState } from "./auth/forgetpwd/reducer";
import ProfileReducer, { ProfileState } from "./auth/profile/reducer";
import MetadataReducer, { MetadataState } from './metadata/reducer';
import TenantReducer, { TenantState } from "./tenant/reducer";
import CompanyReducer, { CompanyState } from './company/reducer';
import CommonReducer, { CommonState } from "./common/reducer";
import WarehouseReducer, { WarehouseState } from "./warehouse/reducer";
import LocationReducer, { LocationState } from "./location/reducer";
import BinReducer, { BinState } from "./bins/reducer";
import CustomerReducer, { CustomerState } from "./customer/reducer";
import ProductReducer, { ProductState } from "./product/reducer";
import ProductCategoryReducer, { ProductCategoryState } from "./product/category/reducer";
import ProductColorReducer, { ProductColorState } from "./product/color/reducer";
import ProductBrandReducer, { ProductBrandState } from "./product/brand/reducer";
import ProductSupplierReducer, { ProductSupplierState } from "./product/supplier/reducer";
import ProductSizeReducer, { ProductSizeState } from "./product/size/reducer";
import InboundReducer, { InboundState } from "./inbound/reducer";
import ReceivingReducer, { ReceivingState } from "./receivings/reducer";
import UnusableProductReasonReducer, { UnusableProductReasonState } from "./receivings/unusableProductReason/reducer";
import PrepServiceReducer, { PrepServiceState } from "./shipping/prepService/reducer";
import FbaShippingReducer, { FbaShippingState } from "./shipping/fbaShipping/reducer";
import InventoryReducer, { InventoryState } from './inventory/reducer';
import CarrierReducer, { CarrierState } from "./carrier/reducer";
import CarrierRuleReducer, { CarrierRuleState } from "./carrierRules/reducer";

export type RootState = {
    Layout: LayoutState,
    Login: LoginState,
    ForgetPassword: ForgetPasswordState,
    Profile: ProfileState,
    Metadata: MetadataState,
    Tenant: TenantState,
    Company: CompanyState,
    Warehouse: WarehouseState,
    Bin: BinState,
    Common: CommonState,
    Location: LocationState,
    Register: RegisterState,
    Customer: CustomerState,
    Product: ProductState,
    ProductCategory: ProductCategoryState,
    ProductColor: ProductColorState,
    ProductBrand: ProductBrandState,
    ProductSupplier: ProductSupplierState,
    ProductSize: ProductSizeState,
    Inbound: InboundState,
    Receiving: ReceivingState,
    UnusableProductReason: UnusableProductReasonState,
    PrepService: PrepServiceState,
    FbaShipping: FbaShippingState,
    Inventory: InventoryState,
    Carrier: CarrierState,
    CarrierRules: CarrierRuleState
}

const rootReducer = combineReducers<RootState>({
    Layout: LayoutReducer,
    Login: LoginReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Metadata: MetadataReducer,
    Tenant: TenantReducer,
    Company: CompanyReducer,
    Warehouse: WarehouseReducer,
    Bin: BinReducer,
    Common: CommonReducer,
    Location: LocationReducer,
    Register: RegisterReducer,
    Customer: CustomerReducer,
    Product: ProductReducer,
    ProductCategory: ProductCategoryReducer,
    ProductColor: ProductColorReducer,
    ProductBrand: ProductBrandReducer,
    ProductSupplier: ProductSupplierReducer,
    ProductSize: ProductSizeReducer,
    Inbound: InboundReducer,
    Receiving: ReceivingReducer,
    UnusableProductReason: UnusableProductReasonReducer,
    PrepService: PrepServiceReducer,
    FbaShipping: FbaShippingReducer,
    Inventory: InventoryReducer,
    Carrier: CarrierReducer,
    CarrierRules: CarrierRuleReducer
});

export default rootReducer;