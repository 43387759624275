import { EnumSelectCheckbox } from "helpers/types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PrepServiceChannel } from "api/types/contracts/shipping";
import Checkbox from "Components/Form/Checkbox";

const PrepServiceChannelCheckbox = (props: EnumSelectCheckbox<PrepServiceChannel>) => {
    const { t } = useTranslation();
    const { value, onChange, ...rest } = props;

    const handleCheckboxChange = (val: string, checked?: boolean) => {
        const channel = val as PrepServiceChannel;
        const newValue = checked ? [...(value || []), channel] : (value || []).filter(v => v !== channel);
        onChange?.(newValue);
    };

    return <div className={classNames("d-flex gap-2", props.className, {
        "flex-row": props.direction === "horizontal",
        "flex-column": props.direction === "vertical"
    })}>
        {["fba", "d2c"].map((option) => (
            <Checkbox key={option} name="prepServiceChannel" id={`prepServiceChannel_${option}`} value={value?.includes(option as PrepServiceChannel)} 
                label={t(`enums:PrepServiceChannel.${option}`)} onChange={checked => handleCheckboxChange(option, checked)} {...rest} />
        ))}
    </div>;
};

export default PrepServiceChannelCheckbox;
