import SelectInput from "Components/Form/SelectInput"
import { CarrierAccountServiceContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadCarrierAccountServices } from "slices/carrier/thunk";

type CarrierAccountServiceSelectProps = EntitySelectInputProps<CarrierAccountServiceContract, "carrierAccountServiceId"> & {
    shipmentTargetId: string,
    carrierId?: string
}

const CarrierAccountServiceSelect = ({ isMulti, value, onChange, onSelect, name, isValid, shipmentTargetId, carrierId, ...rest }: CarrierAccountServiceSelectProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectProperties = createSelector(
        (state: RootState) => state.Carrier,
        (carrier) => ({
            carrierAccountServices: carrier.carrierAccountServices,
            loading: carrier.loading.list
        })
    );

    const { carrierAccountServices, loading } = useSelector(selectProperties);
    const carrierAccountServiceList = useMemo(() => carrierAccountServices
        .filter(c => c.carrierAccountService.carrierId === carrierId)
        .map(c => ({ value: c.carrierAccountService.carrierAccountServiceId, label: c.carrierAccountService.carrierService.name })), 
    [carrierAccountServices, carrierId]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = carrierAccountServices.filter(c => val.includes(c.carrierAccountService.carrierAccountServiceId)).map(cas => cas.carrierAccountService);

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = carrierAccountServices.find(c => c.carrierAccountService.carrierAccountServiceId === val)?.carrierAccountService;

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierAccountServiceId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadCarrierAccountServices({
            shipmentTargetId: shipmentTargetId ?? ""
        })(dispatch);
    }, [dispatch, shipmentTargetId]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (carrierAccountServices.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carrierAccountServices]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Carrier Service"),
        busy: loading,
        options: carrierAccountServiceList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierAccountServiceSelect;
