import Copyright from 'Components/Copyright';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return <>
        <footer className="footer">
            <Container fluid>
                <Row>
                    <Col sm={6}>
                        Tyloc Warehouse Management Systems
                    </Col>
                    <Col sm={6} className='justify-content-end d-flex'>
                        <Copyright />
                    </Col>
                </Row>
            </Container>
        </footer>
    </>;
};

export default Footer;