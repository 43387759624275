import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomerContract } from "api/types/contracts/customers";
import { LoadingStates } from "helpers/types";

type RegisterLoadingStates = LoadingStates<"register" | "login">;

export type RegisterState = {
    registeredCustomer?: CustomerContract,
    loading: RegisterLoadingStates,
    error?: string
};

export const initialState: RegisterState = {
    loading: {
        register: false,
        login: false
    }
};

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        setRegisteredCustomer(state, action: PayloadAction<CustomerContract>) {
            state.registeredCustomer = action.payload;
        },
        loading(state, action: PayloadAction<[keyof RegisterLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        setApiError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        }
    },
});

export const {
    setApiError,
    setRegisteredCustomer,
    loading
} = registerSlice.actions;

export default registerSlice.reducer;
