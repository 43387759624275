import { useTenant } from 'Components/Hooks/TenantHooks';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

const Offline = () => {
    const { t } = useTranslation();
    const { refresh } = useTenant();
    
    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <img src="https://img.themesbrand.com/velzon/images/auth-offline.gif" alt="" height="210" />
                                        <h3 className="mt-4 fw-semibold">
                                            We're currently offline
                                        </h3>
                                        <p className="text-muted mb-4 fs-14">
                                            You aren't connected to the internet. 
                                            When you're back online refresh the page or hit the button below
                                        </p>
                                        <Button color="success" className="btn-border" onClick={refresh}>
                                            <i className="ri-refresh-line align-bottom"></i> {t("Refresh")}
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>;
};

export default Offline;