import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import UnitOfCurrencySelect from "Components/EnumSelects/UnitOfCurrencySelect";
import UnitOfWeightSelect from "Components/EnumSelects/UnitOfWeightSelect";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import WeightInput from "Components/Form/UnitInputs/WeightInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { CarrierOverweightRuleContract, CarrierOverweightRuleForm } from "api/types/contracts/carrier";
import { RangeValueDefinitionContract, UnitOfCurrency, UnitOfWeight } from "api/types/contracts/common";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Label, Row } from "reactstrap";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { createOverweightRule, updateOverweightRule } from "slices/carrierRules/thunk";
import * as Yup from "yup";

type FormProps = {
    overweightRule?: CarrierOverweightRuleContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<CarrierOverweightRuleForm> = {
    name: "",
    currencyCode: undefined,
    weightRanges: [],
    unitOfWeight: undefined
};

const createOverweightRuleForm = (rule: CarrierOverweightRuleContract | undefined): CarrierOverweightRuleForm | undefined => {
    return rule ? {
        name: rule.name,
        currencyCode: rule.currencyCode,
        weightRanges: [...rule.weightRanges],
        unitOfWeight: rule.unitOfWeight
    } as CarrierOverweightRuleForm : undefined
};

const EditForm = (props: FormProps) => {
    const dispatch = useDispatch();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const ruleForm = useMemo(() => createOverweightRuleForm(props.overweightRule) || defaultForm, [props.overweightRule]);

    setPageTitle(`${t(props.overweightRule ? "Update Overweight Rule" : "Create Overweight Rule")} - ${t("Carrier Rules")} - ${t("Management")}`);

    const selectProperties = createSelector(
        (state: RootState) => state.CarrierRules,
        (state) => ({
            error: state.error,
            loading: state.loading.overweight
        })
    );

    const { error, loading } = useSelector(selectProperties);
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...ruleForm,
            unitOfWeight: ruleForm.unitOfWeight ?? userProfile?.user.warehouse?.settings.unitOfWeight,
            currencyCode: ruleForm.currencyCode ?? userProfile?.user.warehouse?.settings.unitOfCurrency
        },
        validationSchema: Yup.object<CarrierOverweightRuleForm, TypedShape<CarrierOverweightRuleForm>>({
            name: Yup.string().required(t("Name is required")),
            currencyCode: Yup.string<UnitOfCurrency>().required(t("Currency is required")),
            unitOfWeight: Yup.string<UnitOfWeight>().required(t("Unit of weight is required")),
            weightRanges: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Price is required"))
            })).min(1, t("At least one range must be defined")).required(),
        }),
        onSubmit: async values => {
            const carrierOverweightRule = values as CarrierOverweightRuleForm;

            if (props.overweightRule?.carrierOverweightRuleId) {
                const success = await updateOverweightRule({ 
                        carrierOverweightRuleId: props.overweightRule.carrierOverweightRuleId, 
                        carrierOverweightRule 
                })(dispatch);

                if (success) {
                    props.onSuccess();
                }
            }
            else {
                const result = await createOverweightRule({
                    carrierOverweightRule
                })(dispatch);

                if (result) {
                    props.onSuccess();
                }
            }
        },
    });

    const addNewWeightRangeRow = () => {
        validation.setFieldValue("weightRanges", [...(validation.values.weightRanges || []), {
            from: 0,
            to: 0
        }]);
    };

    const removeWeightRangeRow = (i: number) => {
        const newRanges = validation.values.weightRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("weightRanges", newRanges);
    }

    useEffect(() => {
        if (ruleForm.weightRanges?.length === 0) {
            addNewWeightRangeRow();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleForm]);

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Container fluid>
                <Row>
                    <Col className="mb-2">
                        <h4>{t(props.overweightRule ? "Edit Overweight Rule" : "Create Overweight Rule")}</h4>
                        <p className="mb-0 fs-10">{t("paragraphs:CarrierRulesOverweightRuleDescription")}</p>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col xs={12}>
                        <RequiredLabel>{t("Rule Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <RequiredLabel>{t("Currency")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="currencyCode">
                            <UnitOfCurrencySelect />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12} className="mb-2">
                        <RequiredLabel>{t("Unit")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="unitOfWeight">
                            <UnitOfWeightSelect />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col className="vstack gap-2">
                        <div className="hstack gap-3 text-center">
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Weight Range")}
                            </div>
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Overweight Fee")}
                            </div>
                        </div>
                        {validation.values.weightRanges?.map((range, i) => <React.Fragment key={i}>
                            <div className="hstack gap-3 align-items-center" key={i}>
                                <span className="small text-muted">{t("from")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`weightRanges.${i}.from`}>
                                        <WeightInput size="sm" placeholder="From" className="text-center" unit={validation.values.unitOfWeight} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("to")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`weightRanges.${i}.to`}>
                                        <WeightInput size="sm" placeholder="To" className="text-center" unit={validation.values.unitOfWeight} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("then")}</span>
                                <div style={{ flex: 3 }}>
                                    <ValidationWrapper validation={validation} field={`weightRanges.${i}.value`}>
                                        <CurrencyInput size="sm" placeholder="Price" currency={validation.values.currencyCode} />
                                    </ValidationWrapper>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removeWeightRangeRow(i)}>
                                        <i className="ri-delete-bin-5-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>)}
                        <div className="d-flex justify-content-end">
                            <Button size="sm" type="button" className="btn-ghost-info" onClick={addNewWeightRangeRow}>
                                {t("Add another")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default EditForm;