import { ShippingPurpose } from "api/types/contracts/inbound";
import { EnumSelectRadio } from "helpers/types";
import { useTranslation } from "react-i18next";
import Radiobox from "Components/Form/Radiobox";
import classNames from "classnames";

const ShippingPurposeRadio = (props: EnumSelectRadio<ShippingPurpose>) => {
    const { t } = useTranslation();
    const { value, onChange, ...rest } = props;

    const handleRadioChange = (val: string) => {
        onChange?.(val as ShippingPurpose);
    };

    return <div className={classNames("d-flex gap-2", props.className, {
        "flex-row": props.direction === "horizontal",
        "flex-column": props.direction === "vertical"
    })}>
        {["storage", "fba", "dropship"].map((option) => (
            <Radiobox key={option} name="shippingPurpose" id={`shippingPurpose_${option}`} value={value === option} 
                label={t(`enums:ShippingPurpose.${option}`)} onChange={() => handleRadioChange(option)} {...rest} />
        ))}
    </div>;
};

export default ShippingPurposeRadio;
