import { useRef } from "react";
import { Container, Modal, ModalBody } from "reactstrap";

//import images 
import { useTranslation } from "react-i18next";

import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import List, { BinListRef } from "./_List";
import { useLoaderData, useNavigate } from "react-router-dom";
import UpdateBinForm from "./_UpdateForm";
import CreateBinForm from "./_CreateForm";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
//import WizardForm from "./_WizardForm";

type BinsPageProps = {
    edit?: boolean
}

const BinsPage = (props: BinsPageProps ) => {
    const loaderData = useLoaderData() as any;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<BinListRef>(null);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <TitleBreadcrumb active={t("Bins")} parents={[t("Management")]} />
                    <List ref={listRef} />
                    {props.edit && 
                        <Modal size="xl" isOpen toggle={() => navigate("/bins")} unmountOnClose>
                            <ModalBody>
                                {loaderData?.bin ? <ResolveWrapper promise={loaderData.bin}>
                                    {(bin) => <>
                                        <ModalCloseButton onClick={() => navigate("/bins")} />
                                        <UpdateBinForm bin={bin} onSuccess={() => {
                                            navigate("/bins");
                                            listRef.current?.reload();
                                        }} />
                                    </>}
                                </ResolveWrapper> : <>
                                    <ModalCloseButton onClick={() => navigate("/bins")} />
                                    <CreateBinForm onSuccess={() => {
                                        navigate("/bins");
                                        listRef.current?.reload();
                                    }} />
                                </>}
                            </ModalBody>
                        </Modal>
                    }
                </Container>
            </div>
        </>
    );
};

export default BinsPage;
