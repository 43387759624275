import { InfoAlert } from "Components/Alerts"
import TrackingStatusBadge from "Components/Displays/TrackingStatusBadge"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Table } from "reactstrap"
import { createSelector } from "reselect"
import { RootState } from "slices"

type TrackingsProps = {

}

const Trackings = (props: TrackingsProps) => {
    const { t } = useTranslation();
    const selectProperties = createSelector(
        (state: RootState) => state.Inbound,
        (inbound) => ({
            error: inbound.error,
            inbound: inbound.processingItem!,
            loading: inbound.loading
        })
    );
    const { loading, inbound, error } = useSelector(selectProperties); 

    return <>
        {inbound.trackings.length > 0 ? <>
            <div className="table-responsive table-card">
                <Table striped borderless className="align-middle no-wrap mb-0">
                    <thead>
                        <tr>
                            <th>{t("Tracking Number")}</th>
                            <th>{t("Carrier")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inbound.trackings.map((tracking, index) => {
                            return <tr key={index}>
                                <td>{tracking.trackingNumber}</td>
                                <td>{tracking.carrier.name}</td>
                                <td><TrackingStatusBadge value={tracking.status} /></td>
                            </tr>;
                        })}
                    </tbody>
                </Table>
            </div>
        </>
        : <>
            <InfoAlert className="mb-0">{t("No trackings found")}</InfoAlert>
        </>}
    </>
}

export default Trackings;
