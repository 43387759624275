import { Input, InputProps } from "reactstrap";
import { IMaskInput } from 'react-imask';
import classNames from "classnames";
import { ValidatableInputProps } from "helpers/types";

export type TextInputProps = Pick<InputProps, "value" | "onKeyUp" | "onKeyDown" | "id" | "disabled" | "onFocus" | "onBlur" | "className" | "style" | "placeholder" | "onInput"> & ValidatableInputProps<string> & {
    type?: "text" | "email" | "url" | "textarea" | "password",
    size?: InputProps["bsSize"],
    mask?: string
}

const TextInput = ({ mask, isValid, className, value, size, onChange, ...rest }: TextInputProps) =>  {
    return <>
        {mask ? 
            <IMaskInput
                className={classNames("form-control", className, {
                    "is-invalid": isValid === false,
                    "is-valid": isValid === true
                })}
                lazy={false}
                mask={mask}
                onAccept={val => onChange?.(val)}
                value={value || ""}
                {...rest} /> 
            : 
            <Input
                //valid={isValid === true}
                invalid={isValid === false}
                className={className}
                onChange={e => onChange?.(e.target.value)}
                value={value || ""}
                bsSize={size}
                {...rest} />
            }
    </>;
}

export default TextInput;