import { AnyAction, Dispatch } from "redux";
import { setStateList, setCountryList, loading } from "./reducer";
import { FaultResponse } from "helpers/api_helper";
import { ListDocumentTypesQuery, ListStatesQuery } from "api/types/queries";
import { getStateList } from "api/state";
import { getCountryList } from "api/country";
import { getDocumentTypeList } from "api/document";

export const loadCountries = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["countries", true]));

        var result = await getCountryList();
        
        dispatch(setCountryList(result));
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["countries", false]));
    }
};

export const loadStates = (params: ListStatesQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["states", true]));

        var result = await getStateList(params);
        
        dispatch(setStateList(result));
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["states", false]));
    }
};

export const loadDocumentTypes = (params: ListDocumentTypesQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await getDocumentTypeList(params);
        
        return result;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return [];
    }
    finally {
        
    }
}