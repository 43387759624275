import { EnumSelectRadio } from "helpers/types";
import { useTranslation } from "react-i18next";
import Radiobox from "Components/Form/Radiobox";
import classNames from "classnames";
import { PrepServiceType } from "api/types/contracts/shipping";

const PrepServiceTypeList = ["standart", "expireDate", "bundling", "splitting"] as PrepServiceType[];

const PrepServiceTypeRadio = (props: EnumSelectRadio<PrepServiceType>) => {
    const { t } = useTranslation();
    const { value, onChange, ...rest } = props;

    const handleRadioChange = (val: string) => {
        onChange?.(val as PrepServiceType);
    };

    return <div className={classNames("d-flex gap-3", props.className, {
        "flex-row": props.direction === "horizontal",
        "flex-column": props.direction === "vertical"
    })}>
        {PrepServiceTypeList.map((option) => (
            <Radiobox key={option} name="prepServiceType" id={`prepServiceType_${option}`} value={value === option} 
                disabled={props.disabledCallback?.(option as PrepServiceType) ?? false}
                label={t(`enums:PrepServiceType.${option}`)} onChange={() => handleRadioChange(option)} {...rest} />
        ))}
    </div>;
};

export default PrepServiceTypeRadio;
