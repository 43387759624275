import { postFbaShipmentInvoice, deleteFbaShipmentDocuments, deleteFbaShipping, getFbaShipment, getFbaShipmentsList, patchFbaShipmentItems, postFbaShipment, putFbaShipmentDocuments, putFbaShipmentPackages, patchFbaShipmentItemPrepService, patchFbaShipment, deleteFbaShipmentItem, postFbaShipmentItems, getShipmentTargetList, postFbaShipmentTransports, deleteFbaShipmentTransport } from "api/fbaShipping";
import { getProductByAsin, postQuickAddProduct, searchProductByAsin } from "api/product";
import { AddFbaShipmentDocumentsCommand, AddFbaShipmentItemsCommand, ClearFbaShipmentDocumentsCommand, CreateFbaShipmentCommand, CreateFbaShipmentInvoiceCommand, CreateFbaShipmentTransportsCommand, DeleteFbaShipmentCommand, DeleteFbaShipmentItemCommand, DeleteFbaShipmentTransportCommand, QuickAddProductsCommand, UpdateFbaShipmentCommand, UpdateFbaShipmentItemPrepServiceCommand, UpdateFbaShipmentItemsCommand, UpdateFbaShipmentPackagesCommand } from "api/types/commands";
import { CancelFbaShipmentInvoiceQuery, GetFbaShippingQuery, GetProductByAsinQuery, ListFbaShipmentsQuery, ListShipmentTargetsQuery, PayFbaShipmentInvoiceQuery, RefundFbaShipmentInvoiceQuery, SearchProductByAsinQuery } from "api/types/queries";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import { apiError, loading, setList, setProcessingItem, setShipmentTargetList } from "./reducer";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";
import { postCancelInvoice, postPayInvoice, postRefundInvoice } from "api/invoice";

export const retrieveProduct = (params: GetProductByAsinQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await getProductByAsin(params);
        
        return result;
    } catch (error) {
        return undefined;
    }
};

export const quickAddProduct = (params: QuickAddProductsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        var result = await postQuickAddProduct(params);
        
        return result;
    } catch (error) {
        return undefined;
    }
};

export const createFbaShipment = (params: CreateFbaShipmentCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        var result = await postFbaShipment(params);

        if (result) {
            toast.success(i18n.t("Shipment created successfully"));
        }
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const loadList = (params: ListFbaShipmentsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["list", true]));
        var result = await getFbaShipmentsList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
}

export const removeFbaShipment = (params: DeleteFbaShipmentCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteFbaShipping(params);

        return true;
        
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const resetError = () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(apiError(undefined));
}

export const loadFbaShipment = (params: GetFbaShippingQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["load", true]));
        var result = await getFbaShipment(params);
        console.log("loadFbaShipment", result);
        
        dispatch(setProcessingItem(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["load", false]));
    }
}

export const updateFbaShipmentItems = (params: UpdateFbaShipmentItemsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await patchFbaShipmentItems(params);

        toast.success(i18n.t("Items updated successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const addFbaShipmentItems = (params: AddFbaShipmentItemsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await postFbaShipmentItems(params);

        toast.success(i18n.t("Items added successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateFbaShipment = (params: UpdateFbaShipmentCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await patchFbaShipment(params);

        toast.success(i18n.t("Update successful"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateFbaShipmentPackages = (params: UpdateFbaShipmentPackagesCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));
        await putFbaShipmentPackages(params);

        toast.success(i18n.t("Packages updated successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const uploadFbaShipmentDocuments = (params: AddFbaShipmentDocumentsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await putFbaShipmentDocuments(params);

        toast.success(i18n.t("Documents uploaded successfully"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const clearFbaShipmentDocuments = (params: ClearFbaShipmentDocumentsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await deleteFbaShipmentDocuments(params);

        toast.success(i18n.t("Documents removed"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const createFbaShipmentInvoice = (params: CreateFbaShipmentInvoiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["invoiceAdd", true]));

        const invoice = await postFbaShipmentInvoice(params);

        toast.success(i18n.t("Invoice created successfully"));

        return invoice;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoiceAdd", false]));
    }
}

export const payFbaShipmentInvoice = (params: PayFbaShipmentInvoiceQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["invoicePay", true]));

        await postPayInvoice(params);

        toast.success(i18n.t("Invoice paid successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoicePay", false]));
    }
}

export const cancelFbaShipmentInvoice = (params: CancelFbaShipmentInvoiceQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["invoiceCancel", true]));

        await postCancelInvoice(params);

        toast.success(i18n.t("Invoice cancelled successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoiceCancel", false]));
    }
}

export const refundFbaShipmentInvoice = (params: RefundFbaShipmentInvoiceQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["invoiceRefund", true]));

        await postRefundInvoice(params);

        toast.success(i18n.t("Invoice refunded successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoiceRefund", false]));
    }
}

export const updateFbaShipmentItemPrepService = (params: UpdateFbaShipmentItemPrepServiceCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await patchFbaShipmentItemPrepService(params);

        toast.success(i18n.t("Saved"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeFbaShipmentItem = (params: DeleteFbaShipmentItemCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteFbaShipmentItem(params);

        toast.success(i18n.t("Item removed"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const loadShipmentTargetList = (params: ListShipmentTargetsQuery) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["shipmentTargetList", true]));

        const result = await getShipmentTargetList(params);

        dispatch(setShipmentTargetList(result));
    } catch (error) {
        return [];
    }
    finally {
        dispatch(loading(["shipmentTargetList", false]));
    }
}

export const createFbaShipmentTransports = (params: CreateFbaShipmentTransportsCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["save", true]));

        await postFbaShipmentTransports(params);
        
        toast.success(i18n.t("Shipments saved successfully"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeFbaShipmentTransport = (params: DeleteFbaShipmentTransportCommand) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteFbaShipmentTransport(params);
        
        toast.success(i18n.t("Shipment deleted"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}