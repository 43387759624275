import SelectInput from "Components/Form/SelectInput"
import { ProductBrandContract } from "api/types/contracts/products";
import { ListProductBrandsQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { loadList } from "slices/product/brand/thunk";
import { useImmer } from "use-immer";

type ProductBrandSelectProps = EntitySelectInputProps<ProductBrandContract, "brandId"> & {
    userId?: string
}

const ProductBrandSelect = ({ isMulti, value, onChange, onSelect, name, isValid, userId, ...rest }: ProductBrandSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListProductBrandsQuery>({
        page: 1,
        pageSize: 99,
        search: "",
        userId
    });
    const selectProperties = createSelector(
        (state: RootState) => state.ProductBrand,
        (productBrandState) => ({
            brands: productBrandState.list?.items || [],
            loading: productBrandState.loading.list
        })
    );

    const { brands, loading } = useSelector(selectProperties);
    const productBrandList = useMemo(() => brands.map(b => ({ 
        label: b.name, 
        value: b.brandId 
    })), [brands]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = brands.filter(b => val.includes(b.brandId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = brands.find(b => b.brandId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.brandId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadList(query)(dispatch);
    }, [dispatch, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => loadList(query)(dispatch)
        };
    }, [dispatch, query]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (brands.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brands]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Product Brand"),
        busy: loading,
        options: productBrandList,
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default forwardRef(ProductBrandSelect) as (
    props: ProductBrandSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof ProductBrandSelect>;