import SelectInput from "Components/Form/SelectInput";
import { UnitOfLength } from "api/types/contracts/common";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const unitOfLengths: SelectOption<UnitOfLength>[] = [{
    label: "inch",
    value: "inch"
}, {
    label: "cm",
    value: "cm"
}];

const UnitOfLengthSelect = (props: EnumSelectInput<UnitOfLength>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<UnitOfLength> isMulti value={value} onChange={onChange} {...rest} options={unitOfLengths} placeholder={t("Length Unit")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={unitOfLengths} placeholder={t("Length Unit")} />}
    </>;
};

export default UnitOfLengthSelect;