import { APIClient, PagedList } from "helpers/api_helper";
import { GetCarrierImportTaxPricingRuleQuery, GetCarrierInsuranceRuleQuery, GetCarrierOversizeRuleQuery, GetCarrierOverweightRuleQuery, ListCarrierImportTaxPricingRulesQuery, ListCarrierInsuranceRulesQuery, ListCarrierOversizeRulesQuery, ListCarrierOverweightRulesQuery } from "./types/queries";
import { CarrierImportTaxPricingRuleContract, CarrierInsuranceRuleContract, CarrierOversizeRuleContract, CarrierOverweightRuleContract } from "./types/contracts/carrier";
import { CreateCarrierImportTaxPricingRuleCommand, CreateCarrierInsuranceRuleCommand, CreateCarrierOversizeRuleCommand, CreateCarrierOverweightRuleCommand, DeleteCarrierImportTaxPricingRuleCommand, DeleteCarrierInsuranceRuleCommand, DeleteCarrierOversizeRuleCommand, DeleteCarrierOverweightRuleCommand, UpdateCarrierImportTaxPricingRuleCommand, UpdateCarrierInsuranceRuleCommand, UpdateCarrierOversizeRuleCommand, UpdateCarrierOverweightRuleCommand } from "./types/commands";

const api = new APIClient();

export const getCarrierImportTaxPricingRuleList = async (data: ListCarrierImportTaxPricingRulesQuery): Promise<PagedList<CarrierImportTaxPricingRuleContract>> => {
    const result = await api.get(`/api/carrierRule/importTaxPricing`, { ...data });
    return result;
}

export const getCarrierImportTaxPricingRule = async (data: GetCarrierImportTaxPricingRuleQuery): Promise<CarrierImportTaxPricingRuleContract | undefined> => {
    const result = await api.get(`/api/carrierRule/importTaxPricing/${data.carrierImportTaxPricingRuleId}`);
    return result;
}

export const postCarrierImportTaxPricingRule = async (data: CreateCarrierImportTaxPricingRuleCommand): Promise<CarrierImportTaxPricingRuleContract> => {
    const result = await api.post(`/api/carrierRule/importTaxPricing`, { ...data });
    return result.data;
}

export const putCarrierImportTaxPricingRule = async ({ carrierImportTaxPricingRuleId, carrierImportTaxPricingRule }: UpdateCarrierImportTaxPricingRuleCommand) => {
    const result = await api.put(`/api/carrierRule/importTaxPricing/${carrierImportTaxPricingRuleId}`, { ...carrierImportTaxPricingRule });
}

export const deleteCarrierImportTaxPricingRule = async (data: DeleteCarrierImportTaxPricingRuleCommand) => {
    const result = await api.delete(`/api/carrierRule/importTaxPricing/${data.carrierImportTaxPricingRuleId}`);
}

export const getCarrierInsuranceRuleList = async (data: ListCarrierInsuranceRulesQuery): Promise<PagedList<CarrierInsuranceRuleContract>> => {
    const result = await api.get(`/api/carrierRule/insurance`, { ...data });
    return result;
}

export const getCarrierInsuranceRule = async (data: GetCarrierInsuranceRuleQuery): Promise<CarrierInsuranceRuleContract | undefined> => {
    const result = await api.get(`/api/carrierRule/insurance/${data.carrierInsuranceRuleId}`);
    return result;
}

export const postCarrierInsuranceRule = async (data: CreateCarrierInsuranceRuleCommand): Promise<CarrierInsuranceRuleContract> => {
    const result = await api.post(`/api/carrierRule/insurance`, { ...data });
    return result.data;
}

export const putCarrierInsuranceRule = async ({ carrierInsuranceRuleId, carrierInsuranceRule }: UpdateCarrierInsuranceRuleCommand) => {
    const result = await api.put(`/api/carrierRule/insurance/${carrierInsuranceRuleId}`, { ...carrierInsuranceRule });
}

export const deleteCarrierInsuranceRule = async (data: DeleteCarrierInsuranceRuleCommand) => {
    const result = await api.delete(`/api/carrierRule/insurance/${data.carrierInsuranceRuleId}`);
}

export const getCarrierOversizeRuleList = async (data: ListCarrierOversizeRulesQuery): Promise<PagedList<CarrierOversizeRuleContract>> => {
    const result = await api.get(`/api/carrierRule/oversize`, { ...data });
    return result;
}

export const getCarrierOversizeRule = async (data: GetCarrierOversizeRuleQuery): Promise<CarrierOversizeRuleContract | undefined> => {
    const result = await api.get(`/api/carrierRule/oversize/${data.carrierOversizeRuleId}`);
    return result;
}

export const postCarrierOversizeRule = async (data: CreateCarrierOversizeRuleCommand): Promise<CarrierOversizeRuleContract> => {
    const result = await api.post(`/api/carrierRule/oversize`, { ...data });
    return result.data;
}

export const putCarrierOversizeRule = async ({ carrierOversizeRuleId, carrierOversizeRule }: UpdateCarrierOversizeRuleCommand) => {
    const result = await api.put(`/api/carrierRule/oversize/${carrierOversizeRuleId}`, { ...carrierOversizeRule });
}

export const deleteCarrierOversizeRule = async (data: DeleteCarrierOversizeRuleCommand) => {
    const result = await api.delete(`/api/carrierRule/oversize/${data.carrierOversizeRuleId}`);
}

export const getCarrierOverweightRuleList = async (data: ListCarrierOverweightRulesQuery): Promise<PagedList<CarrierOverweightRuleContract>> => {
    const result = await api.get(`/api/carrierRule/overweight`, { ...data });
    return result;
}

export const getCarrierOverweightRule = async (data: GetCarrierOverweightRuleQuery): Promise<CarrierOverweightRuleContract | undefined> => {
    const result = await api.get(`/api/carrierRule/overweight/${data.carrierOverweightRuleId}`);
    return result;
}

export const postCarrierOverweightRule = async (data: CreateCarrierOverweightRuleCommand): Promise<CarrierOverweightRuleContract> => {
    const result = await api.post(`/api/carrierRule/overweight`, { ...data });
    return result.data;
}

export const putCarrierOverweightRule = async ({ carrierOverweightRuleId, carrierOverweightRule }: UpdateCarrierOverweightRuleCommand) => {
    const result = await api.put(`/api/carrierRule/overweight/${carrierOverweightRuleId}`, { ...carrierOverweightRule });
}

export const deleteCarrierOverweightRule = async (data: DeleteCarrierOverweightRuleCommand) => {
    const result = await api.delete(`/api/carrierRule/overweight/${data.carrierOverweightRuleId}`);
}
