import { GetCompanyQuery, ListCompaniesQuery, ListCompaniesQueryForSelect } from "api/types/queries";
import { CompanyContract } from "api/types/contracts/companies";
import { CreateCompanyCommand, DeleteCompanyCommand, SetCompanyStatusCommand, UpdateCompanyCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";

const api = new APIClient();

export const getCompany = async (data: GetCompanyQuery): Promise<CompanyContract | undefined> => {
    const result = await api.get(`/api/company/${data.companyId}`);
    return result;
}

export const getCompanyList = async (data: ListCompaniesQuery): Promise<PagedList<CompanyContract>> => {
    const result = await api.get(`/api/company`, data);
    return result;
}

export const getCompanyListForSelect = async (data: ListCompaniesQueryForSelect): Promise<CompanyContract[]> => {
    const result = await api.get(`/api/company/select`, data);
    return result;
}

export const postCompany = async (data: CreateCompanyCommand): Promise<void> => {
    await api.post(`/api/company`, data);
}

export const putCompany = async (data: UpdateCompanyCommand): Promise<void> => {
    await api.put(`/api/company/${data.companyId}`, { ...data.company });
}

export const deleteCompany = async (data: DeleteCompanyCommand): Promise<void> => {
    await api.delete(`/api/company/${data.companyId}`);
}

export const patchCompanyStatus = async (data: SetCompanyStatusCommand): Promise<void> => {
    await api.patch(`/api/company/${data.companyId}/status`, data.status);
}

export const getCurrentCompany = async (): Promise<CompanyContract> => {
    const result = await api.get(`/api/company/current`);
    return result;
}