import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { createSelector } from "reselect";
import { RootState } from "slices";

const Logout = () => {
    const dispatch = useDispatch();

    const logoutData = createSelector(
        (state: RootState) => state.Login.isUserLogout,
        (isUserLogout) => isUserLogout
    );

    // Inside your component
    const isUserLogout = useSelector(logoutData);

    useEffect(() => {
        logoutUser()(dispatch);
    }, [dispatch]);

    if (isUserLogout) {
        return <Navigate to="/login" />;
    }

    return <></>;
};


export default Logout;