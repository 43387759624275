import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { Container } from "reactstrap";
import List from "./_List";
import Process from "./_Process";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import { ReceivingEntryContract } from "api/types/contracts/receivings";

type ReceivingsPageProps = {
    process?: boolean
};

const ReceivingsPage = (props: ReceivingsPageProps) => {
    const loaderData = useLoaderData() as any;
    const { t } = useTranslation();

    return <>
        <div className="page-content">
            <Container fluid>
                {!props.process && <>
                    <TitleBreadcrumb active={t("Receivings")} parents={[t("Receiving")]} />
                    <List />
                </>}
                {props.process && 
                    <ResolveWrapper promise={loaderData?.receivingEntry}>
                        {(resolvedReceivingEntry: ReceivingEntryContract) => <>
                            <TitleBreadcrumb active={`Process #${resolvedReceivingEntry.receivingNumber}`} parents={[t("Receiving"), { title: t("Receivings"), to: "/receivings" }]} />
                            <Process receivingEntry={resolvedReceivingEntry} />
                        </>}
                    </ResolveWrapper>
                }
            </Container>
        </div>
    </>;
};

export default ReceivingsPage;