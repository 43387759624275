import SelectInput from "Components/Form/SelectInput";
import { WarehouseType } from "api/types/contracts/warehouses";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useTranslation } from "react-i18next";

const warehouseTypes: SelectOption<WarehouseType>[] = [{
    value: "normal",
    label: "Normal"
}, {
    value: "thirdPartyLogistics",
    label: "3PL"
}];

const WarehouseTypeSelect = (props: EnumSelectInput<WarehouseType>) => {
    const { t } = useTranslation();

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<WarehouseType> isMulti value={value} onChange={onChange} {...rest} options={warehouseTypes} placeholder={t("Warehouse Type")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={warehouseTypes} placeholder={t("Warehouse Type")} />}
    </>;
};

export default WarehouseTypeSelect;