import { PropsWithChildren } from "react";
import { Spinner } from "reactstrap";
import classNames from "classnames";

export type BusyOverlayProps = PropsWithChildren & {
    busy: boolean,
    spinnerColor?: string,
    backgroundColor?: string,
    opaque?: boolean,
    size?: "sm",
    blur?: boolean,
    inline?: boolean,
    overlayClassName?: string
}

const BusyOverlay = (props: BusyOverlayProps) => {
    return (<>
        {props.busy ?
        <div className={classNames("position-relative", props.overlayClassName)}>
            <div className={classNames(`bg-${props.backgroundColor || "black"}`, "position-absolute z-3 w-100 h-100", {
                "bg-opacity-25": !props.opaque
            })}>
                <div className={classNames("align-items-center d-flex h-100", {
                    "justify-content-center": !props.inline
                })}>
                    <Spinner size={props.size} type="border" className={classNames(`text-${props.spinnerColor || "light"}`)} />
                </div>
            </div>
            <div className={classNames({
                "blur-1": props.blur
            })}>{props.children}</div>
        </div> 
        : props.children}
    </>);
}

export default BusyOverlay;