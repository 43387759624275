import { ListProductSuppliersQuery } from "api/types/queries";
import { CreateProductSupplierCommand, DeleteProductSupplierCommand, UpdateProductSupplierCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductSupplierContract } from "./types/contracts/products";

const api = new APIClient();

export const getProductSupplierList = async (data: ListProductSuppliersQuery): Promise<PagedList<ProductSupplierContract>> => {
    const result = await api.get(`/api/productsupplier`, data);
    return result;
}

export const postProductSupplier = async (data: CreateProductSupplierCommand): Promise<ProductSupplierContract | undefined> => {
    const response = await api.post(`/api/productsupplier`, data);
    const locationHeader = response.headers.location;
    
    if (locationHeader) {
        return await api.get(locationHeader);
    }
    else {
        return undefined;
    }
}

export const putProductSupplier = async (data: UpdateProductSupplierCommand): Promise<void> => {
    await api.put(`/api/productsupplier/${data.productSupplierId}`, { ...data.productSupplier });
}

export const deleteProductSupplier = async (data: DeleteProductSupplierCommand): Promise<void> => {
    await api.delete(`/api/productsupplier/${data.productSupplierId}`);
}
