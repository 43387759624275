import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InboundContract } from "api/types/contracts/inbound";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type InboundLoadingStates = LoadingStates<"list" | "save" | "delete" | "load" | "quickAdd">;

export type InboundState = {
    list: Partial<PagedList<InboundContract>>,
    processingItem: InboundContract | undefined,
    loading: InboundLoadingStates,
    error?: ApiError
}

export const initialState: InboundState = {
    list: {},
    processingItem: undefined,
    loading: {
        list: false,
        save: false,
        delete: false,
        load: false,
        quickAdd: false
    }
};

const InboundSlice = createSlice({
    name: "InboundSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<InboundContract>>) {
            state.list = action.payload;
        },
        loading(state, action: PayloadAction<[keyof InboundLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        },
        setProcessingItem(state, action: PayloadAction<InboundContract | undefined>) {
            state.processingItem = action.payload;
        }
    },
});

export const {
    setList,
    loading,
    apiError,
    setProcessingItem
} = InboundSlice.actions;

export default InboundSlice.reducer;