import { GetWarehouseQuery, ListWarehousesQuery, ListWarehousesQueryForSelect } from "api/types/queries";
import { CreateWarehouseCommand, DeleteWarehouseCommand, SetWarehouseStatusCommand, UpdateWarehouseCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { WarehouseContract } from "./types/contracts/warehouses";

const api = new APIClient();

export const getWarehouse = async (data: GetWarehouseQuery): Promise<WarehouseContract | undefined> => {
    const result = await api.get(`/api/warehouse/${data.warehouseId}`);
    return result;
}

export const getWarehouseList = async (data: ListWarehousesQuery): Promise<PagedList<WarehouseContract>> => {
    const result = await api.get(`/api/warehouse`, data);
    return result;
}

export const getWarehouseListForSelect = async (data: ListWarehousesQueryForSelect): Promise<WarehouseContract[]> => {
    const result = await api.get(`/api/warehouse/select`, data);
    return result;
}

export const postWarehouse = async (data: CreateWarehouseCommand): Promise<void> => {
    await api.post(`/api/warehouse`, data);
}

export const putWarehouse = async (data: UpdateWarehouseCommand): Promise<void> => {
    await api.put(`/api/warehouse/${data.warehouseId}`, { ...data.warehouse });
}

export const deleteWarehouse = async (data: DeleteWarehouseCommand): Promise<void> => {
    await api.delete(`/api/warehouse/${data.warehouseId}`);
}

export const patchWarehouseStatus = async (data: SetWarehouseStatusCommand): Promise<void> => {
    await api.patch(`/api/warehouse/${data.warehouseId}/status`, data.status);
}