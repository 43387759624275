import { Container, Modal, ModalBody } from "reactstrap";

//import images 
import { useTranslation } from "react-i18next";

import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import List, { CompanyListRef } from "./_List";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useRef } from "react";
import WizardForm from "./_WizardForm";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";

type CompaniesPageProps = {
    edit?: boolean
}

const CompaniesPage = (props: CompaniesPageProps) => {
    const loaderData = useLoaderData() as any;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<CompanyListRef>(null);

    return <Restricted require="management.company" throw fallback={() => <Unauthorized />}>
            <div className="page-content">
                <Container fluid>
                    <TitleBreadcrumb active={t("Companies")} parents={[t("Management")]} />
                    <List ref={listRef} />
                    {props.edit && 
                        <Modal size="xl" isOpen toggle={() => navigate("/companies")} unmountOnClose>
                            <ModalBody>
                                <ResolveWrapper promise={loaderData?.company}>
                                    {(company) => <>
                                        <ModalCloseButton onClick={() => navigate("/companies")} />
                                        <WizardForm company={company} onSuccess={() => {
                                            navigate("/companies");
                                            listRef.current?.reload();
                                        }} />
                                    </>}
                                </ResolveWrapper>
                            </ModalBody>
                        </Modal>
                    }
                </Container>
            </div>
        </Restricted>;
};

export default CompaniesPage;
